// Holds all the icons of the form i.e. the circut icons etc

import { IconType } from "../../helpers/muiHelper";
import GridOnIcon from '@mui/icons-material/GridOn';
import StraightenIcon from '@mui/icons-material/Straighten';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AirIcon from '@mui/icons-material/Air';
import PoolIcon from '@mui/icons-material/Pool';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import { AcUnit, Hvac } from '@mui/icons-material';
import { AddToDrive } from '@mui/icons-material';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

/**
 * The icon for the floor heating circut
 */
export const FloorHeatingIcon:IconType = GridOnIcon;
/**
 * The icon for the radiator heating circut
 */
export const RadiatorHeatingIcon:IconType = StraightenIcon;
/**
 * The icon for the ventilation heating circut
 */
export const VentilationHeatingIcon:IconType = DragHandleIcon;
/**
 * The icon for the pool heating circut
 */
export const PoolHeatingIcon:IconType = PoolIcon;
/**
 * The icon for the extra heating circut without mixture node
 */
export const ExtraCircleWithoutValveHeatingIcon:IconType = DataSaverOnIcon;
/**
 * The icon for the extra circle heating circut
 */
export const ExtraCircleHeatingIcon:IconType = DataSaverOnIcon;
/**
 * The icon for the cooling inner parts cooling circut
 */
export const CoolingInnerPartsIcon:IconType = AcUnit;
/**
 * The icon for the ventilation cooling circut
 */
export const VentilationCoolingIcon:IconType = Hvac;
/**
 * The icon for the extra circle heating circut
 */
export const ExtraHeatingCircleIcon:IconType = AddToDrive;
/**
 * The icon used for the dimensioning
 */
export const DimensioningIcon:IconType = ViewInArIcon;


import * as React from 'react';
import { IStepperIconsProps } from './IStepperIconsProps';
import { Tooltip, useTheme } from "@mui/material";
import { IconType } from "../../../helpers/muiHelper";
import { ReportProblem } from "@mui/icons-material";
import { SectionKeys } from '../FormContext';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';


/**
 * Renders out the icons for the stepper form's steps
 */
export function StepperIcons(props:IStepperIconsProps) {
    
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _theme = useTheme();
    let _color:string = 'inherit';
    let Icon:IconType = props.section.icon

    if(props.section.areRequiredFieldsFilled()) {
        _color = _theme.palette.primary.main;

        if(props.section.key === props.activeSection) {
            _color = _theme.palette.primary.dark;
        }
    }

    if(props.error) {
        Icon = ReportProblem;
        _color = _theme.palette.error.main;
    }

    let _sectionHeading:string = '';
    switch(props.section.key) {
        case SectionKeys.generalInfo:
            _sectionHeading = _translations?.phrases.projectInfoSectionHeading || '';
            break;
        case SectionKeys.heatingCircuts:
            _sectionHeading = _translations?.phrases.heatingCircutsSectionHeading || '';
            break;
        case SectionKeys.coolingCircuts:
            _sectionHeading = _translations?.phrases.coolingCircutsSectionHeading || '';
            break;
        case SectionKeys.heatpump:
            _sectionHeading = _translations?.phrases.heatpumpSectionHeading || '';
            break;
        case SectionKeys.tanks:
            _sectionHeading = _translations?.phrases.tankSelectionSectionHeading || '';
            break;
        case SectionKeys.result:
            _sectionHeading = _translations?.phrases.generationResultsSectionHeating || '';
            break;
    }

    return (
        <Tooltip title={ _sectionHeading } placement="top-start">
            <Icon 
                sx={{
                    color: _color,
                }}
            />
        </Tooltip>
    )
}
import * as React from 'react';
import styles from './HeatingCircuts.module.scss';

import { HeatingCircut } from '../HeatingCircut/HeatingCircut';
import { 
    useForm,
    IHeatingCircutsValues,
    IUseFormHookResult,
    ICircutInErrorValues
} from '../FormContext';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { ExtraCircleWithoutValveHeatingIcon, ExtraCircleHeatingIcon, FloorHeatingIcon, PoolHeatingIcon, RadiatorHeatingIcon, VentilationHeatingIcon } from '../Icons';
import { IHeatingCircut } from '../IProjectData';
import { IState, useState } from '../../../react-hooks/useState';
import { Alert, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import * as _ from 'lodash';
import { PumpWarning } from '../PumpWarning/PumpWarning';


/**
 * Renders out the heating circuts (elements) form
 */
export function HeatingCircuts(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:IHeatingCircutsValues = _form.data.heatingCircuts.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    let totalHeatload:number = 0;
    if(_data.floor.isEnabled) {        
        totalHeatload += _data.floor.heatingLoad || 0;
    }
    if(_data.radiator.isEnabled) {
        totalHeatload += _data.radiator.heatingLoad || 0;
    }
    if(_data.ventilation.isEnabled) {
        totalHeatload += _data.ventilation.heatingLoad || 0;
    }
    if(_data.pool.isEnabled) {
        totalHeatload += _data.pool.heatingLoad || 0;
    }
    if(_data.extraWithoutValve.isEnabled) {
        totalHeatload += _data.extraWithoutValve.heatingLoad || 0;
    }
    if(_data.extra.isEnabled) {
        totalHeatload += _data.extra.heatingLoad || 0;
    }    

    return (
        <>
            <Typography variant='sectionHeading'>{_translations?.phrases.heatingCircutsSectionHeading}</Typography>
            <PumpWarning />
            {
                (totalHeatload > (_form.data.generalInfo.values.heatload || 0)) &&
                <Alert severity="warning">
                    { _translations?.phrases.combinedHeatloadBiggerThanProject }
                </Alert>
            }
            <HeatingCircut 
                icon={<FloorHeatingIcon />}
                label={_translations?.phrases.floorHeating}
                value={_data.floor}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatingCircutsValues> = {
                        floor: newValue
                    };
                    _form.setHeatingCircuts(newData);
                }}
                extraSelection={
                    <Box className={styles.threeWayValve}>
                        <FormControlLabel 
                            control={(
                                <Checkbox  
                                    checked={_data.hasFloorHeatingThreeWayValve}
                                    onChange={(e, checked:boolean) => {
                                        const newData:Partial<IHeatingCircutsValues> = {
                                            hasFloorHeatingThreeWayValve: checked
                                        };
                                        _form.setHeatingCircuts(newData);
                                    }}
                                    disabled={_data.is3WayFloorHeatingValveDisabled}
                                />
                            )}
                            label={_translations?.phrases.floorHeating3WayValve}
                        />
                    </Box>
                }
                inErrorValues={_data.floorErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.floorErrors);
                    errors.isHeatloadInError = !_data.floor.heatingLoad;
                    const newData:Partial<IHeatingCircutsValues> = {
                        floorErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.floorErrors);
                    errors.isResistanceInError = !_data.floor.resistance;
                    const newData:Partial<IHeatingCircutsValues> = {
                        floorErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
            />
            <HeatingCircut 
                icon={<RadiatorHeatingIcon />}
                label={_translations?.phrases.radiatorHeating}
                value={_data.radiator}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatingCircutsValues> = {
                        radiator: newValue
                    };
                    _form.setHeatingCircuts(newData);
                }}
                maxTemp={55}
                inErrorValues={_data.radiatorErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.radiatorErrors);
                    errors.isHeatloadInError = !_data.radiator.heatingLoad;
                    const newData:Partial<IHeatingCircutsValues> = {
                        radiatorErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.radiatorErrors);
                    errors.isResistanceInError = !_data.radiator.resistance;
                    const newData:Partial<IHeatingCircutsValues> = {
                        radiatorErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
            />
            <HeatingCircut 
                icon={<VentilationHeatingIcon />}
                label={_translations?.phrases.ventilationHeating}
                value={_data.ventilation}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatingCircutsValues> = {
                        ventilation: newValue
                    };
                    _form.setHeatingCircuts(newData);
                }}
                maxTemp={60}
                inErrorValues={_data.ventilationErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.ventilationErrors);
                    errors.isHeatloadInError = !_data.ventilation.heatingLoad;
                    const newData:Partial<IHeatingCircutsValues> = {
                        ventilationErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.ventilationErrors);
                    errors.isResistanceInError = !_data.ventilation.resistance;
                    const newData:Partial<IHeatingCircutsValues> = {
                        ventilationErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
            />
            <HeatingCircut 
                icon={<PoolHeatingIcon />}
                label={_translations?.phrases.poolHeating}
                value={_data.pool}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatingCircutsValues> = {
                        pool: newValue
                    };
                    _form.setHeatingCircuts(newData);
                }}
                inErrorValues={_data.poolErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.poolErrors);
                    errors.isHeatloadInError = !_data.pool.heatingLoad;
                    const newData:Partial<IHeatingCircutsValues> = {
                        poolErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.poolErrors);
                    errors.isResistanceInError = !_data.pool.resistance;
                    const newData:Partial<IHeatingCircutsValues> = {
                        poolErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
            />
            <HeatingCircut 
                icon={<ExtraCircleWithoutValveHeatingIcon />}                
                value={_data.extraWithoutValve}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraWithoutValve: newValue
                    };
                    _form.setHeatingCircuts(newData);
                }}
                inErrorValues={_data.extraWithoutValveErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.extraWithoutValveErrors);
                    errors.isHeatloadInError = !_data.extraWithoutValve.heatingLoad;
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraWithoutValveErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.extraWithoutValveErrors);
                    errors.isResistanceInError = !_data.extraWithoutValve.resistance;
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraWithoutValveErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                label={_data.extraCircutWithoutValveLabel}
                labelInputLabel={_translations?.phrases.extraCircleWithoutValveHeating} 
                onLabelChange={(newLabel:string) => {                    
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraCircutWithoutValveLabel: newLabel
                    };
                    _form.setHeatingCircuts(newData);
                }}
            />
            <HeatingCircut 
                icon={<ExtraCircleHeatingIcon />}
                label={_data.extraCircutLabel}
                value={_data.extra}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatingCircutsValues> = {
                        extra: newValue
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onLabelChange={(newLabel:string) => {                    
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraCircutLabel: newLabel
                    };
                    _form.setHeatingCircuts(newData);
                }}
                labelInputLabel={_translations?.phrases.extraCircleHeating}                
                inErrorValues={_data.extraErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.extraErrors);
                    errors.isHeatloadInError = !_data.extra.heatingLoad;
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.extraErrors);
                    errors.isResistanceInError = !_data.extra.resistance;
                    const newData:Partial<IHeatingCircutsValues> = {
                        extraErrors: errors
                    };
                    _form.setHeatingCircuts(newData);
                }}
            />
        </>
    );
}

import * as React from 'react';
import styles from './HeatpumpSelection.module.scss';

import { 
    Alert, 
    Box, 
    Card, 
    Divider, 
    FormControl, 
    FormControlLabel, 
    FormLabel, 
    Grid, 
    IconButton, 
    Radio, 
    RadioGroup, 
    Stack, 
    Theme, 
    Tooltip, 
    Typography, 
    useTheme
} from '@mui/material';
import { 
    ArrowCircleLeft,
    ArrowCircleRight,
    Check, 
    HorizontalRule, 
    LocalFireDepartment, 
    Plumbing 
} from '@mui/icons-material';
import { 
    DEFAULT_EARTH_TYPE_VALUE, 
    ICircutInErrorValues, 
    IHeatingCircutsValues, 
    IHeatpumpValuesForForm, 
    IUseFormHookResult, 
    useForm,
    IDimensioningRange,
    AIR_DIMENSIONING,
    GROUND_DIMENSIONING
} from '../FormContext';

import { 
    CompressorType,
    IHeatpump,
    PumpTypes,
    SubTypes
} from '../IHeatpump';
import { DevicePreviewCard } from '../DevicePreviewCard/DevicePreviewCard';
import { IState, useState } from '../../../react-hooks/useState';
import { g_getEnumValues } from '../../../helpers/generalHelpers';
import { AppSlider } from '../../AppSlider/AppSlider';
import { DevicesContext } from '../DevicesContext';
import { GroundPumpConturing } from '../GroundPumpConturing/GroundPumpConturing';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { DimensioningIcon, ExtraHeatingCircleIcon } from '../Icons';
import { EarthContureCalculationType, HeatpumpType, IHeatingCircut, IHeatpumpSelectionValues } from '../IProjectData';
import { HeatingCircut } from '../HeatingCircut/HeatingCircut';
import { AppTabs } from '../../AppTabs/AppTabs';
import { IAppTab } from '../../AppTabs/IAppTabsProps';
import * as _ from 'lodash';

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import BoltIcon from '@mui/icons-material/Bolt';
import { Format } from '../../../helpers/Format';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { PumpWarning } from '../PumpWarning/PumpWarning';
import { ABValve } from '../../AppIcons/ABValve';
import { Pump } from '../../AppIcons/Pump';
import { Waves } from '../../AppIcons/Waves';

const GROUND_GROUP_NAME:string = "GROUND-1049587f-c2e4-4c97-b47c-cdd2892af901";
const AIR_GROUP_NAME:string = "AIR-bc36028d-305f-4514-a4d0-d5cb18e93353";

const SINGLE_PUMP_BREAKPOINT:number = 600;

/**
 * Renders out the heatpump selection
 */
export function HeatpumpSelection(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:IHeatpumpValuesForForm = _form.data.heatpump.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    const _groundSelection:IHeatpump|undefined = _data.pumps.find((p:IHeatpump) => p.guid === _data.groundId);
    const _airSelection:IHeatpump|undefined = _data.pumps.find((p:IHeatpump) => p.guid === _data.airId);

    const _theme:Theme = useTheme();

    //gets the layout widths and class names
    let _previewXS:number = 12;
    let _previewClassName:string = styles.noSelectionPreviewContainer;
    if(_data.airId && !_data.groundId) {
        _previewClassName += ' ' + styles.airPreviewContainer;
    } else if(_data.groundId && !_data.airId) {
        _previewClassName += ' ' + styles.groundPreviewContainer;
    } else if(_data.airId && _data.groundId) {
        _previewXS = 6;
        _previewClassName += ' ' + styles.bothPreviewsContainer;
    }

    //makes sure that we have pumps
    const _hasGroundPumps:boolean = !!_data.groundDimensioningRange;
    const _hasAirPumps:boolean = !!_data.airDimensioningRange;
    let _pumpSelectionXS:number = _hasAirPumps && _hasGroundPumps ? 6 : 12;

    /**
     * Closes and clears the ground pump selection
     */
    const _handleGroundSelectionClose = ():void => {
        const newData:Partial<IHeatpumpValuesForForm> = {
            type: _data.type,
            groundId: null
        };
        if(newData.type === HeatpumpType.Ground) {
            newData.type = HeatpumpType.NotSet;
        }
        _form.setHeatingpump(newData);
    };
    /**
     * Closes and clears the Air pump selection
     */
    const _handleAirSelectionClose = ():void => {
        const newData:Partial<IHeatpumpValuesForForm> = {
            type: _data.type,
            airId: null
        };
        if(newData.type === HeatpumpType.Air) {
            newData.type = HeatpumpType.NotSet;
        }
        _form.setHeatingpump(newData);
    };
    /**
     * Saves the ground pump dimensiong change into the form data context
     * @param dimensioning The new value to be stored
     */
    const _handleGroundDimensioningChange = (dimensioning:number):void => {
        if(dimensioning !== _data.groundDimensioning) {
            const newData:Partial<IHeatpumpValuesForForm> = {
                groundDimensioning: dimensioning as number
            };
            _form.setHeatingpump(newData);
        }
    };
    /**
     * Saves the air pump dimensiong change into the form data context
     * @param dimensioning The new value to be stored
     */
    const _handleAirDimensioningChange = (dimensioning:number):void => {
        if(_data.airDimensioning !== dimensioning) {
            const newData:Partial<IHeatpumpValuesForForm> = {
                airDimensioning: dimensioning as number
            };
            _form.setHeatingpump(newData);
        }
    };
    /**
     * Returns the boiler type label
     * @param type The type of boiler the pump has
     */
    const _getBoilerTypeLabel = (type:PumpTypes):string => {
        let label:string|undefined = 'TAB';
        switch(type) {
            case PumpTypes.WithIntegratedBoiler:
                label = _translations?.phrases.integratedBoilerPumpGroupName;
                break;
            case PumpTypes.Compact:
                label = _translations?.phrases.compactPumpGroupName;
                break;
            case PumpTypes.WithExternalBoiler:
                label = _translations?.phrases.externalBoilerPumpGroupName;
                break;                                
            case PumpTypes.Indoor:
                label = _translations?.phrases.indoorPumpGroupName;
                break;
            case PumpTypes.Outdoor:
                label = _translations?.phrases.outdoorPumpGroupName;
                break;
            case PumpTypes.Cascade:
                label = _translations?.phrases.cascadePumpGroupName;
                break;
            
        }

        return label || '';
    };
    /**
     * Returns the boiler sub type label
     * @param type The type of boiler the pump has
     */
    const _getBoilerSubTypeLabel = (type:SubTypes):string => {
        let label:string|undefined = 'SUBTYPE';
        switch(type) {
            case SubTypes.InverterMonoblock:
                label = _translations?.phrases.heatpumpSubTypeInverterMonoblock;
                break;
            case SubTypes.SplitHMInverter:
                label = _translations?.phrases.heatpumpSubTypeSplitHM;
                break;
            case SubTypes.SplitHTInverter:
                label = _translations?.phrases.heatpumpSubTypeSplitHT;
                break;
            case SubTypes.onOffMonoblock:
                label = _translations?.phrases.heatpumpSubTypeOnOffMonoblock;
                break;
            case SubTypes.Inverter:
                label = _translations?.phrases.heatpumpSubTypeInverter;
                break;
            case SubTypes.onOff:
                label = _translations?.phrases.heatpumpSubTypeOnOff;
                break;
        }

        return label || '';
    };
    /**
     * Holds the extra heating source selection
     */
    const _extraHeatSourceSelection:React.ReactNode = (
        <>
            
            <HeatingCircut 
                icon={<ExtraHeatingCircleIcon />}
                label={_data.extraHeatingSourceLabel}
                value={_data.extraHeatingSource}
                onChange={(newValue:IHeatingCircut) => {
                    const newData:Partial<IHeatpumpValuesForForm> = {
                        extraHeatingSource: newValue
                    };
                    _form.setHeatingpump(newData);
                }}
                maxTemp={75}
                doesNotHaveBorders
                onLabelChange={(newLabel:string) => {
                    const newData:Partial<IHeatpumpValuesForForm> = {
                        extraHeatingSourceLabel: newLabel
                    };
                    _form.setHeatingpump(newData);
                }}
                labelInputLabel={_translations?.phrases.extraHeatingSource}
                inErrorValues={_data.extraHeatSourceErrors}
                onHeatloadBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.extraHeatSourceErrors);
                    errors.isHeatloadInError = !_data.extraHeatingSource.heatingLoad;
                    const newData:Partial<IHeatpumpValuesForForm> = {
                        extraHeatSourceErrors: errors
                    };
                    _form.setHeatingpump(newData);
                }}
                onResistanceBlur={() => {
                    const errors:ICircutInErrorValues = _.cloneDeep(_data.extraHeatSourceErrors);
                    errors.isResistanceInError = !_data.extraHeatingSource.resistance;
                    const newData:Partial<IHeatpumpValuesForForm> = {
                        extraHeatSourceErrors: errors
                    };
                    _form.setHeatingpump(newData);
                }}
                heatloadLabel={_translations?.phrases.powerLabel}                
            />
        </>
    );
    /**
     * Renders out the technical details table for a pump
     * @param pump The pump whom technical details are requested
     */
    const _renderPumpTechnicalDetails = (pump:IHeatpump):React.ReactNode => {
        let power:string = pump.power ? Format.text(_translations?.phrases.powerValue, pump.power) : Format.text(_translations?.phrases.inverterPowerValue, pump.minPower, pump.maxPower);

        if(pump.powerSingleComp) {
            power = Format.text(_translations?.phrases.dualCompressorPowerValue, pump.powerSingleComp, power);
        }

        return (
            <Grid
                container
                className={styles.technicalPreview}
                spacing={2}
            >
                <Grid
                    item
                    xs={4}
                >
                    <Tooltip title={Format.text(_translations?.phrases.tooltipLabelValue, _translations?.phrases.powerLabel, power)} placement="bottom">
                        <Card
                            className={styles.technicalCard}
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={styles.labels}
                            >
                                <LocalFireDepartmentIcon />                                
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                        
                                { power }
                            </Typography>
                        </Card>
                    </Tooltip>                    
                </Grid>
                <Grid
                    item
                    xs={4}                    
                >
                    <Tooltip 
                        title={
                            Format.text(
                                _translations?.phrases.tooltipLabelValue, 
                                _translations?.phrases.integratedBoilerLabel, 
                                !!pump.integratedBoiler ? Format.text(_translations?.phrases.integratedBoilerValue, pump.integratedBoiler) : _translations?.phrases.doesNotHaveElementIndicator
                            )
                        } 
                        placement="bottom"
                    >
                        <Card
                            className={styles.technicalCard}
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={styles.labels}
                            >
                                <WaterDropIcon />                                
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                                
                                { 
                                    !!pump.integratedBoiler ? Format.text(_translations?.phrases.integratedBoilerValue, pump.integratedBoiler) : (
                                        <HorizontalRule />
                                    )                            
                                }
                            </Typography>
                        </Card>
                    </Tooltip>
                </Grid>
                <Grid
                    item
                    xs={4}                    
                >
                    <Tooltip 
                        title={
                            Format.text(
                                _translations?.phrases.tooltipLabelValue, 
                                _translations?.phrases.integratedHeatingElementLabel, 
                                !!pump.integratedHeatElement ? Format.text(_translations?.phrases.integratedHeatingElementValue, pump.integratedHeatElement) : _translations?.phrases.doesNotHaveElementIndicator
                            )
                        }
                        placement="bottom"
                    >
                        <Card
                            className={styles.technicalCard}
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={styles.labels}
                            >
                                <BoltIcon />                                
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                                
                                { 
                                    !!pump.integratedHeatElement ? Format.text(_translations?.phrases.integratedHeatingElementValue, pump.integratedHeatElement) : (
                                        <HorizontalRule />
                                    )     
                                }
                            </Typography>
                        </Card>
                    </Tooltip>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Tooltip 
                        title={
                            Format.text(
                                _translations?.phrases.tooltipLabelValue, 
                                _translations?.phrases.domesticHotWaterABValveLabel, 
                                pump.hasDhwABvalve ? _translations?.phrases.hasElementIndicator : _translations?.phrases.doesNotHaveElementIndicator
                            )
                        }
                        placement="bottom"
                    >
                        <Card
                            className={styles.technicalCard}                            
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={`${styles.labels} ${styles.svgIcon}`}
                            >
                                <ABValve 
                                    color={_theme.palette.text.secondary}
                                />                                 
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                        
                                { 
                                    pump.hasDhwABvalve ? (
                                        <Check />
                                    ) : (
                                        <HorizontalRule />
                                    )     
                                }
                            </Typography>
                        </Card>
                    </Tooltip>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Tooltip 
                        title={
                            Format.text(
                                _translations?.phrases.tooltipLabelValue, 
                                _translations?.phrases.heatingCircuitPumpLabel, 
                                pump.circPumpHeat ? _translations?.phrases.hasElementIndicator : _translations?.phrases.doesNotHaveElementIndicator
                            )
                        }
                        placement="bottom"
                    >
                        <Card
                            className={styles.technicalCard}                            
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={`${styles.labels} ${styles.svgIcon}`}
                            >
                                <Box
                                    className={styles.subIconContainer}
                                >
                                    <Pump 
                                        color={_theme.palette.text.secondary}
                                    />
                                    <Box
                                        className={styles.subIcon}
                                        sx={{
                                            borderColor: `${_theme.palette.subIcon.main} !important`
                                        }}
                                    >
                                      <LocalFireDepartment 
                                        className={styles.circulationPumpHeatIcon}
                                        htmlColor={_theme.palette.subIcon.main}
                                      />  
                                    </Box>
                                </Box>                       
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                        
                                { 
                                    pump.circPumpHeat ? (
                                        <Check />
                                    ) : (
                                        <HorizontalRule />
                                    )     
                                }
                            </Typography>
                        </Card>
                    </Tooltip>
                </Grid>
                {
                    pump.type === HeatpumpType.Ground &&
                    <Grid
                        item
                        xs={4}
                    >
                        <Tooltip 
                            title={
                                Format.text(
                                    _translations?.phrases.tooltipLabelValue, 
                                    _translations?.phrases.brineCircuitPumpLabel, 
                                    pump.circPumpBrine ? _translations?.phrases.hasElementIndicator : _translations?.phrases.doesNotHaveElementIndicator
                                )
                            }
                            placement="bottom"
                        >
                            <Card
                                className={styles.technicalCard}                            
                            >
                                <Typography 
                                    variant='subtitle2'
                                    color="text.secondary"
                                    className={`${styles.labels} ${styles.svgIcon}`}
                                >
                                    <Box
                                        className={styles.subIconContainer}
                                    >
                                        <Pump 
                                            color={_theme.palette.text.secondary}
                                        />
                                        <Box
                                            className={styles.subIcon}
                                            sx={{
                                                borderColor: `${_theme.palette.subIcon.main} !important`
                                            }}
                                        >
                                            <Waves 
                                                color={_theme.palette.subIcon.main}
                                            />
                                        </Box>
                                    </Box>                        
                                </Typography>
                                <Typography 
                                    variant='body2'
                                    color="text.secondary"
                                    className={styles.values}
                                >                        
                                    { 
                                        pump.circPumpBrine ? (
                                            <Check />
                                        ) : (
                                            <HorizontalRule />
                                        )     
                                    }
                                </Typography>
                            </Card>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        )
    }

    const _containerRef = React.useRef<HTMLDivElement>();
    const _containerWidth:IState<number> = useState<number>(0);
    /**
     * The grid width to specify the slide width, 
     * if set is the double of the container width. if 0 then 100% is used.
     * If set shows that only one pump is being shown.
     */
    const _gridWidth:IState<number> = useState<number>(0);    
    const _isShowingOnlyOnePump:boolean = !!_gridWidth.value;

    if(_isShowingOnlyOnePump) {
        _previewXS = 6;
    }

    /**
     * Sets the SVG dimensions based of the SVG container
     */
    const _setContainerDimensions = () => {
        const width:number = _containerRef.current?.offsetWidth || 0;        
        _containerWidth.set(width);

        if(_hasAirPumps && _hasGroundPumps && _containerWidth.value && _containerWidth.value < SINGLE_PUMP_BREAKPOINT) {            
            _gridWidth.set(_containerWidth.value * 2); 
        } else {
            _gridWidth.set(0);
        }
    };
    /**
     * The observer that tracks the size of the container for the pumps
     */
    const _observer = new ResizeObserver(_setContainerDimensions);

    //makes sure the view updates on pump selection update
    React.useEffect(() => {
        _setContainerDimensions();
    }, [_hasAirPumps, _hasGroundPumps]);

    
    let _gridPositionLeft:number = 0;
    if(_isShowingOnlyOnePump && _data.singlePumpViewPump === HeatpumpType.Air) {
        _gridPositionLeft = -_containerWidth.value;
    }

    const _singlePumpViewPump:IState<HeatpumpType> = useState<number>(HeatpumpType.NotSet);
    React.useEffect(() => {
        if(_gridWidth.value) {
            _singlePumpViewPump.set(_data.singlePumpViewPump || HeatpumpType.Ground);
        }
    }, [_gridWidth.value]);
    
    return (
        <>
            <Stack>
                <Typography variant='sectionHeading'>{_translations?.phrases.heatpumpSectionHeading}</Typography>
                <PumpWarning className={styles.noHeatpumpWarning} />
            </Stack>          
            <Box
                ref={(elm:HTMLDivElement) => {
                    _containerRef.current = elm;
                    if(elm) {
                        _observer.disconnect();
                        _observer.observe(elm);
                        _setContainerDimensions();
                    }
                }}
                sx={{
                    width: '100%',
                    overflowX: 'hidden',
                    paddingBottom: '10px'
                }}
                className={`${_isShowingOnlyOnePump ? styles.singlePumpView : ''}`}
            >
                <Grid 
                    container 
                    className={`${_previewClassName}`}   
                    sx={{
                        width: _gridWidth.value ||  '100%',
                        minWidth: '540px !important',
                        position: 'relative',
                        left: _gridPositionLeft,
                        transition: _singlePumpViewPump.value !== HeatpumpType.NotSet ? 'left 0.5s' : 'initial',
                    }}            
                >                    
                    {
                        _hasGroundPumps &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider',
                                position: 'relative'
                            }}
                            className={styles.groundpumpHeading}
                            xs={_pumpSelectionXS}
                        >
                            <Typography 
                                component={"span"} 
                                variant='subtitle1'
                            >
                                {_translations?.phrases.groundpump}
                            </Typography>
                            {
                                _isShowingOnlyOnePump &&
                                <IconButton
                                    onClick={() => {                                        
                                        const newData:Partial<IHeatpumpValuesForForm> = {
                                            singlePumpViewPump: HeatpumpType.Air
                                        };
                                        _form.setHeatingpump(newData, true);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '0.25em'
                                    }}
                                    size='large'
                                >
                                    <ArrowCircleRight 
                                        color="primary"
                                        sx={{
                                            fontSize: '1.5em'
                                        }}
                                    />
                                </IconButton>
                            }
                        </Grid>
                    }
                    {
                        _hasAirPumps &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider',
                                position: 'relative'
                            }}
                            className={styles.airpumpHeading}
                            xs={_pumpSelectionXS}
                        >
                            <Typography 
                                component={"span"} 
                                variant='subtitle1'
                            >
                                {_translations?.phrases.airpump}
                            </Typography>
                            {
                                _isShowingOnlyOnePump &&
                                <IconButton
                                    onClick={() => {
                                        const newData:Partial<IHeatpumpValuesForForm> = {
                                            singlePumpViewPump: HeatpumpType.Ground
                                        };
                                        _form.setHeatingpump(newData, true);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: '0.25em',                                        
                                    }}
                                    size='large'
                                >
                                    <ArrowCircleLeft
                                        color="primary"
                                        sx={{
                                            fontSize: '1.5em'
                                        }}
                                    />
                                </IconButton>
                            }
                        </Grid>                
                    }
                    {
                        !!_data.groundDimensioningRange &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider',
                            }}
                            className={styles.groundpumpFiltering}
                            xs={_pumpSelectionXS}
                        >
                            <Box className={styles.dimensioningContainer}>
                                <AppSlider 
                                    min={_data.groundDimensioningRange.min}
                                    max={_data.groundDimensioningRange.max}
                                    initial={_data.groundDimensioning}
                                    unit='%'
                                    label={_translations?.phrases.dimensioning}
                                    icon={DimensioningIcon}
                                    onChange={(newValue:number|number[]) => _handleGroundDimensioningChange(newValue as number)}
                                />
                            </Box>
                            <AppTabs 
                                selectedValue={_data.selectedGroundPumpType}
                                tabs={
                                    g_getEnumValues<PumpTypes>(PumpTypes).filter((boilerType:PumpTypes) => _data.pumps.find((p:IHeatpump) => p.pumpType === boilerType && p.type === HeatpumpType.Ground)).map((type:PumpTypes) => {
                                        const tab:IAppTab = {
                                            label: _getBoilerTypeLabel(type),
                                            value: type
                                        };
                                        return tab;
                                    })
                                }
                                onChange={(newValue:PumpTypes) => {
                                    const newData:Partial<IHeatpumpSelectionValues> = {
                                        selectedGroundPumpType: newValue
                                    };
                                    _form.setHeatingpump(newData, true);
                                }}
                            />                    
                        </Grid>                
                    }
                    {
                        !!_data.airDimensioningRange &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider'
                            }}
                            className={styles.airpumpFiltering}
                            xs={_pumpSelectionXS}
                        >
                            <Box className={styles.dimensioningContainer}>
                                <AppSlider 
                                    min={_data.airDimensioningRange.min}
                                    max={_data.airDimensioningRange.max}
                                    initial={_data.airDimensioning}
                                    unit='%'
                                    label={_translations?.phrases.dimensioning}
                                    icon={DimensioningIcon}
                                    onChange={(newValue:number|number[]) => _handleAirDimensioningChange(newValue as number)}
                                    setLabelOnRight={_isShowingOnlyOnePump}
                                />
                            </Box>
                            <Box
                                sx={{
                                    '.MuiTab-root:first-child': {
                                        marginLeft: '1em'
                                    }
                                }}
                            >
                                <AppTabs 
                                    selectedValue={_data.selectedAirPumpType}
                                    tabs={
                                        g_getEnumValues<PumpTypes>(PumpTypes).filter((boilerType:PumpTypes) => _data.pumps.find((p:IHeatpump) => p.pumpType === boilerType && p.type === HeatpumpType.Air)).map((type:PumpTypes) => {
                                            const tab:IAppTab = {
                                                label: _getBoilerTypeLabel(type),
                                                value: type
                                            };
                                            return tab;
                                        })
                                    }
                                    onChange={(newValue:PumpTypes) => {
                                        const newData:Partial<IHeatpumpSelectionValues> = {
                                            selectedAirPumpType: newValue
                                        };
                                        _form.setHeatingpump(newData, true);
                                    }}
                                />
                            </Box>
                        </Grid>                
                    }
                    {
                        _hasGroundPumps &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider'
                            }}
                            className={styles.groundpumpSelection}
                            xs={_pumpSelectionXS}
                        >
                            <Stack spacing={1}>
                            {
                                g_getEnumValues<SubTypes>(SubTypes).map((subType:SubTypes) => {

                                    const pumps:IHeatpump[] = _data.pumps.filter((p:IHeatpump) => p.type === HeatpumpType.Ground && p.pumpType === _data.selectedGroundPumpType && subType === p.group);
                                    if(!pumps.length) {
                                        return null;
                                    }

                                    const heading:string|undefined = _getBoilerSubTypeLabel(subType);
                                    
                                    return (
                                        <FormControl key={subType}>
                                            <FormLabel>{ heading }</FormLabel>
                                            <RadioGroup 
                                                name={`G${ subType }_${GROUND_GROUP_NAME}`}
                                                onChange={(e, value:string) => {
                                                    const newData:Partial<IHeatpumpValuesForForm> = {
                                                        groundId: value
                                                    };
                                                    _form.setHeatingpump(newData);
                                                }}
                                                value={_data.groundId}
                                            >
                                            {
                                                pumps.map((pump:IHeatpump) => (
                                                    <FormControlLabel 
                                                        value={pump.guid} 
                                                        control={<Radio />} 
                                                        label={_form.data.getPumpName(pump)} 
                                                        key={pump.guid}
                                                    />  
                                                ))
                                            }                    
                                            </RadioGroup>                    
                                        </FormControl>
                                    );
                                })
                            }  
                            </Stack>              
                        </Grid>                
                    }
                    {
                        _hasAirPumps &&
                        <Grid 
                            item 
                            sx={{
                                flexGrow: 1,
                                borderColor: 'divider',
                                borderWidth: _isShowingOnlyOnePump ? 0 : 1,
                            }}
                            xs={_pumpSelectionXS}
                            className={styles.airSelection}
                        >
                            <Stack spacing={1}>
                            {
                                g_getEnumValues<SubTypes>(SubTypes).map((subType:SubTypes) => {

                                    const pumps:IHeatpump[] = _data.pumps.filter((p:IHeatpump) => p.type === HeatpumpType.Air && p.pumpType === _data.selectedAirPumpType && subType === p.group);
                                    if(!pumps.length) {
                                        return null;
                                    }

                                    const heading:string|undefined = _getBoilerSubTypeLabel(subType);
                                    
                                    return (
                                        <FormControl key={subType}>
                                            <FormLabel>{ heading }</FormLabel>
                                            <RadioGroup 
                                                name={`A${subType}_${AIR_GROUP_NAME}`}
                                                onChange={(e, value:string) => {
                                                    const newData:Partial<IHeatpumpValuesForForm> = {
                                                        airId: value
                                                    };
                                                    _form.setHeatingpump(newData);
                                                }}
                                                value={_data.airId}
                                            >
                                            {
                                                pumps.map((pump:IHeatpump) => (
                                                    <FormControlLabel 
                                                        value={pump.guid} 
                                                        control={<Radio />} 
                                                        label={_form.data.getPumpName(pump)} 
                                                        key={pump.guid}
                                                    />  
                                                ))
                                            }                    
                                            </RadioGroup>                    
                                        </FormControl>
                                    );
                                })
                            }
                            </Stack>                
                        </Grid>                
                    }
                    {
                        (!!_groundSelection || _isShowingOnlyOnePump) &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider'
                            }}
                            xs={_previewXS}
                            className={styles.groundPreview}
                        >
                            {
                                !!_groundSelection &&
                                <DevicePreviewCard
                                    device={_groundSelection}
                                    isUsedChecked={_form.data.heatpump.values.type === HeatpumpType.Ground}
                                    onUseCheck={(isChecked:boolean) => {
                                        const newData:Partial<IHeatpumpValuesForForm> = {
                                            type: isChecked ? HeatpumpType.Ground : HeatpumpType.NotSet,
                                            earthType: DEFAULT_EARTH_TYPE_VALUE,
                                            earthContureCalType: EarthContureCalculationType.Automatic,
                                            singlePumpViewPump: HeatpumpType.Ground
                                        };
                                        _form.setHeatingpump(newData);
                                    }}
                                    onClose={_handleGroundSelectionClose}
                                    extraSection={
                                        _data.type === HeatpumpType.Ground &&
                                        <>
                                            { 
                                                !_groundSelection.disableExtraHeatSource
                                                && _extraHeatSourceSelection 
                                            }
                                            <GroundPumpConturing />
                                        </>
                                    }
                                    extraInfo={_renderPumpTechnicalDetails(_groundSelection)}
                                    customName={_form.data.getPumpName(_groundSelection)}
                                />
                            }
                        </Grid>
                    }
                    {
                        (!!_airSelection || _isShowingOnlyOnePump) &&
                        <Grid 
                            item 
                            sx={{
                                borderColor: 'divider'
                            }}
                            xs={_previewXS}
                            className={styles.airPreview}
                        >
                            {
                                !!_airSelection &&
                                <DevicePreviewCard
                                    device={_airSelection}
                                    isUsedChecked={_data.type === HeatpumpType.Air}
                                    onUseCheck={(isChecked:boolean) => {
                                        const newData:Partial<IHeatpumpValuesForForm> = {
                                            type: isChecked ? HeatpumpType.Air : HeatpumpType.NotSet,
                                            singlePumpViewPump: HeatpumpType.Air
                                        };
                                        _form.setHeatingpump(newData);
                                    }}
                                    onClose={_handleAirSelectionClose}
                                    extraSection={
                                        _data.type === HeatpumpType.Air 
                                        && !_airSelection.disableExtraHeatSource
                                        && _extraHeatSourceSelection
                                    }
                                    extraInfo={_renderPumpTechnicalDetails(_airSelection)}
                                    customName={_form.data.getPumpName(_airSelection)}
                                />
                            }
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}
import * as React from 'react';
import { IAppSliderProps } from './IAppSliderProps';

import { 
    Box, Grid, Slider, Stack, Typography 
} from '@mui/material';
import { IState, useState } from '../../react-hooks/useState';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import { IconType } from '../../helpers/muiHelper';

export function AppSlider(props:IAppSliderProps) {

    const _unit:string = props.unit ? props.unit : '°C';
    const _selectedValue:IState<number|number[]> = useState<number|number[]>(props.initial);

    /**
     * Returns the label used for the selector
     */
    const _getLabel = (value?:number|number[]):string => {
        value = value === undefined ? _selectedValue.value : value;
        if(Array.isArray(value)) {
            return `${ (props.label || '') } ${value[0]}-${value[1]} ${ _unit }`.trim();
        }
        else {
            return `${ (props.label || '') } ${value}${ _unit }`.trim();
        }
    };

    //makes sure the label is bubbled up
    React.useEffect(() => {
        if(props.initial && props.onLabelChange) {
            props.onLabelChange(_getLabel(props.initial));
        }
    }, []);

    /**
     * Handles the slider value change event i.e. sets the new value for the slider
     * @param newValue The new value to be set
     */
    const _handleSliderChange = (newValue:number|number[]):void => {
        _selectedValue.set(newValue);
        if(props.onChange) {
            props.onChange(newValue);
        }
        if(props.onLabelChange) {
            props.onLabelChange(_getLabel(newValue));
        }
    };

    //makes sure that the value will change when the initial values change
    React.useEffect(() => {
        if(typeof props.initial === 'number') {
            _handleSliderChange(props.initial);
        }
    }, [props.initial, props.max, props.min]);

    if(Array.isArray(props.initial) && props.initial.length !== 2) {
        console.error('AppTemperatureSlider::Temperature range must have ONLY two values. Min and Max!');
        return null;
    }

    //creates the icon component used on the slider    
    const Icon:IconType = props.icon ? props.icon : DeviceThermostatIcon;
    return (
        <Box>
            {
                !props.hideLabel &&
                <Stack
                    direction={props.setLabelOnRight ? 'row-reverse' : 'row'}
                >
                    <Icon color={props.isDisabled ? 'inherit' : 'primary'} /> 
                    <Typography variant='subtitle2'>
                        { _getLabel() }
                    </Typography>                    
                </Stack>                
            }
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant='caption'>{ props.min } { _unit }</Typography>
                </Grid>
                <Grid item xs>
                    <Slider
                        value={_selectedValue.value}
                        onChange={(e, newValue:number|number[], active:number) => _handleSliderChange(newValue)}
                        max={props.max}
                        min={props.min}   
                        disabled={props.isDisabled}                    
                    />
                    </Grid>
                <Grid item>
                    <Typography  variant='caption'>{ props.max } { _unit }</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
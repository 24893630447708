import * as React from 'react';
import styles from './GroundPumpConturing.module.scss';

import { FormatColorReset, WaterDrop } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Slider, Stack, Typography } from '@mui/material';
import { IHeatpumpValuesForForm, IUseFormHookResult, useForm } from '../FormContext';
import { Format } from '../../../helpers/Format';
import { DevicesContext, IDevices } from '../DevicesContext';
import { IHeatpump } from '../IHeatpump';
import { AppInput } from '../../AppInput/AppInput';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { EarthContureCalculationType } from '../IProjectData';

enum EarthTypes {
    DrySandy,
    WetSandy,
    DryClay,
    WetClay,
    OveryWetSoil,
    Water
}

interface IEarthType {
    lastValue:number;
    type:EarthTypes;
}

const EARTH_TYPE_BREAKPOINTS:IEarthType[] = [{
    lastValue: 10,
    type: EarthTypes.DrySandy
}, {
    lastValue: 15,
    type: EarthTypes.WetSandy
}, {
    lastValue: 20,
    type: EarthTypes.DryClay
}, {
    lastValue: 25,
    type: EarthTypes.WetClay
}, {
    lastValue: 30,
    type: EarthTypes.OveryWetSoil
}, {
    lastValue: 50,
    type: EarthTypes.Water
}];

/**
 * The radio group name for the calculation
 */
const CALCULATION_TYPE_GROUP:string = 'Calculation-Type-f56122b4-be36-4853-a632-27f42baf9f32';

const MAX_CONTURE_LENGTH:number = 400;
const MIN_CONTURE_LENGTH:number = 240;

/**
 * Renders out the ground conturing for the ground heatpump
 */
export function GroundPumpConturing(props:{}) {

    const _form:IUseFormHookResult = useForm();
    const _data:IHeatpumpValuesForForm = _form.data.heatpump.values;
    const _devices:IDevices|null = React.useContext(DevicesContext);
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    
    /**
     * Returns the earth type label
     */
    const _getEarthTypeLabel = ():string => {
        let ret:string = '';

        const types:IEarthType[] = EARTH_TYPE_BREAKPOINTS.filter((t:IEarthType) => t.lastValue >= _data.earthType);
        types.sort((a:IEarthType, b:IEarthType) => a.type - b.type);
        
        const currentType:IEarthType = types[0];
        switch(currentType?.type){            
            case EarthTypes.Water:
                ret = _translations?.phrases.earthTypeWater || '';
                break;
            case EarthTypes.OveryWetSoil:           
                ret = _translations?.phrases.earthTypeOveryWetSoil || '';
                break;
            case EarthTypes.WetClay:
                ret = _translations?.phrases.earthTypeWetClay || '';
                break;
            case EarthTypes.DryClay:                
            ret = _translations?.phrases.earthTypeDryClay || '';
                break;
            case EarthTypes.WetSandy:                
                ret = _translations?.phrases.earthTypeWetSandy || '';
                break;
            default:
            case EarthTypes.DrySandy:                
                ret = _translations?.phrases.earthTypeDrySandy || ''; 
                break;
        }

        ret = Format.text(_translations?.phrases.earthContureLabel, _data.earthType, ret);   

        return ret;
    };
    /**
     * Returns the total length of the earth conture circle length
     */
    const _getEarthCircleTotalLength = ():number => {
        let ret:number;

        const pump:IHeatpump|null = _devices?.heatpumps.find((p:IHeatpump) => p.guid === _data.groundId) || null;        
        if(_data.earthContureCalType === EarthContureCalculationType.Manual) {
            ret = (_data.circleLength * _data.nrOfContures) || 0;
        }
        else {
            
            const powerW:number = (pump?.power || pump?.maxPower || 0) * 1000; //the power in wats
            ret = Math.round((powerW / _data.earthType) / 10) * 10; //gets the nearest 10 and
        }

        if(_data.totalEathContureLength !== ret) {
            const newData:Partial<IHeatpumpValuesForForm> = {
                totalEathContureLength: ret
            };
            _form.data.heatpump.setValues(newData); //we want the data to be updated without a render (infinity loop will happen then) <Maybe do a refactor in the future>
        }

        return ret;
    };
    const _earthCircleTotalLength:number = _getEarthCircleTotalLength();

    /**
     * Sets the number of conures and the circle length automatically in the form
     */
    const _setAndCalculateContures = ():void => {

        let nrOfContures:number = Math.ceil(_earthCircleTotalLength / MAX_CONTURE_LENGTH);
        let circleLength:number = Math.ceil((_earthCircleTotalLength / nrOfContures) / 10) * 10;
        if(circleLength < MIN_CONTURE_LENGTH) {
            circleLength = MIN_CONTURE_LENGTH;            
        }
        nrOfContures = Math.ceil(_earthCircleTotalLength / circleLength);

        if(_data.circleLength !== circleLength || _data.nrOfContures !== nrOfContures) {
            const newData:Partial<IHeatpumpValuesForForm> = {
                circleLength,
                nrOfContures
            };
            _form.setHeatingpump(newData);
        }
    };

    //calculates the conture number and length
    React.useEffect(() => {
        if(_data.earthContureCalType === EarthContureCalculationType.Manual) {
            //if the type is manual, then there is nothing to do
            return;
        }
        _setAndCalculateContures();
    }, [_earthCircleTotalLength]);

    return (
        <Box className={styles.container}>
            <Box>
                <Typography>{ _getEarthTypeLabel() }</Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <FormatColorReset />
                    <Slider 
                        valueLabelDisplay='auto'
                        min={5}
                        max={50}
                        value={_data.earthType}
                        onChange={(e, value:number|number[]) => {
                            const newData:Partial<IHeatpumpValuesForForm> = {
                                earthType: value as number
                            };
                            _form.setHeatingpump(newData);
                        }}
                    />
                    <WaterDrop />
                </Stack>
                <Typography>{ Format.text(_translations?.phrases.earthContureLengthLabel, _translations?.phrases.earthContureLength, _earthCircleTotalLength) }</Typography>
                <FormControl>
                    <RadioGroup
                        row
                        name={CALCULATION_TYPE_GROUP}
                        value={_data.earthContureCalType}
                        onChange={(e, value:string) => {
                            const newData:Partial<IHeatpumpValuesForForm> = {
                                earthContureCalType: parseInt(value)
                            };

                            if(newData.earthContureCalType === EarthContureCalculationType.Automatic) {
                                _setAndCalculateContures();
                            }

                            _form.setHeatingpump(newData);
                        }}
                    >
                        <FormControlLabel value={EarthContureCalculationType.Automatic} control={<Radio />} label={_translations?.phrases.automatic} />
                        <FormControlLabel value={EarthContureCalculationType.Manual} control={<Radio />} label={_translations?.phrases.manual}/>                        
                    </RadioGroup>
                </FormControl>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <AppInput 
                            label={_translations?.phrases.circleLength}
                            isFullWidth
                            isRequired
                            isDisabled={_data.earthContureCalType !== EarthContureCalculationType.Manual}
                            numericValue={_data.circleLength}
                            onNumberChange={(value:number|null) => {
                                const newData:Partial<IHeatpumpValuesForForm> = {
                                    circleLength: value || 0
                                };
                                _form.setHeatingpump(newData);
                            }}
                            inErrorMessage={
                                _data.isCircleLenghtInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }
                            onBlur={() => {
                                const newData:Partial<IHeatpumpValuesForForm> = {
                                    isCircleLenghtInError: !_data.circleLength
                                };
                                _form.setHeatingpump(newData);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppInput 
                            label={_translations?.phrases.nrOfContures}
                            isFullWidth
                            isRequired
                            isDisabled={_data.earthContureCalType !== EarthContureCalculationType.Manual}
                            numericValue={_data.nrOfContures}
                            onNumberChange={(value:number|null) => {
                                const newData:Partial<IHeatpumpValuesForForm> = {
                                    nrOfContures: value || 0
                                };
                                _form.setHeatingpump(newData);
                            }}
                            inErrorMessage={
                                _data.isNrOfConturesInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }
                            onBlur={() => {
                                const newData:Partial<IHeatpumpValuesForForm> = {
                                    isNrOfConturesInError: !_data.nrOfContures
                                };
                                _form.setHeatingpump(newData);
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
import * as React from 'react';
import styles from './Welcome_enGB.module.scss';
import { 
    Grid, 
    Stack, 
    Typography 
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

/**
 * Renders out the Welcome page content for en-GB
 */
export function Welcome_enGB(props:{}) {
    return (
        <Stack alignItems={'center'}>
            <Grid 
                container 
                spacing={4} 
                className={styles.content}
            >
                    <Grid item xs={12} className={styles.Spacer}></Grid>
                    <Grid 
                        item 
                        xs={12}
                        sm={6} 
                        className={styles.whatIs}
                    >
                        <Typography variant='h6'>What is alphaPRO?</Typography>
                        <Typography variant='body2' component='p'>
                            Excelent way to simplify the building's heating project creation!
                        </Typography>
                        <Typography variant='body2' component='p'>
                            Offers the sizing of heating, domestic hot water, and cooling circuits, selects the correct pipe diameters, provides a selection of suitable air-water and ground-source heat pumps, as well as the need for a ground loop.
                        </Typography>
                        <Typography variant='body2' component='p'>
                        Generates a detailed heating node diagram in .DXF format along with the heating node specifications.
                        </Typography>
                        <Typography variant='body2' component='p'>Includes pipeline DN, valve and valve KV calculations, as well as flow rates of circulation pumps and heat exchangers.</Typography>
                        <Typography variant='body2' component='p'>
                        This way, it creates heating projects in about 10 minutes from now on!
                        </Typography>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.LoginIMG}
                    >
                        <img src="/CADsample.webp"/>
                    </Grid>
                    <Grid item xs={12} className={styles.Spacer}></Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.LoginIMG}
                        sx={{
                            display:{
                                xs: 'none',
                                sm: 'flex'
                            }
                        }}
                    >
                        <img src="/LoginUseful.webp"/>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.Useful}                        
                    >
                        <Typography variant='h6'>
                            Why is alphaPro useful?
                        </Typography>
                        
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>More than 10 times simplification of designer's work</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Automation of input data</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Accurate and precise equipment selection</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Generation of heating node scheme in Autocad and PDF format</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Project is ready in 10 minutes</Typography>
                        </Stack>
                        
                    </Grid>
                    <Grid item xs={12} className={styles.Spacer}></Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.Useful}
                    >
                        <Typography variant='h6'>Who is alphaPRO intended for?</Typography>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Heating and cooling designers</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Heating and cooling engineers</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Heating and cooling installation companies</Typography>
                        </Stack>    
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.LoginIMG}>
                        <img src="/loginPerson.webp"/>
                    </Grid>
            </Grid>            
        </Stack>
    )
}
// Module used to calculate the DN values

/**
 * Describes a single DN value with for the DN calculation and filtering
 */
export interface IDNValue {
    /**
     * The maximum flow the pipes can take in liters.
     * Only values lower than this can be used for this DN
     * i.e. flow < maxFlow
     */
    maxFlow:number,
    /**
     * The nominal DN value of the pipe
     */
    DN:number;
    /**
     * The DN flow in liters.
     * @remarks ony appended by the g_getDNValue function
     */
    flow?:number;
    /**
     * The DN flow in m3/h.
     * @remarks ony appended by the g_getDNValue function
     */
    flow_m3?:number;
}

/**
 * Holds all the possible built in DN values and that can be calculated out
 */
export const POSSIBLE_DN_VALUES:IDNValue[] = [
    {
        maxFlow: 0.14,
        DN: 15
    },
    {
        maxFlow: 0.24,
        DN: 20
    },
    {
        maxFlow: 0.49,
        DN: 25
    },
    {
        maxFlow: 0.77,
        DN: 32
    },
    {
        maxFlow: 1.22,
        DN: 40
    },
    {
        maxFlow: 2.2,
        DN: 50
    },
    {
        maxFlow: 3.9,
        DN: 65
    },
    {
        maxFlow: 5.4,
        DN: 80
    },
    {
        maxFlow: 7.8,
        DN: 100
    },
    {
        maxFlow: 12.2,
        DN: 125
    },
    {
        maxFlow: 17.4,
        DN: 150
    },
    {
        maxFlow: 32.8,
        DN: 200
    },
    {
        maxFlow: Number.MAX_VALUE,
        DN: 250
    }
];

/**
 * Calculates the suitable DN value for from the inputs
 * @param max The max operating temperature
 * @param min The min operating temperature
 * @param heatingLoad The heating load of the system
 */
export function g_getDNValue(max:number, min:number, heatingLoad:number):IDNValue|null {
    let ret:IDNValue|null = null;

    if(
        typeof max !== 'number'
        || typeof min !== 'number'
        || typeof heatingLoad !== 'number'
    ) {
        return ret; //nothing to do if we can't calculate
    }

    const tempDiff:number = Math.abs(max - min);
    
    //flow formulas takes from the excel, 
    //NOTE! - No idea what the magic numbers are :/
    const flow_m3PerHour:number = ((((heatingLoad as number)*1000)/(998*4190*tempDiff))*3600);
    const flow_lPerHour = flow_m3PerHour * 0.277778;

    //gets the suitable DN
    const suitableDNs:IDNValue[] = POSSIBLE_DN_VALUES.filter((v:IDNValue) => flow_lPerHour < v.maxFlow); //filters out the too small DNs
    suitableDNs.sort((a:IDNValue, b:IDNValue) => a.maxFlow - b.maxFlow);

    ret = suitableDNs[0] ? {
        ...suitableDNs[0],
        flow: flow_lPerHour,
        flow_m3: flow_m3PerHour
    } : {
        flow: flow_lPerHour,
        DN: 0,
        maxFlow: 0,
        flow_m3: 0
    };

    return ret;
}
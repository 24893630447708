import * as React from 'react';
import { IPumpWarningProps } from './IPumpWarningProps';

import { ITranslationsContext, TranslationsContext } from "../../../translations/TranslationsContext";
import { IHeatpumpValuesForForm, IUseFormHookResult, useForm } from "../FormContext";
import { Alert } from '@mui/material';

/**
 * Renders out the pump warning if there are no suitable pumps
 */
export function PumpWarning(props:IPumpWarningProps) {
    const _form:IUseFormHookResult = useForm();
    const _data:IHeatpumpValuesForForm = _form.data.heatpump.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    const _hasGroundPumps:boolean = !!_data.groundDimensioningRange;
    const _hasAirPumps:boolean = !!_data.airDimensioningRange;
    
    if(_hasAirPumps || _hasGroundPumps) {
        return null;
    }    

    return (
        <Alert 
            severity="warning"
            className={props.className}
            sx={props.sx}
        >
            { _translations?.phrases.noHeatpumpToSelectWarning }
        </Alert>
    )
}
import * as React from 'react';
import { IHomeProps } from './IHomeProps';

import { Welcome } from '../../components/Welcome/Welcome';
import { 
    AuthenticatedTemplate, 
    UnauthenticatedTemplate 
} from '@azure/msal-react';
import { Dashboard } from '../../components/Dashboard/Dashboard';
import { IState, useState } from '../../react-hooks/useState';
import { CalculatorForm } from '../../components/CalculatorForm/CalculatorForm';
import { IProjectQuckInfo } from '../../models/IProjectQuckInfo';


/**
 * Renders out the home page
 */
export function Home(props:React.PropsWithChildren<IHomeProps>) {
    const _showNewForm:IState<boolean> = useState<boolean>(false);
    /**
     * Holds the project that needs to be opened.
     * If NULL then no project needs to be opened.
     */
    const _toBeOpenedProject:IState<IProjectQuckInfo|null> = useState<IProjectQuckInfo|null>(null);
    /**
     * Holds the project that needs to be cloned.
     * If NULL then no project needs to be cloned.
     */
    const _toBeClonedProject:IState<IProjectQuckInfo|null> = useState<IProjectQuckInfo|null>(null);

    return (
        <>
            <UnauthenticatedTemplate>
                <Welcome />                
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {
                    !_showNewForm.value && 
                    !_toBeClonedProject.value &&
                    !_toBeOpenedProject.value &&
                    <Dashboard 
                        onNewClick={() => {
                            _showNewForm.set(true);
                        }}
                        onCloneClick={(project:IProjectQuckInfo) => _toBeClonedProject.set(project)}
                        onOpenClick={(project:IProjectQuckInfo) => _toBeOpenedProject.set(project)}
                    />
                }
                {
                    _showNewForm.value &&
                    <CalculatorForm 
                        onHomeClick={() => _showNewForm.set(false)}
                    />
                }
                {
                    !!_toBeOpenedProject.value &&
                    <CalculatorForm 
                        project={_toBeOpenedProject.value}  
                        onHomeClick={() => _toBeOpenedProject.set(null)}
                    />
                }
                {
                    !!_toBeClonedProject.value &&
                    <CalculatorForm 
                        project={_toBeClonedProject.value}
                        isCloneOfProject
                        onHomeClick={() => _toBeClonedProject.set(null)}
                    />
                }
            </AuthenticatedTemplate>
        </>
    );
}
import * as React from 'react';
import styles from './TanksSelection.module.scss';

import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import { IHeatpumpValuesForForm, ITankSelectionFormValues, IUseFormHookResult, useForm } from '../FormContext';
import { IBoiler } from '../IBoiler';
import { DevicesContext, IDevices } from '../DevicesContext';
import { IHeatpump } from '../IHeatpump';
import { IAccuTank } from '../IAccuTank';
import { DevicePreviewCard } from '../DevicePreviewCard/DevicePreviewCard';
import { IHeatingElement } from '../IHeatingElement';
import { DeviceSelection } from '../DeviceSelection/DeviceSelection';
import { IDevice } from '../IDevice';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { CoolingType, HeatpumpType } from '../IProjectData';
import { Height, LocalFireDepartment, OpenInFull, Storm } from '@mui/icons-material';
import { Format } from '../../../helpers/Format';
import { PumpWarning } from '../PumpWarning/PumpWarning';


const BOILER_SELECTION_NAME:string = "Boiler-54b339bb-11bb-494b-9cc8-1ede9c45205b";
const ACCU_TANK_SELECTION_NAME:string = "Accu-Tank-e311ba74-8e08-4e68-b354-b84500293aad";
const COOLING_ACCU_TANK_SELECTION_NAME:string = "Cooling-Accu-Tank-c25abb89-7912-4d18-b451-cd2bce61e0d4";

/**
 * Renders out the Boler and Accu-Tank(s) selections
 */
export function TanksSelection(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:ITankSelectionFormValues = _form.data.tanksSelection.values;
    const _devices:IDevices|null = React.useContext(DevicesContext);
    const _pumpData:IHeatpumpValuesForForm = _form.data.heatpump.values;       
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    
    const pumpId:string = (_pumpData.type === HeatpumpType.Ground ? _pumpData.groundId : _pumpData.airId) || '';
    const _selectedPump:IHeatpump|null = _devices?.heatpumps.find((pump:IHeatpump) => pump.guid === pumpId) || null;    
    const _boilers:(IBoiler|null)[] = _selectedPump?.boilerIds.map((guid:string) => _devices?.boilers.find((b:IBoiler) => b.guid === guid) || null) || [];

    const _accuTankIdsArr:string[] = (_pumpData.extraHeatingSource.isEnabled && _selectedPump?.extraHeatSourceAccuIds ? _selectedPump?.extraHeatSourceAccuIds : _selectedPump?.accuTankIds) || [];
    const _coolingAccuTankIdsArr:string[] = (_form.data.generalInfo.values.coolingType === CoolingType.Wet && _selectedPump?.wetCoolingAccuTankIds ? _selectedPump?.wetCoolingAccuTankIds : _selectedPump?.accuTankIds) || [];

    const _accuTanks:(IAccuTank|null)[] = _accuTankIdsArr.map((guid:string) => _devices?.accuTanks.find((b:IAccuTank) => b.guid === guid) || null);    
    const _coolingAccuTanks:(IAccuTank|null)[] = _coolingAccuTankIdsArr.map((guid:string) => _devices?.accuTanks.find((b:IAccuTank) => b.guid === guid) || null);

    const _accuTank:IAccuTank|null = _accuTanks.find((t:IAccuTank|null) => t?.guid === _data.accuTankId) || null;
    const _coolingAccuTank:IAccuTank|null = _coolingAccuTanks.find((t:IAccuTank|null) => t?.guid === _data.coolingAccuTankId) || null;
    const _boiler:IBoiler|null = _boilers.find((t:IBoiler|null) => t?.guid === _data.boilerId) || null;

    const _hasCoolingAccuTank:boolean = _form.data.generalInfo.values.coolingType === CoolingType.Wet;
    const _hasBoiler:boolean = !!_boilers.length;

    const _nrOfColumns:number = 1 + (_hasCoolingAccuTank ? 1 : 0) + (_hasBoiler ? 1 : 0);
    const _columnXS:number = 12 / _nrOfColumns;

    const _accuTankHeatingElement:IHeatingElement|null = _devices?.heatingElements.find((d:IHeatingElement) => d.guid === _data.accuTankHeatingElementId) || null;
    const _boilerHeatingElement:IHeatingElement|null = _devices?.heatingElements.find((d:IHeatingElement) => d.guid === _data.boilerHeatingElementId) || null;

    //sorts the tanks
    _accuTanks.sort((a:IAccuTank|null, b:IAccuTank|null) => (a?.size || 0) - (b?.size || 0));
    _boilers.sort((a:IBoiler|null, b:IBoiler|null) => (a?.size || 0) - (b?.size || 0));


    /**
     * Renders out the technical details for a tank
     * @param tank The accu-tank or boiler whom details you want to shpw
     * @param isBoiler If TRUE shows the system that this is the boiler
     */
    const _renderTankTechnicalDetails = (tank:IBoiler|IAccuTank, isBoiler?:boolean):React.ReactNode => {
        const boiler:null|IBoiler = isBoiler ? tank as IBoiler : null;
        const colSize:number = isBoiler ? 4 : 6;

        return (
            <Grid
                container
                className={styles.technicalPreview}
                spacing={2}
            >
                {
                    !!boiler &&
                    <Grid
                        item
                        xs={colSize}
                    >
                        <Tooltip title={Format.text(_translations?.phrases.tooltipLabelValue, _translations?.phrases.coilLabel, `${Format.number(boiler.coilSize, 1)} m²`)} placement="bottom">
                            <Card
                                className={styles.technicalCard}
                            >
                                <Typography 
                                    variant='subtitle2'
                                    color="text.secondary"
                                    className={styles.labels}
                                >
                                    <Storm />                                
                                </Typography>
                                <Typography 
                                    variant='body2'
                                    color="text.secondary"
                                    className={styles.values}
                                >                        
                                    { Format.number(boiler.coilSize, 1)  } m²
                                </Typography>
                            </Card>
                        </Tooltip>                    
                    </Grid>
                }
                <Grid
                    item
                    xs={colSize}
                >
                    <Tooltip title={Format.text(_translations?.phrases.tooltipLabelValue, _translations?.phrases.widthLabel, `${tank.width} mm`)} placement="bottom">
                        <Card
                            className={styles.technicalCard}
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={styles.labels}
                            >
                                <OpenInFull />                                
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                        
                                { tank.width } mm
                            </Typography>
                        </Card>
                    </Tooltip>                    
                </Grid>
                <Grid
                    item
                    xs={colSize}
                >
                    <Tooltip title={Format.text(_translations?.phrases.tooltipLabelValue, _translations?.phrases.heightLabel, `${tank.height} mm`)} placement="bottom">
                        <Card
                            className={styles.technicalCard}
                        >
                            <Typography 
                                variant='subtitle2'
                                color="text.secondary"
                                className={styles.labels}
                            >
                                <Height />                                
                            </Typography>
                            <Typography 
                                variant='body2'
                                color="text.secondary"
                                className={styles.values}
                            >                        
                                { tank.height } mm
                            </Typography>
                        </Card>
                    </Tooltip>                    
                </Grid>     
            </Grid>
        )
    };

    return (
        <Box>
            <Typography variant='sectionHeading'>{_translations?.phrases.tankSelectionSectionHeading}</Typography>
            <PumpWarning />        
            <Grid container spacing={3}>                
                <Grid 
                    item 
                    xs={12} 
                    sm={_columnXS}
                    order={{
                        xs: 2,
                        sm: 1
                    }}
                >
                    <FormControl>
                        <FormLabel>{_translations?.phrases.accuTank}</FormLabel>
                        <RadioGroup
                            name={ACCU_TANK_SELECTION_NAME}
                            value={_data.accuTankId}
                            onChange={(e, value:string) => {
                                const newData:Partial<ITankSelectionFormValues> = {
                                    accuTankId: value
                                };
                                _form.setTankSelection(newData);
                            }}
                        >                            
                            {
                                _accuTanks.map((accuTank:IAccuTank|null) => {
                                    if(!accuTank) {
                                        return null;
                                    }

                                    return (
                                        <FormControlLabel 
                                            value={accuTank.guid} 
                                            control={<Radio />} 
                                            label={accuTank.name} 
                                            key={accuTank.guid}
                                        />
                                    );
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {
                    _hasCoolingAccuTank &&
                    <Grid 
                        item 
                        xs={12} 
                        sm={_columnXS}
                        order={{
                            xs: 4,
                            sm: 2
                        }}
                    >
                        <FormControl>
                            <FormLabel>{_translations?.phrases.coolingAccuTank}</FormLabel>
                            <RadioGroup
                                name={COOLING_ACCU_TANK_SELECTION_NAME}
                                value={_data.coolingAccuTankId}
                                onChange={(e, value:string) => {
                                    const newData:Partial<ITankSelectionFormValues> = {
                                        coolingAccuTankId: value
                                    };
                                    _form.setTankSelection(newData);
                                }}
                            >
                                {
                                    _coolingAccuTanks.map((accuTank:IAccuTank|null) => {
                                        if(!accuTank) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel 
                                                value={accuTank.guid} 
                                                control={<Radio />} 
                                                label={accuTank.name} 
                                                key={accuTank.guid}
                                            />
                                        );
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                }
                {
                    _hasBoiler &&
                    <Grid 
                        item 
                        xs={12} 
                        sm={_columnXS}
                        order={{
                            xs: 6,
                            sm: 3
                        }}
                    >
                        <FormControl>
                            <FormLabel>{_translations?.phrases.boiler}</FormLabel>
                            <RadioGroup
                                name={BOILER_SELECTION_NAME}
                                value={_data.boilerId}
                                onChange={(e, value:string) => {
                                    const newData:Partial<ITankSelectionFormValues> = {
                                        boilerId: value
                                    };
                                    _form.setTankSelection(newData);
                                }}
                            >
                                <FormControlLabel value={""} control={<Radio />} label={_translations?.phrases.noBoiler} />
                                {
                                    _boilers.map((boiler:IBoiler|null) => {
                                        if(!boiler) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel 
                                                value={boiler.guid} 
                                                control={<Radio />} 
                                                label={boiler.name} 
                                                key={boiler.guid}
                                            />
                                        );
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                }
                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 1,
                        sm: 4
                    }}
                >
                    <Card className={styles.checkboxCard}>
                        <CardContent className={styles.checkboxContainerCard}>
                            <FormControlLabel 
                                control={(
                                    <Checkbox  
                                        checked={_data.hasRecirculation}
                                        onChange={(e, checked:boolean) => {
                                            const newData:Partial<ITankSelectionFormValues> = {
                                                hasRecirculation: checked
                                            };
                                            _form.setTankSelection(newData);
                                        }}
                                        disabled={_data.isRecirculationDisabled}
                                    />
                                )}
                                label={_translations?.phrases.recirculation}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={_columnXS}
                    order={{
                        xs: 3,
                        sm: 5
                    }}
                >
                    {
                        !!_accuTank &&
                        <DevicePreviewCard
                            device={_accuTank}
                            onClose={() => {
                                const newData:Partial<ITankSelectionFormValues> = {
                                    accuTankId: null
                                };
                                _form.setTankSelection(newData);
                            }}
                            extraSection={(
                                _accuTank.heatingElementIds.length ? (
                                    <DeviceSelection 
                                        label={_translations?.phrases.heatingElement}
                                        clearSelectionLabel={_translations?.phrases.noHeatingElementNeeded}
                                        value={_accuTankHeatingElement}
                                        onChange={(value:IDevice|null) => {
                                            const newData:Partial<ITankSelectionFormValues> = {
                                                accuTankHeatingElementId: value?.guid || null
                                            };
                                            _form.setTankSelection(newData);
                                        }}                                        
                                        devices={_accuTank.heatingElementIds.map((guid:string) => {
                                            const device:IHeatingElement = _devices?.heatingElements.find((d:IHeatingElement) => d.guid === guid) as IHeatingElement;
                                            return device;
                                        })}
                                    />
                                ) : null
                            )}
                            extraInfo={_renderTankTechnicalDetails(_accuTank)}
                        />
                    }
                </Grid>                
                {
                    _hasCoolingAccuTank &&
                    <Grid 
                        item 
                        xs={12} 
                        sm={_columnXS}
                        order={{
                            xs: 5,
                            sm: 6
                        }}
                    >
                        {
                            !!_coolingAccuTank &&
                            <DevicePreviewCard
                                device={_coolingAccuTank}
                                onClose={() => {
                                    const newData:Partial<ITankSelectionFormValues> = {
                                        coolingAccuTankId: null
                                    };
                                    _form.setTankSelection(newData);
                                }}
                                extraInfo={_renderTankTechnicalDetails(_coolingAccuTank)}
                            />
                        }
                    </Grid>
                }
                {
                    _hasBoiler &&
                    <Grid 
                        item 
                        xs={12} 
                        sm={_columnXS}
                        order={{
                            xs: 7,
                            sm: 7
                        }}
                    >
                        {
                            !!_boiler &&
                            <DevicePreviewCard
                                device={_boiler}
                                onClose={() => {
                                    const newData:Partial<ITankSelectionFormValues> = {
                                        boilerId: null
                                    };
                                    _form.setTankSelection(newData);
                                }}
                                extraSection={(
                                    _boiler.heatingElementIds.length ? (
                                        <DeviceSelection 
                                            label={_translations?.phrases.heatingElement}
                                            clearSelectionLabel={_translations?.phrases.noHeatingElementNeeded}
                                            value={_boilerHeatingElement}
                                            onChange={(value:IDevice|null) => {
                                                const newData:Partial<ITankSelectionFormValues> = {
                                                    boilerHeatingElementId: value?.guid || null
                                                };
                                                _form.setTankSelection(newData);
                                            }}                                            
                                            devices={_boiler.heatingElementIds.map((guid:string) => {
                                                const device:IHeatingElement = _devices?.heatingElements.find((d:IHeatingElement) => d.guid === guid) as IHeatingElement;
                                                return device;
                                            })}                                            
                                        />
                                    ) : null
                                )}
                                extraInfo={_renderTankTechnicalDetails(_boiler, true)}
                            />
                        }
                    </Grid>
                }
            </Grid>
        </Box>
    );
}
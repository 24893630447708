import * as React from 'react';
import { IExpandButtonProps } from './IExpandButtonProps';
import styles from './ExpandButton.module.scss';

import { Build } from '@mui/icons-material';
import { Box, Button, Collapse, Fade, IconButton, Stack, Typography } from '@mui/material';
import { IState, useState } from '../../react-hooks/useState';
import { ButtonColor, IconType } from '../../helpers/muiHelper';
import { IRefState, useRefState } from '../../react-hooks/useRefState';

/**
 * Renders out an expandable text button
 */
export function ExpandButton(props:IExpandButtonProps) {
    const _showText:IRefState<boolean> = useRefState(false);
    const _buttonColor:ButtonColor = props.color || 'primary';

    const ButtonIcon:IconType = props.icon;
    const _collapsedButtonWidthHeight = '2.8em';

    let _floatBtnStyle:React.CSSProperties = {};
    if(props.isFloating) {
        _floatBtnStyle = {
            position: 'absolute',
            zIndex: 100,
            top: 0,
            left: 0
        };
    }
    
    const _touchTimeoutRef:React.MutableRefObject<any> = React.useRef(null);

    const _doClick = () => {
        if(!props.isDisabled && (_touchTimeoutRef.current === null || _touchTimeoutRef.current)) {            
            clearTimeout(_touchTimeoutRef.current);
            _showText.set(false)
            props.onClick();
        }
    };

    return (
        <Box
            style={{
                position: 'relative',
                display: 'inline-block',
                minWidth: _collapsedButtonWidthHeight,
                height: _collapsedButtonWidthHeight,
                margin: '0 0.25em',
                ...(props.style || {}),
            }}            
        >
            <div
                style={{
                    display: 'inline-block',
                    borderRadius: '2em',
                    boxSizing: 'border-box',
                    ..._floatBtnStyle                    
                }}
                onClick={() => {
                    if(props.isDisabled) {
                        props.onDisabledClick?.();
                    }
                }}
            >
                <Button
                    onMouseEnter={() => _showText.set(true)}
                    onMouseLeave={() => _showText.set(false)}
                    onTouchStart={(e) => {                        
                        clearTimeout(_touchTimeoutRef.current);
                        _touchTimeoutRef.current = setTimeout(() => {
                            _showText.set(true);
                        }, 300);
                    }}
                    onTouchEnd={(e) => {
                        if(_showText.ref.current) {
                            _showText.set(false);
                        } else {
                            clearTimeout(_touchTimeoutRef.current);
                        }
                    }}
                    onClick={_doClick}
                    size='large'
                    color={_buttonColor}
                    variant='contained'
                    sx={{
                        borderRadius: '2em',
                        paddingLeft: '0.35em',
                        paddingRight: '0.35em',
                        minWidth: _collapsedButtonWidthHeight,
                        height: _collapsedButtonWidthHeight,
                        paddingTop: '0.55em',
                        paddingBottom: '0.5em',
                        margin: '0px 0px 0px 0px !important',
                        '&:hover': {
                            transform: 'scale(1)'
                        },
                        ...(props.sx || {})
                    }}                    
                    className={`${styles.button} ${props.className || ''}`}
                    disabled={props.isDisabled}                    
                >
                    <Stack direction={"row"}>
                        <Collapse orientation='horizontal' in={_showText.value}>
                            <Fade in={_showText.value}>                
                                <Typography 
                                    variant="body2" 
                                    component="span"
                                    className={styles.text}
                                >
                                    { props.label }
                                </Typography>                
                            </Fade>
                        </Collapse>
                        <ButtonIcon />
                    </Stack>                
                </Button>
            </div>
        </Box>
    );
}
import { ITranslations } from "./ITranslations";

export const g_enGB:ITranslations = {
    projectInfoSectionHeading: 'Project details',
    heatingLoadSectionHeading: 'Heating load',
    heatingCircutsSectionHeading: 'Heating',
    coolingCircutsSectionHeading: 'Cooling',
    extrasSectionHeading: 'Extras',
    heatpumpSectionHeading: 'Heatpump',
    tankSelectionSectionHeading: 'DHW and accumulation tank',

    optionalMarker: 'Optional',

    projectName: 'Project name',
    projectAddress: 'Address',
    projectClimateZone: 'Climate zone',
    projectHeatingArea: 'Heating area',

    //Menubar expand collapse

    menuBarExpand: "Expand",
    menuBarCollapse: "Collapse",

    dragAndDropTitle: "Drag 'n' drop logo of 498x186px (PNG, SVG, JPG, GIF) or click to upload a logo",
    dragAndDropDragText: "Drop logo here",
    dragAndDropPrompt: "Drag 'n' drop logo or click to upload logo",
    dragAndDropSubPrompt: "489x186px (PNG, SVG, JPG, GIF)",

    profileCompanyLabel: "Company",
    profilePhoneLabel: "Phone",

    coolingLoadLabel: "Cooling load",
    isCoolingEnabledLabel: "Project with cooling",
    coolingTypeRadioGroupLabel: "Cooling options",
    coolingTypeRadioNoneLabel: "Not required",
    coolingTypeRadioWetLabel: "Wet (from +7°C)",
    coolingTypeRadioDryLabel: "Dry (from +18°C)",

    projectDrafterLabel: "Project drafter",
    projectManagerLabel: "Project manager",
    accountableProjectDrafterLabel: "Accountable project drafter",
    projectNumberLabel: "Project number",
    projectClientLabel: "Client",

    newBuilding: 'New building',
    renovatedHouse: 'Renovated building',
    selectRenovatedHeatloadsubheading: 'Enter the consumption according to heat source per year',
    roomTemperature: 'Room temperature',
    balanceTemperature: 'Balance temperature',
    heatingLoadLoss: 'Heatload',
    electricityConsumptionPerYear: 'Electricity',
    gasConsumptionPerYear: 'Gas',
    pelletConsumptionPerYear: 'Pellet',
    oilConsumptionPerYear: 'Oil',
    woodConsumptionPerYear: 'Wood',
    propaineConsumptionPerYear: 'Propane',

    floorHeating: 'Underfloor heating',
    radiatorHeating: 'Radiators',
    ventilationHeating: 'Ventilation heating',
    poolHeating: 'Pool heating',
    extraCircleWithoutValveHeating: 'Extra circle heating without 3 way valve',
    extraCircleHeating: 'Extra circle heating with 3 way valve',

    heatingCircutHeatingLoad: 'Heating load',
    heatingCircutResistance: 'Pressure loss',
    heatingCircutDN: 'Dn',
    coolingCircutHeatingLoad: "Cooling load",

    operatingRange: 'Operating range',
    coolingInnerParts: 'Cooling fancoils',
    ventilationCoolingBatteries: 'Ventilation cooling batteries',

    recirculation: 'Recirculation',
    floorHeating3WayValve: '3-way valve',
    extraHeatingSource: 'Additional heatsource',

    dimensioning: 'Dimensioning',
    integratedBoilerPumpGroupName: 'Integ. DHW tank',
    externalBoilerPumpGroupName: 'Ext. DHW tank',
    compactPumpGroupName: 'Compact',
    outdoorPumpGroupName: 'Outdoor installation',
    indoorPumpGroupName: 'Indoor installation',
    cascadePumpGroupName: "Cascade",
    useCheckboxLabel: 'Select',
    groundpump: 'Geothermal',
    airpump: 'Air to water',

    earthContureLabel: '{0} W/rm - {1}',
    earthContureLength: 'Ground loop length',
    earthContureLengthLabel: '{0}: {1}m',
    automatic: 'Automatic',
    manual: 'Manual',
    circleLength: 'Ground loop length',
    nrOfContures: 'No. of loops',
    earthTypeWater: 'Water',
    earthTypeOveryWetSoil: 'Overly wet soil',
    earthTypeWetClay: 'Wet clay',
    earthTypeDryClay: 'Dry clay',
    earthTypeWetSandy: 'Wet sandy',
    earthTypeDrySandy: 'Dry sandy',

    heatpumpSubTypeInverter: 'Inverter',
    heatpumpSubTypeOnOff: 'ON/OFF',
    heatpumpSubTypeInverterMonoblock: 'Inverter monoblock',
    heatpumpSubTypeSplitHM: 'Split with ext. DHW tank',
    heatpumpSubTypeSplitHT: 'Split with int. DHW tank',
    heatpumpSubTypeOnOffMonoblock: 'ON/OFF monoblock',

    accuTank: 'Accumulation tank',
    coolingAccuTank: 'Cooling accumulation tank',
    boiler: 'DHW tank',
    noBoiler: 'Do not include a DHW tank',
    noHeatingElementNeeded: 'Not required',
    heatingElement: 'Heating element',

    nextBtnLabel: 'Next',
    previousBtnLabel: 'Previous',
    generateBtnLabel: 'Create the project',

    heatingCoolingCircutPreviewUnit: '{0}-{1}°C',
    noHeatpumpToSelectWarning: 'We are sorry, but there are no heatpumps available to match your heatload requirement.',
    generationResultsSectionHeating: 'Project files',
    readMoreBtn: 'Technical data',

    downloadDfxBtnLabel: "Download DFX",
    downloadDfxToolip: "Download the project in Autocad format(DFX)",
    homeBtn: "Back to dashboard",
    downloadPdfBtnLabel: "Download PDF",
    downloadPdfTooltip: "Download the project in PDF format",

    downloadExcelBtnLabel: "Download Excel",
    downloadExcelTooltip: "Dowload the project in Excel format",

    saveChangesBtnLabel: "Save changes",
    backToHomeWarningTitle: "Unsaved changes",
    backToHomeWarningContent: "Are you sure you wish to navigate back to the dashboard?",

    //manuals 
    manualsButtonText: "Manuals",
    manualsButtonTooltip: "Select the manual you want to download",
    installationManual: "Installation",
    technicalnManual: "Technical",
    declarationManual: "Declaration",
    automationManual: "Automation",
    productLineManual: "Product Line",

    //hetpump technical details
    hasElementIndicator: "Included",
    doesNotHaveElementIndicator: "Not included",
    domesticHotWaterABValveLabel: "DHW AB valve",
    heatingCircuitPumpLabel: "Heating circulation pump",
    brineCircuitPumpLabel: "Ground loop circulation pump",
    maxInletTempLabel: "Max inlet temp.",
    maxInletTempValue: "{0}°C",
    integratedHeatingElementLabel: "Integrated heating element",
    integratedHeatingElementValue: "{0}kW",
    integratedBoilerLabel: "Integrated DHW tank",
    integratedBoilerValue: "{0}l",
    compressorLabel: "Compressor",
    powerLabel: "Power",
    compressorTypeInverter: "Inverter",
    compressorTypeOnOff: "ON/OFF",
    compressorTypeDualCompressorOnOff: "Dual Compressor ON/OFF",
    dualCompressorPowerValue: "{0}kW / {1}",
    powerValue: "{0}kW",
    inverterPowerValue: "{0}-{1}kW",
    tooltipLabelValue: "{0}: {1}",
    
    // Tanks tech details
    coilLabel: "Coil",
    heightLabel: "Height",
    widthLabel: "Diameter",

    //error messages
    requiredFieldErrorMessage: "Required field",
    nonNumbericInputErrorMessage: "Only numbers are allowed",
    noHeatingCircutSelectedErrorMessage: "At least one of the heating circuts needs to be included!",
    noCoolingCircutsSelectedErrrorMessage: "At least one of the cooling circuts needs to be included!",
    noHeatpumpSelectedErrorMessage: "No heatpump selected!",
    combinedHeatloadBiggerThanProject: "The combined heatload is larger than the total heatload specified in project details. You can still proceed with the project.",
    combinedCoolingLoadBiggerThanProject: "The combined cooling load is larger than the cooling load specified in project details. You can still proceed with the project.",

    successfulyGeneratedMessage: "The project \"{0}\" has been created",
    successfulySavedMessage: "The project \"{0}\" was saved",
    successfullyClonedMessage: "The project \"{0}\" was cloned",
    longGenerationMessage: "The project creation is in progress, please wait!",

    unknownFetchErrorMessage: "An error has occured. Please try again. If the error presists then contact support.",
    noSuitablePumpsErrorMessage: "There are no heatpumps available for the requested settings.",

    // Dashboard
    heatPumpPhrase: "Heatpump",
    dashboardDate: "Date",
    actionsHeading: "Actions",

    deleteBtnLabel: "Delete",
    cancelBtnLabel: "Cancel",
    deleteProjectBtnTooltip: "Delete the project",
    cloneProjectBtnTooltip: "Clone the project",
    viewProjectBtnTooltip: "View the project",
    deleteProjectDialogHeading: "Delete project",
    addNewProjectBtnLabel: "Add new",
    deleteProjectWarning: "Are you sure you want to delete the project? {0} - {1}",

    editProfile: "Edit profile",
    resetPassword: "Change password",
    noProjectsYet: "There are no projects created yet, please proceed to create a new project.",
    //profile avatar
    settingsMenuLabel: "Profile settings",
    settingPanelHeading: "Profile settings",
    profileNameLabel: "Name",
    titleBlockSettingsHeading: "Project title block",
    settingsProjectDrafterLabel: "Project drafter",
    settingsProjectManagerLabel: "Project manager",
    settingsAccountableProjectDrafterLabel: "Accountable project drafter",
    settingsProjectNumberLabel: "Project number",
    settingsSaveSettingsBtnLabel: "Save settings",

    // Pre-login welcome
    preLoginWelcome: "Please enter the AlphaPRO platform using the LOG-IN button",
    logInButton: "Log-in",
    logOutButton: "Log-out",

    // Root
    noRowsLabel: 'No rows',
    noResultsOverlayLabel: 'No results found.',
    
    // Density selector toolbar button text
    toolbarDensity: 'Density',
    toolbarDensityLabel: 'Density',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',
    
    // Columns selector toolbar button text
    toolbarColumns: 'Columns',
    toolbarColumnsLabel: 'Select columns',
    
    // Filters toolbar button text
    toolbarFilters: 'Filters',
    toolbarFiltersLabel: 'Show filters',
    toolbarFiltersTooltipHide: 'Hide filters',
    toolbarFiltersTooltipShow: 'Show filters',
    toolbarFiltersTooltipActiveSingle: '{0} active filter',
    toolbarFiltersTooltipActiveMultiple: '{0} active filters',
        
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Search…',
    toolbarQuickFilterLabel: 'Search',
    toolbarQuickFilterDeleteIconLabel: 'Clear',
    
    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Download as CSV',
    toolbarExportPrint: 'Print',
    toolbarExportExcel: 'Download as Excel',
    
    // Columns panel text
    columnsPanelTextFieldLabel: 'Find column',
    columnsPanelTextFieldPlaceholder: 'Column title',
    columnsPanelDragIconLabel: 'Reorder column',
    columnsPanelShowAllButton: 'Show all',
    columnsPanelHideAllButton: 'Hide all',
    
    // Filter panel text
    filterPanelAddFilter: 'Add filter',
    filterPanelRemoveAll: 'Remove all',
    filterPanelDeleteIconLabel: 'Delete',
    filterPanelLogicOperator: 'Logic operator',
    filterPanelOperator: 'Operator',
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: 'Columns',
    filterPanelInputLabel: 'Value',
    filterPanelInputPlaceholder: 'Filter value',
    
    // Filter operators text
    filterOperatorContains: 'contains',
    filterOperatorEquals: 'equals',
    filterOperatorStartsWith: 'starts with',
    filterOperatorEndsWith: 'ends with',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'is empty',
    filterOperatorIsNotEmpty: 'is not empty',
    filterOperatorIsAnyOf: 'is any of',
    filterOperatorEqual: '=',
    filterOperatorNotEqual: '!=',
    filterOperatorGratherThen: '>',
    filterOperatorGratherThenEqual: '>=',
    filterOperatorLessThen: '<',
    filterOperatorLessThenEqual: '<=',
    
    // Header filter operators text
    headerFilterOperatorContains: 'Contains',
    headerFilterOperatorEquals: 'Equals',
    headerFilterOperatorStartsWith: 'Starts with',
    headerFilterOperatorEndsWith: 'Ends with',
    headerFilterOperatorIs: 'Is',
    headerFilterOperatorNot: 'Is not',
    headerFilterOperatorAfter: 'Is after',
    headerFilterOperatorOnOrAfter: 'Is on or after',
    headerFilterOperatorBefore: 'Is before',
    headerFilterOperatorOnOrBefore: 'Is on or before',
    headerFilterOperatorIsEmpty: 'Is empty',
    headerFilterOperatorIsNotEmpty: 'Is not empty',
    headerFilterOperatorIsAnyOf: 'Is any of',
    headerFilterOperatorEqual: 'Equals',
    headerFilterOperatorNotEqual: 'Not equals',
    headerFilterOperatorGratherThen: 'Greater than',
    headerFilterOperatorGratherThenEqual: 'Greater than or equal to',
    headerFilterOperatorLessThen: 'Less than',
    headerFilterOperatorLessThenEqual: 'Less than or equal to',
    
    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',
    
    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Show columns',
    columnMenuManageColumns: 'Manage columns',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Hide column',
    columnMenuUnsort: 'Unsort',
    columnMenuSortAsc: 'Sort by ASC',
    columnMenuSortDesc: 'Sort by DESC',
    
    // Column header text
    columnHeaderFiltersTooltipActiveSingle: '{0} active filter',
    columnHeaderFiltersTooltipActiveMultiple: '{0} active filters',    
    columnHeaderFiltersLabel: 'Show filters',
    columnHeaderSortIconLabel: 'Sort',
    
    // Rows selected footer text
    footerRowSelectedSingle: '{0} row selected',
    footerRowSelectedMultiple: '{0} rows selected',
        
    // Total row amount footer text
    footerTotalRows: 'Total Rows:',
    
    // Total visible row amount footer text
    footerTotalVisibleRows: '{0} of {1}',
    
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    checkboxSelectionSelectAllRows: 'Select all rows',
    checkboxSelectionUnselectAllRows: 'Unselect all rows',
    checkboxSelectionSelectRow: 'Select row',
    checkboxSelectionUnselectRow: 'Unselect row',
    
    // Boolean cell text
    booleanCellTrueLabel: 'yes',
    booleanCellFalseLabel: 'no',
    
    // Actions cell more text
    actionsCellMore: 'more',
    
    // Column pinning text
    pinToLeft: 'Pin to left',
    pinToRight: 'Pin to right',
    unpin: 'Unpin',
    
    // Tree Data
    treeDataGroupingHeaderName: 'Group',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',
    
    // Grouping columns
    groupingColumnHeaderName: 'Group',
    groupColumn: 'Group by {0}',
    unGroupColumn: 'Stop grouping by {0}',
    
    // Master/detail
    detailPanelToggle: 'Detail panel toggle',
    expandDetailPanel: 'Expand',
    collapseDetailPanel: 'Collapse',
        
    // Row reordering text
    rowReorderingHeaderName: 'Row reordering',
    
    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
};
import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { enqueueSnackbar, closeSnackbar } from 'notistack';


/**
 * Fires off an error toast messagae
 * @param message The error message to be shown
 */
export function g_errorToast(message:string|null|undefined):void {
    enqueueSnackbar(message || '', {
        variant: 'error',
        anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom'
        },
        action: (key) => (
            <IconButton
                onClick={() => closeSnackbar(key)}
                color="inherit"
            >
                <Cancel />
            </IconButton>
        )
    });
}
/**
 * Fires off a success toast message
 * @param message The success toast message
 */
export function g_successToast(message:string|null|undefined):void {
    enqueueSnackbar(message || '', {
        variant: 'success',
        anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
        },
        action: (key) => (
            <IconButton
                onClick={() => closeSnackbar(key)}
                color="inherit"
            >
                <Cancel />
            </IconButton>
        )
    });
}
/**
 * Fires off a warning toast message
 * @param message The success toast message
 */
export function g_warningToast(message:string|null|undefined):void {
    enqueueSnackbar(message || '', {
        variant: 'warning',
        anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
        },
        action: (key) => (
            <IconButton
                onClick={() => closeSnackbar(key)}
                color="inherit"
            >
                <Cancel />
            </IconButton>
        )    
    });
}
/**
 * Fires off a info toast message
 * @param message The success toast message
 */
export function g_infoToast(message:string|null|undefined):void {
    enqueueSnackbar(message || '', {
        variant: 'info',
        anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
        },
        action: (key) => (
            <IconButton
                onClick={() => closeSnackbar(key)}
                color="inherit"
            >
                <Cancel />
            </IconButton>
        )
    });
}
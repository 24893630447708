import * as React from 'react';
import { DevicesContext, IDevices } from "../DevicesContext";
import { HeatpumpManualLinkType, IHeatpump, IHeatpumpManualLink } from '../IHeatpump';
import { ResultProjectContext } from '../Result/Result';
import { IProjectData } from '../IProjectData';
import { IState, useState } from '../../../react-hooks/useState';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { FolderCopy } from '@mui/icons-material';
import { CultureContext } from '../../../helpers/cultureHelper';

/**
 * Renders out the manuals menu button
 */
export function ManualsButton(props:{}) {
    const _devices:IDevices|null = React.useContext(DevicesContext);
    const _resultProject:IState<IProjectData|null>|null = React.useContext(ResultProjectContext);
    const _pump:IHeatpump|null = _devices?.heatpumps.find(p => p.guid === _resultProject?.value?.heatpump.selection?.guid) || null;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _culture:IState<string>|null = React.useContext(CultureContext);
    
    /**
     * Holds the anchor element of the menu i.e. the button DIV.
     * If NULL shows that the menu should be hidden.
     */
    const _menuAnchor:IState<HTMLButtonElement|null> = useState<HTMLButtonElement|null>(null);
    /**
     * Closes the opened profile menu
     */
    const _handleMenuClose = ():void => {
        _menuAnchor.set(null);
    };

    const _manuals:IHeatpumpManualLink[] = (_pump?.manuals || []).filter((l:IHeatpumpManualLink)=> !l.lang || l.lang === _culture?.value);

    if(!_manuals.length) {
        return null;
    }

    return (
        <>
            <Tooltip title={_translations?.phrases.manualsButtonTooltip} arrow>
                <Button
                    startIcon={<FolderCopy />}
                    onClick={(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        _menuAnchor.set(e.currentTarget);
                    }}
                >
                    { _translations?.phrases.manualsButtonText }
                </Button>                
            </Tooltip>
            <Menu
                anchorEl={_menuAnchor.value}
                open={!!_menuAnchor.value}
                onClose={_handleMenuClose}
                onClick={_handleMenuClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    padding: '0 0 0 0',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    (_pump?.manuals || []).filter((l:IHeatpumpManualLink)=> !l.lang || l.lang === _culture?.value).map((link:IHeatpumpManualLink, index:number) => {
                        let name:string|undefined = link.text || '';
                        if(!name) {
                            switch(link.type) {
                                case HeatpumpManualLinkType.Installation:
                                    name = _translations?.phrases.installationManual;
                                    break;
                                case HeatpumpManualLinkType.Technical:
                                    name = _translations?.phrases.technicalnManual;
                                    break;
                                case HeatpumpManualLinkType.Declaration:
                                    name = _translations?.phrases.declarationManual;
                                    break;
                                case HeatpumpManualLinkType.Automation:
                                    name = _translations?.phrases.automationManual;
                                    break;
                                case HeatpumpManualLinkType.ProductLine:
                                    name = _translations?.phrases.productLineManual;
                                    break;
                            }
                        }

                        return (
                            <MenuItem
                                onClick={() => {
                                    window.open(link.url, '_blank')
                                }}
                                key={`${link.url}_${index}`}
                            >
                                {name}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}
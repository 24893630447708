import { IconButton, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import { IAppTabsProps,IAppTab } from './IAppTabsProps';
import * as React from 'react';
import { IState, useState } from '../../react-hooks/useState';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

/**
 * Returns the App tabs that can collapse into a menu button.
 * @remarks Works only as a controlled element
 */
export function AppTabs(props:IAppTabsProps) {
    /**
     * The reference tabs' scrollable DIV.
     * Used to check if the menu should be visible or not
     */
    const _referenceTabScrollDiv:React.MutableRefObject<HTMLElement|null> = React.useRef<HTMLElement|null>(null);
    /**
     * If TRUE the menu should be visble instead of the tabs
     */
    const _displayMenu:IState<boolean> = useState<boolean>(false);
    /**
     * Holds the width that the system currently thinks the component has
     */
    const _widthRef:React.MutableRefObject<number> = React.useRef(0);

    /**
     * Checks to see if the menu format should be visible or not
     */
    const _checkIfMenuShouldBeVisible = ():void => {
        let isVisible:boolean = false;
        if(_referenceTabScrollDiv.current && _referenceTabScrollDiv.current.scrollWidth > _referenceTabScrollDiv.current.offsetWidth) {
            isVisible = true;
        }
        _displayMenu.set(isVisible);
    };
    /**
     * Holds the ancor element for the tab menu
     */
    const _menuAnchor:IState<HTMLButtonElement|null> = useState<HTMLButtonElement|null>(null);
    /**
     * Closes the tab menu
     */
    const _handleMenuClose = ():void => {
        _menuAnchor.set(null);
    };    
    /**
     * Handles the window resize i.e. resizes the loader on resize
     */
    const _handleWindowResize = () => {        
        if(_widthRef.current !== _referenceTabScrollDiv.current?.offsetWidth || 0) {
            _checkIfMenuShouldBeVisible();
            _handleMenuClose();
        }
        _widthRef.current = _referenceTabScrollDiv.current?.offsetWidth || 0;
    };

    /**
     * The observer that tracks the dimensions of the container
     */
    const _observer = new ResizeObserver(_handleWindowResize);
        
    return (
        <>
            <div
                style={{
                    overflow: 'hidden',
                    height: '0px'
                }}
                ref={(elm) => {
                    if(elm) {
                        _observer.disconnect();
                        _observer.observe(elm);
                    }
                }}
            >
                <Tabs 
                    indicatorColor='primary'
                    orientation='horizontal'
                    value={props.selectedValue}
                    ref={(elm:HTMLButtonElement) => {                        
                        _referenceTabScrollDiv.current = elm?.firstElementChild as HTMLElement; //basically selects the tab scroll area
                        _checkIfMenuShouldBeVisible();
                    }}
                >
                    {
                        props.tabs.map((tab:IAppTab) => (
                            <Tab 
                                label={tab.label} 
                                value={tab.value}  
                                key={tab.value}                          
                            />
                        ))
                    }
                </Tabs>
            </div>
            <div
                style={{
                    position: 'relative'
                }}
            >
                <Tabs 
                    indicatorColor='primary'
                    orientation='horizontal'
                    value={props.selectedValue}
                    onChange={(e, newValue:any) => {
                        props.onChange?.(newValue);
                    }}
                >
                    {
                        props.tabs.filter((t:IAppTab) => !_displayMenu.value || _displayMenu.value && t.value === props.selectedValue).map((tab:IAppTab) => (
                            <Tab 
                                label={tab.label} 
                                value={tab.value}  
                                key={tab.value}                                                          
                            />
                        ))
                    }
                </Tabs>
                {
                    _displayMenu.value &&
                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton
                            onClick={(e) => {
                                _menuAnchor.set(e.currentTarget)
                            }}
                            color='primary'
                            size='large'
                            sx={{
                                fontSize: '2rem'
                            }}
                        >
                            <ExpandCircleDownIcon/>
                        </IconButton>
                    </div>
                }               
            </div>
            <Menu
                anchorEl={_menuAnchor.value}
                open={!!_menuAnchor.value}
                onClose={_handleMenuClose}
                onClick={_handleMenuClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    padding: '0 0 0 0',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    props.tabs.filter((t:IAppTab) => t.value != props.selectedValue).map((tab:IAppTab) => (
                        <MenuItem
                            onClick={() => {
                                props.onChange?.(tab.value);
                            }}
                            key={tab.value}
                        >
                            { tab.label }
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    )
}
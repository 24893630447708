import * as React from 'react';
import styles from './GeneralInfoPreview.module.scss';

import { ITranslationsContext, TranslationsContext } from "../../../translations/TranslationsContext";
import { IGeneralInfoValues, IUseFormHookResult, useForm } from "../FormContext";
import { Box, Collapse, Fade, Typography } from '@mui/material';
import { HeatingLoadBuildingType } from '../IProjectData';
import { AcUnit, LocalFireDepartment } from '@mui/icons-material';

/**
 * Reners out the general info preview into the stepper form
 */
export function GeneralInfoPreview(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:IGeneralInfoValues = _form.data.generalInfo.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    if(!_data.projectName && !_data.area && !_data.heatload) {
        return null;
    }

    let _buildingType:string|undefined = '';
    switch(_data.buildingType) {
        case HeatingLoadBuildingType.New:
            _buildingType = _translations?.phrases.newBuilding;
            break;
        case HeatingLoadBuildingType.Renovated:
            _buildingType = _translations?.phrases.renovatedHouse;
            break;
    }

    const _projectName:string = (_data.projectName || '').trim();
    return (
        <Box>            
            <Typography 
                variant='body2' 
                component={"span"}
            >
                { _projectName } 
                {
                    !!_data.area && !!_projectName && 
                    ' - '
                }              
            </Typography>
            {
                !!_data.area &&
                <Typography 
                    variant='body2' 
                    component={"span"}
                    sx={{
                        color: "primary.dark",
                    }}
                    className={styles.value}
                >
                    { _data.area }m²               
                </Typography>
            }
            {
                !!(_data.heatload || _data.coolingLoad) &&
                <Box>            
                    <Typography 
                        variant='body2' 
                        component={"span"}
                    >
                        { `${_buildingType} - ` }
                    </Typography> 
                    <Typography 
                        variant='body2' 
                        component={"span"}
                        sx={{
                            color: "primary.dark",
                        }}
                        className={styles.value}
                    >
                        {
                            !!_data.heatload &&
                            <>
                                <LocalFireDepartment />
                                { _data.heatload }kW               
                            </>
                        }
                        {
                            _data.isCoolingCircutsEnabled &&
                            !!_data.coolingLoad
                            &&
                            <>
                                {
                                    !!_data.heatload &&
                                    ' / '
                                }
                                <AcUnit />
                                { _data.coolingLoad }kW
                            </>
                        }
                    </Typography>
                </Box>
            }
        </Box>
    );
}
import styles from './AppIcons.module.scss';
import { IAppIconsProps } from './IAppIconsProps';

export function Pump(props:IAppIconsProps) {
    return (
        <svg 
            style={{
                width: props.width || '1.95em',
                ...(props.style || {})
            }} 
            viewBox="-10 -5 1034 1034" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            version="1.1"
        >
            <path fill={props.color || "#000"} d="M500 177q-115 0 -215 58q-96 56 -152 152q-58 100 -58 215t58 215q56 96 152 152q100 58 215 58t215 -58q96 -56 152 -152q58 -100 58 -215t-58 -215q-56 -96 -152 -152q-100 -58 -215 -58zM500 259q93 0 173 46q78 46 124 124q46 80 46 173t-46 173q-46 78 -124 124 q-80 46 -173 46q-36 0 -72 -7l389 -336l-388 -336q35 -7 71 -7zM333 302l361 302l-358 300q-82 -45 -130 -124q-49 -82 -49 -177.5t48 -176.5q48 -79 128 -124z" />
        </svg>
    )
}
import * as React from 'react';
import styles from './DevicePreviewCard.module.scss';

import { Box, Checkbox, FormControlLabel, IconButton, Paper, Switch, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { IDevicePreviewCardProps } from './IDevicePreviewCardProps';
import { Build, Close, RadioButtonChecked, RadioButtonUnchecked, ReadMore } from '@mui/icons-material';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { g_protectedResources } from '../../../authConfig';
import useFetchWithMsal from '../../../react-hooks/useFetchWithMsal';
import { LoadingButton } from '@mui/lab';
import { IState, useState } from '../../../react-hooks/useState';
import { IDeviceDescription } from '../IDeviceDescription';

export function DevicePreviewCard(props:IDevicePreviewCardProps) {

    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _msalGetDescription = useFetchWithMsal(g_protectedResources.apiAllDevices.scopes.generator);
    const _moreDescriptionHtml:IState<IDeviceDescription|null> = useState<IDeviceDescription|null>(null);
    const _theme:Theme = useTheme();

    const _isDeviceChecked:boolean = !!(props.onUseCheck && props.isUsedChecked);
    let _checkedSX:SxProps<Theme> = {};
    if(_isDeviceChecked) {
        _checkedSX = {
            boxShadow: `0px 2px 1px -1px ${_theme.palette.primary.main},0px -1px 1px 0px ${_theme.palette.primary.main},0px 1px 3px 0px ${_theme.palette.primary.main}`,
            border: `1px solid ${_theme.palette.primary.main}`
        };
    }

    const _displayName:string = props.customName || props.device.name;

    return (
        <div className={styles.preview}>
            {
                !!props.onClose &&
                <div className={styles.closeBtnContainer}>
                    <IconButton
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            }
            <Paper 
                className={styles.paper}
                sx={{
                    ..._checkedSX                                            
                }}
            >                
                <Box
                    className={styles.description}
                >
                    {
                        !props.onUseCheck ? (
                            <Typography gutterBottom variant="h5" component="div">
                                { _displayName }
                            </Typography>
                        ) : (
                            <Box className={styles.actions}>
                                <FormControlLabel 
                                    control={(
                                        <Switch 
                                            checked={props.isUsedChecked}
                                            onChange={(e, checked:boolean) => {
                                                props.onUseCheck?.(checked);
                                            }}
                                        />
                                    )}
                                    label={_displayName}                                
                                    sx={{
                                        color: props.isUsedChecked ? 'primary.main' : 'inherit',                                        
                                        '.MuiFormControlLabel-label': {
                                            fontWeight: '400',
                                            fontSize: '1.5rem',
                                            lineHeight: '1.334',
                                        }
                                    }}
                                />
                            </Box>
                        )
                    }
                    {
                        !!props.extraInfo &&
                        <Box
                            sx={{
                                paddingBottom: !!props.device.description ? '0.5em' : 0
                            }}
                        >
                            { props.extraInfo } 
                        </Box>
                    }
                    {
                        !!props.device.description &&
                        <Typography variant="body2" color="text.secondary"
                        >
                            { props.device.description }
                        </Typography>
                    }
                    
                    {
                        !!props.device.moreDescriptionId &&
                        <Box
                            className={styles.readMoreContainer}                            
                        >
                            {
                                _moreDescriptionHtml.value?.guid === props.device.moreDescriptionId ? (
                                    <Typography 
                                        dangerouslySetInnerHTML={{__html: _moreDescriptionHtml.value.descriptionHtml}}
                                        className={styles.expandedDescription}
                                        variant='body2'
                                        color="text.secondary"
                                        component={'div'}
                                    ></Typography>
                                ) : (
                                    <div className={styles.button}>
                                        <LoadingButton
                                            onClick={() => {
                                                _msalGetDescription.execute('POST', g_protectedResources.apiGetDescription.endpoint, {
                                                    guid: props.device.moreDescriptionId
                                                }, true).then((description:IDeviceDescription) => {
                                                    _moreDescriptionHtml.set(description);
                                                });
                                            }}
                                            endIcon={<ReadMore />}
                                            loading={_msalGetDescription.isLoading.value}
                                            loadingPosition="end"
                                            variant='text'
                                        >
                                            {_translations?.phrases.readMoreBtn}
                                        </LoadingButton>
                                    </div>
                                )
                            }
                        </Box>
                    }
                </Box>
                <Box className={styles.imageContainer}>
                    <Box
                        sx={{
                            backgroundImage: `url('${props.device.imageUrl}')`
                        }}
                        className={styles.image}
                    />
                    <Box
                        sx={{
                            backgroundImage: `url('${props.device.manufacturerLogoUrl}')`
                        }}
                        className={styles.manufacturerLogo}
                    />
                </Box>
                {
                    props.extraSection
                }
            </Paper>
        </div>
    );
}
import * as React from 'react';
import { IPageLayoutProps } from './IPageLayoutProps';
import styles from './PageLayout.module.scss';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { 
    AppBar,
    Avatar,
    Box,
    Button, 
    ButtonGroup, 
    Divider, 
    Grid, 
    IconButton, 
    ListItemIcon, 
    Menu, 
    MenuItem, 
    Stack, 
    Toolbar, 
    Typography 
} from '@mui/material';

import { 
    HOME_PAGE_URI,
    CALCULATOR_PAGE_URI
} from '../../pages/Pages';
import { 
    AuthenticatedTemplate, 
    UnauthenticatedTemplate, 
    useMsal 
} from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { ExpandButton } from '../ExpandButton/ExpandButton';
import { ITranslationsContext, TranslationsContext } from '../../translations/TranslationsContext';
import { Favorite, Person, Power, PowerSettingsNew } from '@mui/icons-material';
import { g_getActiveUser } from '../../helpers/msalHelper';
import { IActiveAccount } from '../../models/IActiveAccount';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar';
import { g_loginRequest } from '../../authConfig';
import { FooterContext } from '../../App';
import { IState } from '../../react-hooks/useState';

/**
 * Renders out the general page layout inside with the pages go
 */
export function PageLayout(props:React.PropsWithChildren<IPageLayoutProps>) {
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _msal = useMsal();
    const _footer:IState<React.ReactNode>|null = React.useContext(FooterContext);

    React.useEffect(() => {
        console.warn(`AlphaPro:beta - ${ process.env.REACT_APP_LOCATION }`);
    }, []);

    return (
        <>
            <AppBar position='sticky'>
                <Toolbar>
                    <Stack 
                        sx={{
                            flexDirection: 'row',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                alignSelf: 'center'
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1595.08 660"
                                style={{
                                    width: '160px'
                                }}
                            >
                                <title>Alphapro logo</title>

                                <g
                                    transform='scale(0.95) translate(-170, 185)'
                                >
                                <path fill={'#181A1D'} d="M751.37,899.19h33.32V1032H751.37v-14q-9.76,9.29-19.57,13.37a54.93,54.93,0,0,1-21.28,4.09q-25.72,0-44.5-20t-18.78-49.62q0-30.76,18.17-50.42t44.14-19.65A56.11,56.11,0,0,1,732,900.28a61.48,61.48,0,0,1,19.39,13.55Zm-35,27.34q-15.47,0-25.67,10.92t-10.22,28q0,17.21,10.4,28.32a33.68,33.68,0,0,0,25.61,11.11q15.69,0,26-10.93t10.34-28.62q0-17.34-10.34-28.08T716.34,926.53Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M804.72,847.92h33.33V1032H804.72Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M891.4,899.19v14.64a62.81,62.81,0,0,1,19.53-13.55,56.26,56.26,0,0,1,22.46-4.51q26,0,44.19,19.65t18.19,50.42q0,29.65-18.8,49.62t-44.55,20a55.44,55.44,0,0,1-21.36-4.09q-9.9-4.1-19.66-13.37v62.62H858.32V899.19Zm35,27.34q-15.75,0-26.13,10.74t-10.37,28.08q0,17.7,10.37,28.62t26.13,10.93a33.85,33.85,0,0,0,25.69-11.11q10.44-11.1,10.44-28.32t-10.25-28Q942.06,926.53,926.44,926.53Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M1013.48,847.92h33.08v64.69A76,76,0,0,1,1066.22,900a50.61,50.61,0,0,1,20-4.21q19.77,0,33.32,13.67,11.6,11.84,11.6,34.79V1032h-32.84V973.77q0-23.07-2.2-31.25t-7.5-12.21a21.13,21.13,0,0,0-13.13-4A24.71,24.71,0,0,0,1058.1,933q-7.26,6.72-10.07,18.31-1.47,6-1.47,27.34V1032h-33.08Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M1251.41,899.19h33.32V1032h-33.32v-14q-9.77,9.29-19.57,13.37a54.93,54.93,0,0,1-21.28,4.09q-25.72,0-44.5-20t-18.78-49.62q0-30.76,18.17-50.42t44.14-19.65a56.11,56.11,0,0,1,22.43,4.51,61.48,61.48,0,0,1,19.39,13.55Zm-35,27.34q-15.47,0-25.68,10.92t-10.22,28q0,17.21,10.4,28.32a33.72,33.72,0,0,0,25.62,11.11q15.69,0,26-10.93t10.34-28.62q0-17.34-10.34-28.08T1216.38,926.53Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M1411.83,863.62a54.87,54.87,0,0,1,21.37,20.88,60.56,60.56,0,0,1,.13,58.62,54.18,54.18,0,0,1-21.25,20.75q-13.75,7.64-31.5,7.63h-35.75V1032h-37.75V856h73.5Q1398.07,856,1411.83,863.62ZM1389,936.25a22.92,22.92,0,0,0,9.13-9.13,26.7,26.7,0,0,0,3.25-13.12,24.08,24.08,0,0,0-3.38-12.63,23.4,23.4,0,0,0-9.25-8.75,27.51,27.51,0,0,0-13.12-3.12h-30.75v50h30.75A27.09,27.09,0,0,0,1389,936.25Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M1524.33,970.75h-24.5V1032h-37.75V856h74q18,0,31.87,7.37a53.12,53.12,0,0,1,21.5,20.5,57.46,57.46,0,0,1,7.63,29.38,52.49,52.49,0,0,1-9.13,30.5,54.62,54.62,0,0,1-25.12,19.75l47.75,68.5h-45ZM1499.83,938h31.5a28.52,28.52,0,0,0,13.62-3.13,23.33,23.33,0,0,0,9.25-8.75,24.53,24.53,0,0,0,3.38-12.87,23.29,23.29,0,0,0-3.38-12.63,22.07,22.07,0,0,0-9.37-8.25,30.94,30.94,0,0,0-13.5-2.87h-31.5Z" transform="translate(-206.5 -774.48)"/>
                                <path fill={'#181A1D'} d="M1659.83,1022.87a92.73,92.73,0,0,1-34.5-32.87,89.59,89.59,0,0,1,0-92.13,91.49,91.49,0,0,1,34.5-32.87,99.26,99.26,0,0,1,94.62,0,91.75,91.75,0,0,1,34.38,32.87,89.35,89.35,0,0,1,0,92,92.62,92.62,0,0,1-34.38,33,98.33,98.33,0,0,1-94.62,0Zm74.87-30.37a53.48,53.48,0,0,0,20-20,58.72,58.72,0,0,0,0-57,53.48,53.48,0,0,0-20-20,56.26,56.26,0,0,0-55.25,0,53.62,53.62,0,0,0-20,20,58.79,58.79,0,0,0,0,57,53.62,53.62,0,0,0,20,20,56.26,56.26,0,0,0,55.25,0Z" transform="translate(-206.5 -774.48)"/>
                                {/* <path fill={'#C92128'} d="M425.22,774.48A161.33,161.33,0,0,0,271.86,885.8h58.76a107,107,0,1,1,94.6,157v54.3a161.29,161.29,0,0,0,0-322.57" transform="translate(-206.5 -774.48)"/> */}
                                {/* <path fill={'#181A1D'} d="M425.22,1042.75v-157h-94.6a107,107,0,0,0,94.6,157" transform="translate(-206.5 -774.48)"/> */}
                                {/* <path fill={'#181A1D'} d="M263.94,935.76a161.2,161.2,0,0,1,7.92-50H206.5v218.72H425.22v-7.47A161.29,161.29,0,0,1,263.94,935.76" transform="translate(-206.5 -774.48)"/> */}
                                </g>
                                <g
                                    transform='scale(4.5) translate(10, 0) rotate(10 10 10)'
                                >
	<path className={styles.st1} d="M36.22,76.47c0.91-0.17,1.72-0.68,2.29-1.43c0.56-0.74,0.8-1.64,0.7-2.52c-0.1-0.89-0.55-1.7-1.26-2.3   c-0.72-0.6-1.63-0.91-2.55-0.87c-0.98,0.04-1.89,0.49-2.51,1.24c-0.63,0.75-0.92,1.76-0.81,2.76c0.12,1,0.64,1.91,1.42,2.5   c0.6,0.45,1.33,0.69,2.07,0.69C35.78,76.53,36,76.51,36.22,76.47"/>
	<path className={styles.st1} d="M35.49,65.88c0.12,0.01,0.23,0.02,0.35,0.02c0.74,0,1.47-0.24,2.08-0.69c0.69-0.52,1.15-1.26,1.29-2.09   s-0.05-1.68-0.54-2.39C38.18,60,37.44,59.5,36.6,59.3c-0.9-0.21-1.81-0.05-2.57,0.47c-0.75,0.51-1.28,1.33-1.43,2.25   s0.07,1.87,0.61,2.6C33.75,65.32,34.58,65.78,35.49,65.88"/>
	<path className={styles.st1} d="M37.81,55.52c0.39,0.16,0.8,0.24,1.21,0.24c0.38,0,0.76-0.07,1.12-0.2c0.76-0.28,1.36-0.82,1.7-1.53   c0.34-0.7,0.39-1.51,0.15-2.28c-0.25-0.77-0.77-1.41-1.48-1.81c-0.74-0.42-1.6-0.5-2.4-0.24c-0.8,0.26-1.48,0.85-1.85,1.63   c-0.38,0.77-0.42,1.67-0.13,2.46C36.43,54.57,37.02,55.19,37.81,55.52"/>
	<path className={styles.st1} d="M46.86,42.03c-0.54-0.55-1.31-0.86-2.06-0.83c-0.78,0.02-1.52,0.37-2.05,0.95c-0.53,0.59-0.81,1.36-0.75,2.13   c0.05,0.77,0.42,1.49,1.02,1.98c0.52,0.43,1.18,0.66,1.86,0.66c0.07,0,0.13,0,0.19-0.01c0.75-0.05,1.43-0.38,1.91-0.91   c0.49-0.54,0.75-1.25,0.73-1.99C47.69,43.26,47.39,42.56,46.86,42.03L46.86,42.03"/>
	<path className={styles.st1} d="M53.52,34.91c-0.68-0.18-1.43-0.06-2.03,0.31c-0.61,0.37-1.05,0.98-1.2,1.67c-0.15,0.7-0.02,1.41,0.38,2   c0.38,0.57,0.96,0.96,1.64,1.11c0.19,0.04,0.38,0.06,0.58,0.06c0.47,0,0.94-0.13,1.35-0.38c0.57-0.35,0.98-0.91,1.15-1.58   c0.17-0.67,0.08-1.36-0.25-1.96C54.78,35.53,54.21,35.09,53.52,34.91"/>
	<path className={styles.st1} d="M63.61,31.31c-0.56-0.34-1.22-0.43-1.86-0.25c-0.64,0.17-1.17,0.59-1.48,1.16c-0.31,0.57-0.37,1.21-0.17,1.82   c0.33,1.03,1.26,1.69,2.28,1.69c0.2,0,0.41-0.03,0.62-0.08c1.23-0.34,1.97-1.58,1.69-2.84C64.55,32.18,64.16,31.63,63.61,31.31"/>
	<path className={styles.st1} d="M72.71,29.95c-0.59-0.01-1.14,0.21-1.56,0.62c-0.41,0.41-0.63,0.95-0.62,1.52c0.02,1.17,0.94,2.1,2.09,2.12   h0.04c1.13,0,2.07-0.89,2.13-2.04c0.03-0.57-0.16-1.12-0.56-1.55C73.85,30.2,73.3,29.96,72.71,29.95"/>
	<path className={styles.st1} d="M83.47,31.94c-0.49-0.15-1.02-0.11-1.47,0.14c-0.45,0.24-0.77,0.65-0.91,1.14c-0.28,1,0.27,2.02,1.25,2.33   c0.18,0.06,0.37,0.08,0.55,0.08c0.79,0,1.53-0.5,1.8-1.29c0.17-0.48,0.13-1-0.1-1.45C84.37,32.44,83.97,32.1,83.47,31.94"/>
	<path className={styles.st1} d="M41.86,84.24c0.34-0.88,0.33-1.83-0.02-2.67c-0.35-0.84-1.02-1.51-1.89-1.9c-0.88-0.38-1.85-0.43-2.74-0.13   c-0.95,0.32-1.71,1.02-2.11,1.92c-0.4,0.91-0.41,1.98-0.01,2.93s1.16,1.69,2.09,2.05c0.4,0.15,0.82,0.23,1.24,0.23   c0.55,0,1.1-0.13,1.6-0.38C40.87,85.86,41.52,85.14,41.86,84.24"/>
	<path className={styles.st1} d="M44.37,88.77c-0.88-0.11-1.77,0.1-2.49,0.61c-0.76,0.53-1.26,1.36-1.38,2.27c-0.12,0.92,0.15,1.87,0.75,2.61   s1.47,1.21,2.39,1.29c0.09,0.01,0.19,0.01,0.28,0.01c0.82,0,1.61-0.31,2.22-0.88c0.65-0.6,1.05-1.42,1.12-2.3   c0.07-0.88-0.18-1.73-0.72-2.4C46.03,89.31,45.25,88.89,44.37,88.77"/>
	<path className={styles.st1} d="M53.5,96.78c-0.66-0.46-1.47-0.64-2.27-0.51c-0.82,0.13-1.55,0.56-2.05,1.2c-0.54,0.68-0.76,1.53-0.62,2.38   c0.13,0.85,0.62,1.62,1.34,2.12c0.55,0.38,1.2,0.58,1.84,0.58c0.2,0,0.41-0.02,0.61-0.06c0.83-0.17,1.57-0.68,2.01-1.41   c0.43-0.7,0.57-1.53,0.41-2.35C54.61,97.92,54.16,97.23,53.5,96.78"/>
	<path className={styles.st1} d="M62.13,101.37c-0.7-0.23-1.46-0.18-2.16,0.15c-0.69,0.33-1.22,0.9-1.49,1.6c-0.29,0.74-0.26,1.56,0.08,2.26   c0.34,0.71,0.97,1.26,1.73,1.52c0.31,0.1,0.63,0.15,0.95,0.15c0.47,0,0.93-0.11,1.35-0.33c0.7-0.37,1.21-0.99,1.42-1.76l0,0   c0.2-0.73,0.12-1.5-0.24-2.18C63.41,102.1,62.83,101.6,62.13,101.37"/>
	<path className={styles.st1} d="M71.78,103.37c-0.67-0.02-1.34,0.22-1.86,0.68c-0.52,0.47-0.84,1.1-0.9,1.79c-0.06,0.72,0.17,1.42,0.65,1.96   s1.17,0.86,1.9,0.89c0.04,0,0.07,0,0.1,0c0.69,0,1.36-0.27,1.85-0.74c0.52-0.5,0.81-1.18,0.81-1.91c0-0.69-0.27-1.35-0.75-1.86   C73.11,103.69,72.47,103.4,71.78,103.37"/>
	<path className={styles.st1} d="M81.67,102.59c-1.25,0.32-2.03,1.57-1.76,2.86c0.13,0.64,0.51,1.18,1.06,1.52c0.39,0.24,0.83,0.37,1.28,0.37   c0.2,0,0.41-0.03,0.61-0.08c0.65-0.16,1.19-0.57,1.52-1.15c0.32-0.56,0.4-1.23,0.21-1.85C84.2,103.01,82.93,102.27,81.67,102.59"/>
	<path className={styles.st1} d="M93.94,99.74c-0.64-1-1.92-1.31-2.93-0.72s-1.36,1.87-0.79,2.91c0.28,0.51,0.74,0.88,1.3,1.04   c0.19,0.05,0.39,0.08,0.58,0.08c0.38,0,0.76-0.1,1.1-0.3c0.52-0.3,0.88-0.79,1.01-1.36C94.35,100.81,94.25,100.23,93.94,99.74   L93.94,99.74z"/>
	<path className={styles.st1} d="M101.7,92.82c-0.77-0.68-1.97-0.64-2.67,0.11c-0.72,0.76-0.69,1.93,0.05,2.68c0.36,0.36,0.83,0.55,1.34,0.55   h0.03c0.52-0.01,1.01-0.22,1.37-0.61c0.36-0.38,0.54-0.88,0.52-1.4C102.31,93.64,102.08,93.16,101.7,92.82"/>
	<path className={styles.st2} d="M23.79,78.85c1.15-0.22,2.17-0.86,2.88-1.81c0.7-0.94,1.01-2.06,0.88-3.17c-0.13-1.11-0.69-2.14-1.59-2.89   c-0.91-0.76-2.05-1.14-3.22-1.09l0,0c-1.24,0.06-2.36,0.61-3.15,1.55c-0.79,0.95-1.16,2.21-1.01,3.48c0.15,1.26,0.8,2.41,1.78,3.15   c0.76,0.57,1.65,0.86,2.58,0.86C23.23,78.94,23.51,78.91,23.79,78.85"/>
	<path className={styles.st2} d="M22.9,64.49c0.15,0.01,0.29,0.02,0.44,0.02c0.93,0,1.84-0.3,2.61-0.87c0.87-0.65,1.44-1.57,1.62-2.61   c0.17-1.03-0.07-2.1-0.68-2.99c-0.62-0.91-1.54-1.55-2.6-1.8c-1.12-0.27-2.26-0.06-3.2,0.58c-0.94,0.64-1.6,1.67-1.79,2.82   c-0.2,1.16,0.08,2.34,0.77,3.25C20.75,63.81,21.75,64.38,22.9,64.49"/>
	<path className={styles.st2} d="M26.15,50.47c0.48,0.2,1,0.3,1.51,0.3c0.47,0,0.94-0.08,1.4-0.25c0.94-0.35,1.69-1.02,2.12-1.9   c0.42-0.87,0.49-1.88,0.18-2.84s-0.97-1.76-1.84-2.25c-0.92-0.52-1.98-0.62-2.98-0.3s-1.84,1.06-2.3,2.02   c-0.47,0.97-0.53,2.08-0.16,3.06C24.43,49.31,25.17,50.07,26.15,50.47"/>
	<path className={styles.st2} d="M38.04,32.77c-0.68-0.69-1.57-1.05-2.54-1.02c-0.95,0.03-1.88,0.46-2.53,1.18c-0.66,0.72-0.99,1.68-0.93,2.63   c0.06,0.96,0.51,1.82,1.26,2.43c0.65,0.53,1.46,0.82,2.3,0.82c0.08,0,0.16,0,0.24-0.01c0.92-0.06,1.75-0.46,2.36-1.12   c0.6-0.66,0.92-1.54,0.89-2.45C39.06,34.29,38.68,33.42,38.04,32.77L38.04,32.77z"/>
	<path className={styles.st2} d="M49.18,24.73c-0.42-0.76-1.12-1.3-1.96-1.52c-0.83-0.21-1.74-0.07-2.49,0.39s-1.28,1.2-1.47,2.04   c-0.19,0.85-0.02,1.71,0.47,2.44c0.47,0.69,1.18,1.17,2,1.36c0.24,0.05,0.47,0.08,0.71,0.08c0.58,0,1.14-0.16,1.64-0.46   c0.7-0.43,1.2-1.11,1.4-1.92C49.69,26.32,49.59,25.46,49.18,24.73L49.18,24.73z"/>
	<path className={styles.st2} d="M60.81,18.28c-0.68-0.41-1.48-0.52-2.25-0.31c-0.77,0.21-1.41,0.71-1.79,1.4c-0.37,0.68-0.45,1.46-0.21,2.2   c0.4,1.24,1.53,2.04,2.76,2.04c0.25,0,0.49-0.03,0.74-0.1c1.48-0.41,2.38-1.92,2.04-3.44C61.93,19.32,61.48,18.69,60.81,18.28"/>
	<path className={styles.st2} d="M73.32,16.49c-0.69-0.01-1.36,0.25-1.86,0.74c-0.49,0.48-0.75,1.13-0.73,1.81c0.02,1.39,1.12,2.5,2.49,2.53   c0.02,0,0.03,0,0.05,0c1.35,0,2.46-1.06,2.54-2.43l0,0c0.04-0.68-0.2-1.33-0.66-1.84C74.67,16.8,74.02,16.51,73.32,16.49"/>
	<path className={styles.st2} d="M87.81,19.18c-0.58-0.18-1.19-0.12-1.72,0.16c-0.52,0.28-0.9,0.75-1.06,1.33c-0.33,1.17,0.31,2.37,1.46,2.73   c0.22,0.07,0.44,0.1,0.66,0.1c0.92,0,1.77-0.58,2.09-1.51c0.19-0.56,0.15-1.16-0.12-1.69C88.86,19.76,88.39,19.36,87.81,19.18"/>
	<path className={styles.st2} d="M30.88,89.64c0.42-1.11,0.41-2.31-0.03-3.36s-1.28-1.9-2.38-2.38c-1.11-0.49-2.34-0.54-3.47-0.17   c-1.2,0.4-2.14,1.26-2.65,2.41s-0.51,2.5-0.01,3.69c0.5,1.2,1.46,2.14,2.64,2.59c0.51,0.2,1.04,0.29,1.57,0.29   c0.68,0,1.37-0.16,2.01-0.49C29.62,91.69,30.44,90.77,30.88,89.64"/>
	<path className={styles.st2} d="M34.56,96.25c-1.11-0.14-2.22,0.13-3.13,0.77c-0.96,0.67-1.57,1.68-1.72,2.83s0.19,2.35,0.94,3.27   c0.75,0.93,1.84,1.52,3,1.61c0.12,0.01,0.23,0.01,0.35,0.01c1.03,0,2.01-0.38,2.78-1.1c0.82-0.75,1.31-1.78,1.41-2.9   c0.09-1.1-0.23-2.17-0.9-3C36.64,96.93,35.66,96.4,34.56,96.25"/>
	<path className={styles.st2} d="M46.78,107.04c-0.81-0.56-1.82-0.79-2.83-0.63c-1.02,0.16-1.93,0.69-2.56,1.5c-0.67,0.85-0.94,1.9-0.77,2.95   c0.16,1.05,0.77,2.02,1.67,2.64c0.68,0.48,1.49,0.73,2.3,0.73c0.25,0,0.51-0.03,0.76-0.08c1.05-0.21,1.93-0.83,2.5-1.76   c0.53-0.87,0.71-1.91,0.51-2.92C48.15,108.47,47.59,107.61,46.78,107.04"/>
	<path className={styles.st2} d="M58.57,113.31c-0.87-0.29-1.81-0.22-2.66,0.18c-0.85,0.41-1.51,1.11-1.85,1.98c-0.36,0.92-0.33,1.91,0.1,2.78   c0.42,0.88,1.2,1.56,2.14,1.87c0.38,0.13,0.78,0.19,1.17,0.19c0.58,0,1.16-0.14,1.67-0.41c0.87-0.45,1.49-1.22,1.75-2.17   c0.25-0.9,0.14-1.86-0.29-2.7C60.15,114.22,59.43,113.6,58.57,113.31"/>
	<path className={styles.st2} d="M71.75,116.05c-0.86-0.04-1.64,0.26-2.28,0.83s-1.03,1.36-1.1,2.2c-0.07,0.89,0.21,1.74,0.8,2.39   c0.58,0.65,1.43,1.05,2.32,1.08c0.04,0,0.09,0,0.13,0c0.85,0,1.67-0.32,2.27-0.9c0.64-0.61,0.99-1.43,0.98-2.32l0,0   c-0.01-0.85-0.34-1.66-0.93-2.28C73.36,116.44,72.58,116.08,71.75,116.05"/>
	<path className={styles.st2} d="M88.77,117.01c-0.46-1.52-2.01-2.42-3.52-2.03c-1.51,0.39-2.45,1.91-2.13,3.46c0.16,0.77,0.61,1.42,1.28,1.84   c0.47,0.29,1,0.45,1.54,0.45c0.25,0,0.49-0.03,0.74-0.09c0.79-0.2,1.44-0.69,1.84-1.39C88.91,118.55,89,117.76,88.77,117.01   L88.77,117.01z"/>
	<path className={styles.st2} d="M101.49,110.95c-0.76-1.19-2.3-1.57-3.5-0.86s-1.61,2.24-0.95,3.48c0.33,0.61,0.88,1.05,1.55,1.24   c0.23,0.06,0.46,0.1,0.69,0.1c0.46,0,0.91-0.12,1.32-0.36c0.62-0.36,1.05-0.94,1.21-1.63   C101.98,112.24,101.87,111.54,101.49,110.95L101.49,110.95z"/>
	<path className={styles.st2} d="M112.08,101.66c-0.92-0.82-2.3-0.76-3.14,0.13s-0.81,2.27,0.06,3.14c0.42,0.42,0.97,0.65,1.56,0.65   c0.01,0,0.02,0,0.04,0c0.61-0.01,1.18-0.26,1.6-0.71s0.64-1.03,0.61-1.64C112.79,102.61,112.53,102.06,112.08,101.66"/>
	<path className={styles.st0} d="M9.49,81.59c1.42-0.27,2.68-1.06,3.57-2.24c0.86-1.15,1.25-2.54,1.09-3.91s-0.86-2.63-1.97-3.55   c-1.13-0.94-2.56-1.42-3.98-1.35c-1.53,0.07-2.91,0.75-3.87,1.91c-0.97,1.17-1.43,2.73-1.25,4.29c0.18,1.56,0.99,2.98,2.2,3.89   c0.93,0.7,2.03,1.06,3.17,1.06C8.8,81.69,9.14,81.66,9.49,81.59"/>
	<path className={styles.st0} d="M8.42,62.94c0.18,0.02,0.36,0.03,0.54,0.03c1.15,0,2.27-0.37,3.21-1.07c1.07-0.8,1.77-1.93,1.99-3.2   c0.21-1.27-0.08-2.57-0.83-3.68c-0.76-1.12-1.9-1.91-3.2-2.21c-1.37-0.32-2.77-0.07-3.92,0.71c-1.16,0.78-1.96,2.05-2.2,3.47   c-0.24,1.42,0.1,2.88,0.94,4C5.78,62.1,7.02,62.79,8.42,62.94"/>
	<path className={styles.st0} d="M12.72,44.75c0.59,0.24,1.22,0.37,1.85,0.37c0.58,0,1.16-0.1,1.71-0.31c1.15-0.43,2.07-1.25,2.58-2.32   c0.52-1.07,0.59-2.3,0.22-3.47c-0.39-1.18-1.19-2.16-2.26-2.76c-1.12-0.63-2.41-0.76-3.63-0.37s-2.25,1.3-2.82,2.48   s-0.65,2.55-0.2,3.74C10.63,43.32,11.53,44.25,12.72,44.75"/>
	<path className={styles.st0} d="M27.84,22.23c-0.82-0.83-1.92-1.29-3.08-1.24c-1.16,0.03-2.28,0.55-3.08,1.43c-0.8,0.88-1.21,2.04-1.13,3.2   c0.08,1.16,0.62,2.21,1.53,2.95c0.79,0.65,1.77,0.99,2.8,0.99c0.1,0,0.2,0,0.29-0.01c1.11-0.07,2.13-0.56,2.86-1.36   c0.73-0.81,1.11-1.86,1.08-2.98C29.08,24.08,28.63,23.02,27.84,22.23L27.84,22.23z"/>
	<path className={styles.st0} d="M42.27,11.72c-0.51-0.92-1.35-1.57-2.36-1.83c-1.01-0.26-2.11-0.09-3,0.47c-0.9,0.55-1.55,1.45-1.77,2.47   c-0.23,1.02-0.03,2.06,0.56,2.93c0.57,0.83,1.43,1.42,2.42,1.64c0.28,0.06,0.57,0.09,0.85,0.09c0.7,0,1.38-0.19,1.98-0.56   c0.84-0.52,1.44-1.34,1.69-2.32C42.89,13.64,42.76,12.61,42.27,11.72L42.27,11.72z"/>
	<path className={styles.st0} d="M57.46,3.45c-0.79-0.47-1.77-0.6-2.67-0.35c-0.9,0.25-1.68,0.85-2.12,1.65c-0.45,0.81-0.55,1.75-0.27,2.64   c0.27,0.86,0.85,1.57,1.64,2.01c0.52,0.29,1.09,0.44,1.67,0.44c0.29,0,0.59-0.04,0.88-0.12c0.85-0.23,1.57-0.78,2.04-1.56   c0.46-0.78,0.6-1.69,0.4-2.57C58.81,4.68,58.26,3.92,57.46,3.45"/>
	<path className={styles.st0} d="M73.88,1.18c-0.85-0.01-1.61,0.29-2.2,0.88c-0.57,0.57-0.88,1.33-0.87,2.14c0.03,1.65,1.32,2.96,2.94,3   c0.02,0,0.04,0,0.06,0c1.6,0,2.91-1.25,3-2.88c0.05-0.81-0.23-1.57-0.78-2.17C75.47,1.53,74.71,1.19,73.88,1.18"/>
	<path className={styles.st0} d="M92.61,4.66c-0.67-0.21-1.39-0.14-2.01,0.19c-0.6,0.33-1.04,0.88-1.23,1.54c-0.38,1.36,0.37,2.76,1.7,3.18   c0.26,0.08,0.52,0.12,0.77,0.12c1.08,0,2.07-0.68,2.44-1.76c0.23-0.65,0.18-1.35-0.14-1.97C93.83,5.33,93.29,4.87,92.61,4.66"/>
	<path className={styles.st0} d="M18.13,91.67c-0.54-1.3-1.58-2.34-2.93-2.94c-1.37-0.6-2.9-0.67-4.29-0.21c-1.48,0.5-2.65,1.55-3.27,2.96   c-0.63,1.42-0.63,3.08-0.01,4.57c0.62,1.48,1.81,2.65,3.26,3.2c0.63,0.24,1.28,0.36,1.93,0.36c0.84,0,1.69-0.2,2.48-0.6   c1.31-0.66,2.33-1.8,2.87-3.2C18.68,94.44,18.67,92.97,18.13,91.67"/>
	<path className={styles.st0} d="M23.19,104.8c-1.37-0.18-2.74,0.16-3.85,0.94c-1.18,0.83-1.93,2.06-2.11,3.48c-0.19,1.42,0.24,2.89,1.16,4.03   c0.92,1.15,2.27,1.87,3.69,1.99c0.14,0.01,0.29,0.02,0.43,0.02c1.26,0,2.46-0.47,3.41-1.35c1-0.92,1.62-2.19,1.74-3.57   c0.11-1.35-0.28-2.66-1.1-3.68C25.73,105.64,24.54,104.98,23.19,104.8"/>
	<path className={styles.st0} d="M38.93,118.77c-0.99-0.69-2.22-0.96-3.46-0.77c-1.25,0.2-2.37,0.85-3.14,1.84c-0.82,1.04-1.15,2.32-0.95,3.6   c0.2,1.29,0.95,2.47,2.05,3.23c0.84,0.58,1.83,0.89,2.81,0.89c0.31,0,0.62-0.03,0.93-0.09c1.28-0.26,2.36-1.02,3.05-2.14   c0.65-1.07,0.87-2.34,0.63-3.59C40.6,120.51,39.92,119.46,38.93,118.77"/>
	<path className={styles.st0} d="M54.31,126.95c-1.05-0.35-2.2-0.27-3.23,0.22c-1.04,0.5-1.84,1.36-2.25,2.42c-0.44,1.11-0.4,2.32,0.12,3.38   c0.51,1.07,1.46,1.9,2.61,2.28c0.46,0.15,0.95,0.23,1.42,0.23c0.71,0,1.41-0.17,2.03-0.49c1.05-0.55,1.8-1.49,2.12-2.64l0,0   c0.3-1.1,0.17-2.27-0.36-3.29C56.23,128.05,55.36,127.3,54.31,126.95"/>
	<path className={styles.st0} d="M71.51,130.52c-1.01-0.04-1.98,0.32-2.75,1c-0.77,0.69-1.25,1.64-1.33,2.66c-0.09,1.07,0.25,2.1,0.96,2.88   c0.71,0.79,1.73,1.27,2.81,1.31c0.05,0,0.1,0,0.16,0c1.02,0,2.02-0.39,2.74-1.09c0.77-0.73,1.19-1.73,1.18-2.8   c-0.01-1.03-0.4-2.01-1.12-2.76C73.46,130.99,72.52,130.56,71.51,130.52"/>
	<path className={styles.st0} d="M91.71,129.48c-0.8-0.46-1.71-0.59-2.58-0.37s-1.62,0.77-2.1,1.55c-0.48,0.79-0.64,1.71-0.45,2.61   c0.2,0.93,0.75,1.72,1.55,2.21c0.55,0.34,1.19,0.52,1.84,0.52c0.29,0,0.59-0.04,0.87-0.11c0.93-0.23,1.72-0.83,2.19-1.65   c0.47-0.82,0.59-1.77,0.32-2.68C93.1,130.68,92.52,129.94,91.71,129.48"/>
	<path className={styles.st0} d="M105.76,122.74c-1.42,0.84-1.91,2.65-1.12,4.12c0.39,0.72,1.04,1.24,1.84,1.46c0.27,0.08,0.55,0.11,0.82,0.11   c0.54,0,1.08-0.15,1.56-0.43c0.73-0.43,1.24-1.11,1.43-1.93c0.19-0.8,0.05-1.62-0.39-2.31   C109.01,122.35,107.18,121.9,105.76,122.74"/>
	<path className={styles.st0} d="M123.71,111.75L123.71,111.75c-1.08-0.96-2.69-0.89-3.67,0.14c-0.96,1.02-0.93,2.67,0.08,3.67   c0.49,0.49,1.13,0.75,1.82,0.75h0.04c0.71-0.01,1.38-0.31,1.87-0.83c0.49-0.52,0.75-1.21,0.72-1.92   C124.54,112.86,124.24,112.22,123.71,111.75"/>
</g>
                            </svg>
                        </Box>                   
                        <UnauthenticatedTemplate>
                            <Box                      
                                className={styles.loginContainer} 
                                sx={{
                                    alignSelf: {
                                        xs: 'flex-end',
                                        sm: 'center',
                                        textAlign: 'right'
                                    }
                                }}  
                            >
                                <Button
                                    size='large'
                                    variant='contained'                            
                                    onClick={() => {
                                        _msal.instance.loginRedirect(g_loginRequest).catch((error) => console.log(error));                            
                                    }}
                                    className={styles.logoinBtn}
                                    endIcon={<PowerSettingsNewIcon />}
                                >
                                    {_translations?.phrases.logInButton}
                                </Button>
                            </Box>               
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>                    
                            <ProfileAvatar
                                className={styles.profile}
                                sx={{
                                    alignSelf: 'center'
                                }}
                            />
                        </AuthenticatedTemplate>
                    </Stack>
                </Toolbar>
            </AppBar>             
            <Box className={styles.appContent}>
                { props.children }
            </Box>

            <Box className={styles.footer}>
                <Box className={styles.buttonsContainerInFooter}>
                        { _footer?.value }                 
                </Box>                
                <Box
                    className={styles.svgContainer}
                >
                    <Box
                        className={styles.text}
                    >
                        <Typography 
                            variant={'body2'}
                            sx={{
                                fontSize: '0.75em'
                            }}
                        >
                            Made with <Favorite color={'primary'} className={styles.luv} sx={{ fontStyle: '0.75em' }}/> by Alpha Academy
                        </Typography>
                    </Box>
                </Box>
            </Box>            
        </>
    )
}
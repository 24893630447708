/**
 * The query string name for the hidden ID of the pumps
 */
const HIDDEN_ID_PARAM_NAME:string = "hiddenid";
/**
 * Returns the "hiddenId" param from the query string or NULL if not found
 */
export function g_getHiddenId():string|null {
    let ret:string|null = null;

    const params:URLSearchParams = new URLSearchParams(window.location.search.toLocaleLowerCase());
    if(params.has(HIDDEN_ID_PARAM_NAME)) {
        ret = params.get(HIDDEN_ID_PARAM_NAME);
    }

    return ret;
}
import React from 'react';
import styles from './App.module.scss';

import { Pages } from './pages/Pages';

import { PageLayout } from './components/PageLayout/PageLayout';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Box, Paper, Stack, Theme, ThemeProvider, Typography } from '@mui/material';

import { Translations } from './translations/Translations';
import { MuiThemeContext, g_defaultAppTheme } from './helpers/muiHelper';
import { IState, useState } from './react-hooks/useState';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { SnackbarProvider } from 'notistack';

export interface IAppProps {
    instance: PublicClientApplication
}
/**
 * The context for the custom footer of the app
 */
export const FooterContext:React.Context<IState<React.ReactNode>|null> = React.createContext<IState<React.ReactNode>|null>(null);

function App(props:IAppProps) {
    /**
     * Holds the theme of the app that is given to the Mui Theme Context so it can be updated
     */
    const _appTheme:IState<Theme> = useState(g_defaultAppTheme);
    /**
     * The extra footer on the app
     */
    const _footer:IState<React.ReactNode> = useState<React.ReactNode>(null);
    
    return (
        <MsalProvider instance={props.instance}>
            <MuiThemeContext.Provider value={_appTheme}>            
                <SnackbarProvider maxSnack={5} />
                <Translations>
                    <FooterContext.Provider value={_footer}>
                        <ThemeProvider theme={_appTheme.value}>
                            <Stack 
                                className={styles.container}
                            >
                                <Paper 
                                    className={styles.app}
                                    sx={{
                                        margin: '0px'
                                    }}
                                >                                
                                    <PageLayout>
                                        <Pages />
                                    </PageLayout>                                
                                </Paper>                            
                            </Stack>
                        </ThemeProvider>
                    </FooterContext.Provider>
                </Translations>
            </MuiThemeContext.Provider>
        </MsalProvider>
    );
}

export default App;

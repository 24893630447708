import * as React from 'react';
import styles from './StepperForm.module.scss';
import { IStepperFormProps } from './IStepperFormProps';

import { GeneralInfo } from '../GeneralInfo/GeneralInfo';
import { HeatingCircuts } from '../HeatingCircuts/HeatingCircuts';
import { 
    Box,
    Button,
    Card,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Stack,
    Step, 
    StepButton, 
    StepContent, 
    StepIconProps, 
    StepLabel, 
    Stepper, 
    Tooltip, 
    Typography
} from '@mui/material';
import { 
    FormContext,
    ISection,
    SectionKeys,
    useForm,
    IUseFormHookResult,
    IResultValues,
    IHeatingCircutsValues,
    ICoolingCircutsValues,
    IHeatpumpValuesForForm,
    IGeneralInfoValues
} from '../FormContext';
import { AcUnit, Build, ChevronRight, ExpandLess, ExpandMore, Home, KeyboardDoubleArrowDown, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, KeyboardDoubleArrowUp, NavigateNext } from '@mui/icons-material';
import NavigateBack from '@mui/icons-material/NavigateBefore';
import { CoolingCircuts } from '../CoolingCircuts/CoolingCircuts';
import { HeatpumpSelection } from '../HeatpumpSelection/HeatpumpSelection';
import { TanksSelection } from '../TanksSelection/TanksSelection';
import { ExpandButton } from '../../ExpandButton/ExpandButton';
import { TransitionProps } from '@mui/material/transitions';
import { TranslationsContext, ITranslationsContext } from '../../../translations/TranslationsContext';
import { GeneralInfoPreview } from '../GeneralInfoPreview/GeneralInfoPreview';
import { HeatingCircutsPreview } from '../HeatingCircutsPreview/HeatingCircutsPreview';
import { CoolingCircutsPreview } from '../CoolingCircutsPreview/CoolingCircutsPreview';
import { HeatingPumpPreview } from '../HeatingPumpPreview/HeatingPumpPreview';
import { TankSelectionPreview } from '../TankSelectionPreview/TankSelectionPreview';
import { Result } from '../Result/Result';
import { g_errorToast } from '../../../helpers/toastHelper';
import { IState, useState } from '../../../react-hooks/useState';
import { DraggableDialog } from '../../DraggableDialog/DraggableDialog';
import { StepperIcons } from '../StepperIcons/StepperIcons';
import { FooterContext } from '../../../App';
import { CoolingType } from '../IProjectData';

/**
 * Renders out the stepps for the form i.e. the main navigation of the form
 */
export function StepperForm(props:IStepperFormProps) {

    const _form:IUseFormHookResult = useForm();
    const _sections:ISection[] = _form.data.getSections();
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    //sets the custom footer on the page
    const _footer:IState<React.ReactNode>|null = React.useContext(FooterContext);
    React.useEffect(() => {
        _footer?.set(
            <Grid 
                container 
                className={styles.navigationButtons}                 
            >  
                <Grid item xs={6} className={styles.previous}>  
                    {
                        _activeStepIndex > 0 &&
                        <ExpandButton 
                            label={_translations?.phrases.previousBtnLabel}
                            icon={NavigateBack}
                            onClick={() => {
                                _form.previous();
                            }}
                        />
                    }                            
                </Grid>
                <Grid item className={styles.next} xs={6}>
                    {
                        _form.data.areAllSectionsFilled &&
                        <ExpandButton 
                            label={
                                _form.data.result.values.projectGuid ? (
                                    _translations?.phrases.saveChangesBtnLabel
                                ) : (
                                    _translations?.phrases.generateBtnLabel
                                )
                            }
                            icon={Build}
                            onClick={() => {
                                const newData:Partial<IResultValues> = {
                                    generationResult: null,
                                    isEnabled: true
                                };

                                _form.setResult(newData);
                                _form.setSection(SectionKeys.result);
                            }}
                            color='success'
                            isDisabled={
                                (
                                    !_form.data.result.values.generationResult
                                    && _form.data.result.values.isEnabled
                                )
                                || !_form.data.hasChanges
                            }
                        />
                    }
                    {
                        _activeStepIndex < (_sections.length - 1) &&
                        <ExpandButton 
                            label={_translations?.phrases.nextBtnLabel}
                            icon={NavigateNext}
                            onClick={() => {
                                _form.next();
                            }}
                            isDisabled={!_activeSection.areRequiredFieldsFilled()}
                            onDisabledClick={() => {
                                const isInError:boolean = _form.data.isActiveSectionInErrorCheck();  
                                const generalInfo:IGeneralInfoValues = _form.data.generalInfo.values;                              
                                if(isInError) {
                                    switch(_form.data.activeSection) {
                                        case SectionKeys.heatingCircuts:
                                            const heatingCircuts:IHeatingCircutsValues = _form.data.heatingCircuts.values;
                                            if(
                                                !heatingCircuts.floor.isEnabled
                                                && !heatingCircuts.radiator.isEnabled
                                                && !heatingCircuts.ventilation.isEnabled
                                                && !heatingCircuts.pool.isEnabled
                                                && !heatingCircuts.extraWithoutValve.isEnabled
                                                && !heatingCircuts.extra.isEnabled
                                            ) {
                                                g_errorToast(_translations?.phrases.noHeatingCircutSelectedErrorMessage);
                                            }
                                            break;
                                        case SectionKeys.coolingCircuts:
                                            const coolingCircuts:ICoolingCircutsValues = _form.data.coolingCircuts.values;
                                            switch(generalInfo.coolingType) {
                                                case CoolingType.Wet: {
                                                    if(
                                                        !coolingCircuts.coolingInnerParts.isEnabled
                                                        && !coolingCircuts.ventilationCoolingBatteries.isEnabled
                                                    ) {
                                                        g_errorToast(_translations?.phrases.noCoolingCircutsSelectedErrrorMessage);
                                                    }
                                                    break;
                                                }
                                                case CoolingType.Dry: {
                                                    if(
                                                        !coolingCircuts.dryCoolingFloor.isEnabled
                                                        && !coolingCircuts.dryCoolingVentilation.isEnabled
                                                        && !coolingCircuts.dryCoolingExtra.isEnabled
                                                    ) {
                                                        g_errorToast(_translations?.phrases.noCoolingCircutsSelectedErrrorMessage);
                                                    }
                                                }
                                            }                                            
                                            break;
                                        case SectionKeys.heatpump:
                                            const heatpump:IHeatpumpValuesForForm = _form.data.heatpump.values;
                                            if(!heatpump.type) {
                                                g_errorToast(_translations?.phrases.noHeatpumpSelectedErrorMessage);
                                            }
                                            break;
                                    }
                                }

                                _form.reRender();
                            }}
                        />
                    }
                </Grid>
            </Grid>
        );
    }, [_form.data]);
    
    
    //gets the current section contents
    let _sectionContents:React.ReactNode = null;
    switch(_form.data.activeSection) {
        case SectionKeys.generalInfo:
            _sectionContents = <GeneralInfo />;
            break;
        case SectionKeys.heatingCircuts:
            _sectionContents = <HeatingCircuts />;
            break;
        case SectionKeys.coolingCircuts:
            _sectionContents = <CoolingCircuts />;
            break;
        case SectionKeys.heatpump:
            _sectionContents = <HeatpumpSelection />;
            break;
        case SectionKeys.tanks:
            _sectionContents = <TanksSelection />;
            break;
        case SectionKeys.result:
            _sectionContents = <Result />;
            break;
    }

    const _activeStepIndex:number = _sections.findIndex((s:ISection) => s.key === _form.data.activeSection);
    const _activeSection:ISection = _sections[_activeStepIndex]; 
    /**
     * If TRUE shows that the unsaved changes warning needs to be shown
     */
    const _isUnsavedChangesWarningOpen:IState<boolean> = useState(false) ;
    const _isCollapsed:IState<boolean> = useState(false);


    const _handleStepperCollapseClick = () => {
        _isCollapsed.set(!_isCollapsed.value);
    };

    return (
        <>  
            <Stack
                spacing={2}
                sx={{
                    marginTop: '0em',
                    paddingTop: 0,
                    marginBottom: '170px'
                }}
                direction={"row"}
            >
                <Box>
                    <Card
                        sx={{
                            borderRadius: '2em',
                            overflow: 'visible',
                            padding: '0',
                            background: 'linear-gradient(360deg, rgba(255,255,255,0.35) -10%, rgba(255,255,255,0.95) 90%)'
                        }}
                        onClick={_handleStepperCollapseClick}
                    >   
                        <Stack
                            alignItems={'center'}
                            sx={{
                                position: 'relative',
                                top: '0',
                                left: '0'
                            }
                            }
                        >
                            <Box
                                sx={_isCollapsed.value ? {
                                    position: 'relative',
                                    textAlign: 'right', 
                                    margin: '0em 0em 3.3em 0em',
                                    transition: '0.3s all',                                   
                                } : {
                                    position: 'relative',
                                    textAlign: 'right',
                                    margin: '1em 1em 1em 0em',
                                    transition: '0.3s all',
                                    alignSelf: "flex-end"                                 
                                }}
                            >
                                {
                                    !!props.onHomeClick &&
                                    <ExpandButton 
                                        label={_translations?.phrases.homeBtn}
                                        icon={Home}
                                        onClick={() => {
                                            if(_form.data.hasChanges) {
                                                _isUnsavedChangesWarningOpen.set(true);
                                            } else {
                                                props.onHomeClick?.();
                                            }
                                        }}
                                        isFloating={_isCollapsed.value}
                                        style={{
                                            margin: '0',
                                            left: '1px'
                                        }}
                                    />
                                }
                            </Box>
                            <Box
                                sx={_isCollapsed.value ? {
                                    position: 'absolute',
                                    textAlign: 'right', 
                                    margin: '0em 0em 1em 0em',
                                    transition: '0.3s all',
                                    left: '0em',
                                    top: '3.3em',                                 
                                } : {
                                    position: 'absolute',
                                    textAlign: 'right',
                                    margin: '0em 1em 1em 0em',
                                    transition: '0.3s all',
                                    left: '0.8em',
                                    top: '1em',                                 
                                }}
                            >
                                <ExpandButton 
                                    label={_isCollapsed.value ? _translations?.phrases.menuBarExpand : _translations?.phrases.menuBarCollapse}
                                    icon={
                                        _isCollapsed.value ? (                                        
                                            KeyboardDoubleArrowRight
                                        ) : (
                                            KeyboardDoubleArrowLeft
                                        )
                                    }
                                    onClick={_handleStepperCollapseClick}
                                    isFloating={_isCollapsed.value}
                                    style={{
                                        margin: '0',
                                        left: '1px'
                                    }}
                                />
                            </Box>                                
                            <Box
                                sx={{
                                    margin: _isCollapsed.value ? '0 0.5em' : '0em 1em',
                                    paddingBottom: '1em'
                                }}
                            >
                                <Collapse 
                                    collapsedSize={25}
                                    in={!_isCollapsed.value}
                                    orientation='horizontal'
                                >
                                    <Box
                                        sx={{
                                            minWidth: 300
                                        }}
                                    >
                                        <Stepper 
                                            activeStep={_activeStepIndex}
                                            orientation='vertical'
                                            
                                        >
                                            {
                                                _sections.map((section:ISection) => {
                                                    let optionalText:React.ReactNode;
                                                    if(!section.isRequired) {
                                                        optionalText = (
                                                            <>
                                                                <Typography variant="caption">{_translations?.phrases.optionalMarker}</Typography>                                            
                                                            </>
                                                        );
                                                    }

                                                    let sectionHeading:string = '';
                                                    let preview:React.ReactNode = null;
                                                    switch(section.key) {
                                                        case SectionKeys.generalInfo:
                                                            sectionHeading = _translations?.phrases.projectInfoSectionHeading || '';
                                                            preview = <GeneralInfoPreview />;
                                                            break;
                                                        case SectionKeys.heatingCircuts:
                                                            sectionHeading = _translations?.phrases.heatingCircutsSectionHeading || '';
                                                            preview = <HeatingCircutsPreview />;
                                                            break;
                                                        case SectionKeys.coolingCircuts:
                                                            sectionHeading = _translations?.phrases.coolingCircutsSectionHeading || '';
                                                            preview = <CoolingCircutsPreview />;
                                                            break;
                                                        case SectionKeys.heatpump:
                                                            sectionHeading = _translations?.phrases.heatpumpSectionHeading || '';
                                                            preview = <HeatingPumpPreview />;
                                                            break;
                                                        case SectionKeys.tanks:
                                                            sectionHeading = _translations?.phrases.tankSelectionSectionHeading || '';
                                                            preview = <TankSelectionPreview />;
                                                            break;
                                                        case SectionKeys.result:
                                                            sectionHeading = _translations?.phrases.generationResultsSectionHeating || '';
                                                            break;
                                                    }

                                                    const isInError:boolean = _form.data.isSectionInError(section.key);
                                                    return (
                                                        <Step key={section.key}>
                                                            <StepButton
                                                                color='inherit'
                                                                onClick={(e) => {
                                                                    _form.setSection(section.key);

                                                                    e.nativeEvent.stopImmediatePropagation();
                                                                    e.nativeEvent.stopPropagation();
                                                                    e.stopPropagation();
                                                                    return false;
                                                                }}
                                                                disabled={
                                                                    !isInError
                                                                    && !section.areRequiredFieldsFilled()
                                                                    && !_form.data.areAllPiorRequirmentsMet(section.key)
                                                                    && section.key !== SectionKeys.result //the results need to be always clickable when available
                                                                }
                                                                optional={optionalText}
                                                            >
                                                                <StepLabel error={isInError} StepIconComponent={(p:StepIconProps) => <StepperIcons {...p} section={section} activeSection={_form.data.activeSection} />}>
                                                                    { sectionHeading }
                                                                </StepLabel>                                                                                        
                                                            </StepButton>
                                                            {
                                                                !!preview &&
                                                                <StepContent TransitionComponent={(props:React.PropsWithChildren<TransitionProps>) => (<>{ props.children }</>)}>
                                                                    { preview }
                                                                </StepContent>
                                                            }
                                                        </Step> 
                                                    );
                                                })
                                            }                
                                        </Stepper>
                                    </Box>
                                </Collapse>
                            </Box>                                
                        </Stack>
                    </Card>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        maxWidth: '100%'
                    }}
                >
                    <Box
                        sx={{
                            minWidth: '240px'
                        }}
                    >
                        { _sectionContents }
                    </Box>                   
                </Box>
            </Stack>
            <DraggableDialog
                isOpen={_isUnsavedChangesWarningOpen.value}
                onClose={() => {
                    _isUnsavedChangesWarningOpen.set(false);
                }}
                title={_translations?.phrases.backToHomeWarningTitle }
                actions={
                    <>
                        <Button 
                            onClick={() => {
                                props.onHomeClick?.();
                            }}
                        >
                            { _translations?.phrases.homeBtn }
                        </Button>
                        <Button 
                            autoFocus 
                            onClick={() => _isUnsavedChangesWarningOpen.set(false)}
                            variant='contained'
                        >
                            { _translations?.phrases.cancelBtnLabel }
                        </Button>
                    </>
                }
            >
                { _translations?.phrases.backToHomeWarningContent }
            </DraggableDialog>                
        </>
    );
}
import * as React from 'react';
import { IBoiler } from './IBoiler';
import { IAccuTank } from './IAccuTank';
import { IHeatingElement } from './IHeatingElement';
import { IHeatpump } from './IHeatpump';

/**
 * Holds all the possible devices of the form
 */
export interface IDevices {
    /**
     * The boilers available to the form
     */
    boilers:IBoiler[];
    /**
     * The accu tanks available to the form
     */
    accuTanks:IAccuTank[]
    /**
     * The heating elements available to the form
     */
    heatingElements:IHeatingElement[];
    /**
     * The heatpumps available to the form
     */    
    heatpumps:IHeatpump[];
}

/**
 * Holds all the devices, if NULL they need to be loaded in from CosmosDB
 */
export const DevicesContext = React.createContext<IDevices|null>(null);
import * as _ from 'lodash';

/**
 * Returns an array of all the enum values in question
 * @param enumToBeParsed The enum that is to be parsed into value (Works only with numberic enums!)
 */
export function g_getEnumValues<T>(enumToBeParsed:any):T[] {
    const ret:T[] = Object.values(enumToBeParsed as any).filter(v => !isNaN(Number(v))) as T[];
    return ret;;
}
/**
 * Does a millisecond delay for a async function
 * @param ms The milliseconds you want to delay
 */
export const g_delay = (ms:number):Promise<void> => new Promise(res => setTimeout(res, ms));
/**
 * The response type for the getNameOf function
 */
type GetNameOfResponseObjet<T> = {
    [key in keyof T]: string
}
/**
 * Gets the name of the object member as a string
 * @param obj The object whom member you seek
 * @param expression The method that will select the member from the object
 * @returns The name of the member as a string
 */
export function g_getNameOf<T>(obj:T, expression:(x:GetNameOfResponseObjet<T>) => string):string {    
    
    let response:GetNameOfResponseObjet<T> = {} as GetNameOfResponseObjet<T>;
    Object.getOwnPropertyNames(obj).forEach((key: string) => {
        response[key as keyof T] = key;
    });
    
    return expression(response);
}
/**
 * Merges two settings objects into one
 * @param oldSettings The old settings that are currently up
 * @param newSettings The new Settings that are to go into the old ones
 * @returns The new settings object
 * @remarks Arrays are holesale replaces and not merged
 */
export function g_mergeSettings<T>(oldSettings:T, newSettings:Partial<T>):T {
    return _.mergeWith( 
        {}, 
        oldSettings,
        newSettings,
        (a, b) => _.isArray(b) ? b : undefined
    );
}
import * as _ from 'lodash';
import * as React from 'react';
import { IGenerationSuccess } from '../../models/IGenerationSuccess';
import { 
    CoolingType,
    EarthContureCalculationType, 
    HeatingLoadBuildingType, 
    HeatpumpType, 
    IWetCoolingCircut, 
    ICoolingCircuts, 
    IGeneralInfo, 
    IHeatingCircut, 
    IHeatingCircuts, 
    IHeatpumpSelectionValues, 
    ITankSelectionValues 
} from './IProjectData';
import { IDevices } from './DevicesContext';
import { IHeatpump, PumpTypes, HeatinCircutType } from './IHeatpump';
import { IBoiler } from './IBoiler';
import { IRefState } from '../../react-hooks/useRefState';
import { g_getEnumValues, g_mergeSettings } from '../../helpers/generalHelpers';
import { IconType } from '../../helpers/muiHelper';
import { AcUnit, Folder, Grading, HeatPump, LocalFireDepartment, WaterDrop } from '@mui/icons-material';
import { g_getHiddenId } from '../../helpers/alphaProHelpers';


export enum SectionKeys {
    generalInfo = "GeneralInfo",
    heatingCircuts = "HeatingCircuts",
    coolingCircuts = "CoolingCircuts",
    heatpump = "HeatpumpSelection",
    tanks = "TanksSelection",
    result = "Result"
}



const DEFAULT_GROUND_PUMP_DIMENSIONING:number = 100;
const DEFAULT_AIR_PUMP_DIMENSIONING:number = 120;
export const DEFAULT_EARTH_TYPE_VALUE:number = 15;

/**
 * Holds the heatpump dimensioning values
 */
export interface IDimensioningRange {
    /**
     * The max dimensioning
     */
    min:number,
    /**
     * The min dimensioning
     */
    max:number
}
/**
 * Holds the default values for the airpump dimensioning
 */
export const AIR_DIMENSIONING:IDimensioningRange = {
    min: 10,
    max: 150
};
/**
 * Holds the default values for the groundpump dimensioning
 */
export const GROUND_DIMENSIONING:IDimensioningRange = {
    min: 60,
    max: 150
};

export type SetValuesFnType = (values:Partial<ISectionValues>, isSystem?:boolean) => void;

export interface ISectionValues {}
export interface ISection {    
    /**
     * The unique key for the section
     */
    key:SectionKeys;
    /**
     * If TRUE the section is required
     */
    isRequired:boolean;
    /**
     * Returns TRUE if all required fields are filled
     */
    areRequiredFieldsFilled():boolean;
    /**
     * Holds the form values for the section
     */
    values:ISectionValues;
    /**
     * The method that will update the section values
     */
    setValues:SetValuesFnType;
    /**
     * Returns TRUE if any of the required fields are not filled.
     * @remarks Also sets all the inError flags in the form.
     */
    isInError:() => boolean;
    /**
     * The icon of the step
     */
    icon:IconType;
}

export interface ICircutInErrorValues {
    isHeatloadInError:boolean;
    isResistanceInError:boolean;
    isDnInError:boolean;
}

export interface IGeneralInfoValues extends ISectionValues, IGeneralInfo {
    isProjectNameInError:boolean;
    isHeatingAreaInError:boolean;
    isHeatloadInError:boolean;
    isCoolingLoadInError:boolean;
}
export interface IHeatingCircutsValues extends ISectionValues, IHeatingCircuts {
    floorErrors:ICircutInErrorValues;
    radiatorErrors:ICircutInErrorValues;
    ventilationErrors:ICircutInErrorValues;
    poolErrors:ICircutInErrorValues;
    extraWithoutValveErrors:ICircutInErrorValues;
    extraErrors:ICircutInErrorValues;  
    is3WayFloorHeatingValveDisabled:boolean;    
    maxOperatingTemp:number;    
}
export interface ICoolingCircutsValues extends ISectionValues, ICoolingCircuts {
    coolingInnerPartsErrors:ICircutInErrorValues; 
    ventilationCoolingBatteriesErrors:ICircutInErrorValues; 
}
export interface IHeatpumpValuesForForm extends ISectionValues, IHeatpumpSelectionValues {
    extraHeatSourceErrors:ICircutInErrorValues;    
    isCircleLenghtInError:boolean;
    isNrOfConturesInError:boolean;

    /**
     * Holds all the pumps available to the heatpump selector
     */
    pumps:IHeatpump[];
    /**
     * The ground dimensioning range.
     * @remarks If NULL then shos that there are no compadible pumps
     */
    groundDimensioningRange:IDimensioningRange|null;
    /**
     * The air dimensioning range.
     * @remarks If NULL then shos that there are no compadible pumps
     */
    airDimensioningRange:IDimensioningRange|null;
    /**
     * If TRUE shows that the system has checked the diemensioning ranges
     */
    areDimensioningRangesChecked:boolean;
    /**
     * The pump with is selected in the single pump view
     */
    singlePumpViewPump:HeatpumpType;
}
export interface ITankSelectionFormValues extends ISectionValues, ITankSelectionValues {
    isRecirculationDisabled:boolean;
}

export interface IResultValues extends ISectionValues {
    /**
     * Holds the generation results.
     * If NULL shows that the files are loading i.e. are being generated
     */
    generationResult:IGenerationSuccess|null;
    /**
     * If TRUE shows that the generate button has been clicked and the files are being loaded
     */
    isEnabled:boolean;
    /**
     * The GUID of the project used to select the project.
     * If NULL shows that currently there is no project with the form
     */
    projectGuid:string|null;
    /**
     * The Cosmos DB id of the project
     */
    projectId:string|null;
    /**
     * If TRUE the project has a logo
     */
    hasLogo:boolean;
}

export interface IGeneralInfoSection extends ISection {
    values:IGeneralInfoValues;    
}
export interface IHeatingCircutsSection extends ISection {
    values:IHeatingCircutsValues;
}
export interface ICoolingSection extends ISection {
    values:ICoolingCircutsValues;
}
export interface IHeatpumpSection extends ISection {
    values:IHeatpumpValuesForForm;
}
export interface ITankSelectionSection extends ISection {
    values:ITankSelectionFormValues;
}
export interface IResultsSection extends ISection {
    values:IResultValues;
}

/**
 * Checks to see if the heating circut is enabled or not and all the required fields are cheked
 * @param value The heating circut whom enabled value is being checked
 * @param inErrorValues The circut in error values (Are reset only if provided)
 * @remarks If the heating circut is dissabled it is counted as enabeld i.e. all requrerment are met
 */
export function g_isCircutRequredFilled(value:IHeatingCircut|IWetCoolingCircut, inErrorValues?:ICircutInErrorValues):boolean {
    let ret:boolean = !value.isEnabled;

    if(!ret) {
        ret = (
            !!value.heatingLoad
            && !!value.resistance
            && !!value.dn
        );
    } 
    //resets the inError values if provided
    else if(inErrorValues) {
        inErrorValues.isHeatloadInError = false;
        inErrorValues.isResistanceInError = false;
        inErrorValues.isDnInError = false;
    }

    return ret;
}

/**
 * Sets and checks if the heating/cooling circut is in error or not
 * @param errors The error object to be populated with the values 
 * @param circut The circut to be checked
 * @returns TRUE if any of the fields are in error and FALSE if not
 */
function _setCircutInError(errors:ICircutInErrorValues, circut:IHeatingCircut|IWetCoolingCircut) {
    if(circut.isEnabled) {
        errors.isHeatloadInError = !circut.heatingLoad;
        errors.isResistanceInError = !circut.resistance;
        errors.isDnInError = !circut.dn;
    }  else {
        errors.isHeatloadInError = false;
        errors.isResistanceInError = false;
        errors.isDnInError = false;
    }

    return (
        errors.isHeatloadInError
        || errors.isResistanceInError
        || errors.isDnInError
    );
}

/**
 * Sets a circut DN isInError value
 * @param circutErrors The circut errors
 * @param oldValues The old values currently on the circut
 * @param newValues The new values to be applied to the circut
 */
function _isDnInError(circutErrors:ICircutInErrorValues, oldValues:IHeatingCircut|IWetCoolingCircut, newValues:IHeatingCircut|IWetCoolingCircut):void {
    if(oldValues.dn !== newValues.dn) {
        circutErrors.isDnInError = !newValues.dn;
    }
}

export class FormClass {

    constructor(devices:IDevices|null, formData?:FormClass) {

        this.devices = devices;

        if(!formData) {
            return;
        }

        this.generalInfo.values = _.cloneDeep(formData.generalInfo.values);
        this.heatingCircuts.values = _.cloneDeep(formData.heatingCircuts.values);
        this.coolingCircuts.values = _.cloneDeep(formData.coolingCircuts.values);
        this.heatpump.values = _.cloneDeep(formData.heatpump.values);
        this.tanksSelection.values = _.cloneDeep(formData.tanksSelection.values);
        this.result.values = _.cloneDeep(formData.result.values);        
        this._activeSection = formData.activeSection;
        this.furthestActiveSection = formData.furthestActiveSection;
        this.hasChanges = formData.hasChanges;
    }
    /**
     * Holds all the devices of the form
     * If NULL shows that the devices are still being loaded
     */
    public devices:IDevices|null = null;

    public generalInfo:IGeneralInfoSection = {
        key: SectionKeys.generalInfo,
        icon: Grading,
        values: {
            projectName: null,
            address: null,
            area: null,

            projectDrafter: null,
            projectManager: null,
            accountableProjectDrafter: null,
            projectNumber: null,
            projectClient: null,
            
            isHeatingAreaInError: false,
            isProjectNameInError: false,
            isHeatloadInError: false,
            isCoolingLoadInError: false,

            heatload: null,
            isHeatingLoadManual: false,
            buildingType: HeatingLoadBuildingType.New,

            electricityConsumptionPerAnnum: null,
            gasConsumptionPerAnnum: null,
            pelletConsumptionPerAnnum: null,
            oilConsumptionPerAnnum: null,
            woodConsumptionPerAnnum: null,
            propaneConsumptionPerAnnum: null,

            isCoolingCircutsEnabled: false,
            coolingLoad: null,
            coolingType: CoolingType.None
        },
        isRequired: true,
        areRequiredFieldsFilled: () => {
            const values:IGeneralInfoValues = this.generalInfo.values;
            return !!(
                (values.projectName || '').trim()
                && values.area
                && values.heatload
                && (
                    values.isCoolingCircutsEnabled && values.coolingLoad
                    || !values.isCoolingCircutsEnabled
                )
            );
        },
        setValues: (newValues:Partial<IGeneralInfoValues>, isSystem?:boolean):void => {

            const values:IGeneralInfoValues = g_mergeSettings(this.generalInfo.values, newValues);

            //cleas the dependencies on change
            const oldValues:IGeneralInfoValues = this.generalInfo.values;
            const tanks:ITankSelectionFormValues = this.tanksSelection.values;
            
            if(
                values.heatload !== oldValues.heatload
                || values.coolingLoad !== oldValues.coolingLoad
            ) {
                const heatpumpValues:IHeatpumpValuesForForm = this.heatpump.values;    
                heatpumpValues.type = HeatpumpType.NotSet;
                heatpumpValues.airId = null;
                heatpumpValues.groundId = null;
                
                tanks.accuTankId = null;
                tanks.accuTankHeatingElementId = null;
                tanks.boilerId = null;
                tanks.boilerHeatingElementId = null;
                tanks.accuTankId = null;
                tanks.coolingAccuTankId = null;
                tanks.hasRecirculation = false;
                tanks.isRecirculationDisabled = false;                              
            }

            if(oldValues.coolingType !== values.coolingType) {
                const coolingValues:ICoolingCircutsValues = this.coolingCircuts.values;
                switch(values.coolingType) {
                    case CoolingType.Dry:
                        coolingValues.max = 22;
                        coolingValues.min = 18;
                        break;
                    case CoolingType.Wet:
                        coolingValues.max = 14;
                        coolingValues.min = 7;
                        break;
                }

                if(values.coolingType !== CoolingType.Dry) {
                    coolingValues.dryCoolingFloor.isEnabled = false;
                    coolingValues.dryCoolingVentilation.isEnabled = false;
                    coolingValues.dryCoolingExtraWithoutValve.isEnabled = false;
                    coolingValues.dryCoolingExtra.isEnabled = false;
                }

                if(values.coolingType !== CoolingType.Wet) {
                    coolingValues.coolingInnerParts.isEnabled = false;
                    coolingValues.ventilationCoolingBatteries.isEnabled = false;
                }

                if(values.coolingType === CoolingType.None) {
                    tanks.coolingAccuTankId = null;
                    values.coolingLoad = null;
                }
            }

            if(!values.isCoolingCircutsEnabled) {
                tanks.coolingAccuTankId = null;
            }

            this.generalInfo.values = values;            
            if(!isSystem) {
                this.hasChanges = true;
            }
        },
        isInError: ():boolean => {
            const values:IGeneralInfoValues = this.generalInfo.values;
            values.isProjectNameInError = !(values.projectName || '').trim();
            values.isHeatingAreaInError = !values.area;
            values.isHeatloadInError = !values.heatload;
            values.isCoolingLoadInError = values.isCoolingCircutsEnabled && !values.coolingLoad;
            return (
                values.isProjectNameInError
                || values.isHeatingAreaInError
                || values.isHeatloadInError
                || values.isCoolingLoadInError
            );
        }
    };

    public heatingCircuts:IHeatingCircutsSection = {
        key: SectionKeys.heatingCircuts,
        icon: LocalFireDepartment,
        values: {
            floor: {
                isEnabled: true,
                min: 30,
                max: 35,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },
            radiator: {
                isEnabled: false,
                min: 35,
                max: 45,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },
            ventilation: {
                isEnabled: false,
                min: 40,
                max: 50,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },
            pool: {
                isEnabled: false,
                min: 35,
                max: 40,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },
            extraWithoutValve: {
                isEnabled: false,
                min: 30,
                max: 35,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },
            extra: {
                isEnabled: false,
                min: 30,
                max: 35,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },
            hasFloorHeatingThreeWayValve: false,
            extraCircutLabel: null,

            floorErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            radiatorErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            ventilationErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            poolErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            extraWithoutValveErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            extraErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            is3WayFloorHeatingValveDisabled: false,
            maxOperatingTemp: 0,
            extraCircutWithoutValveLabel: null
        },
        isRequired: true,
        areRequiredFieldsFilled: () => {
            const values:IHeatingCircutsValues = this.heatingCircuts.values;
            return (
                g_isCircutRequredFilled(values.floor)
                && g_isCircutRequredFilled(values.radiator)
                && g_isCircutRequredFilled(values.ventilation)
                && g_isCircutRequredFilled(values.pool)
                && g_isCircutRequredFilled(values.extraWithoutValve)
                && g_isCircutRequredFilled(values.extra)
                && (
                    values.floor.isEnabled
                    || values.radiator.isEnabled
                    || values.ventilation.isEnabled
                    || values.pool.isEnabled
                    || values.extraWithoutValve.isEnabled
                    || values.extra.isEnabled
                )
            );
        },
        setValues:(newValues:Partial<IHeatingCircutsValues>, isSystem?:boolean) => {
            const values:IHeatingCircutsValues = g_mergeSettings(this.heatingCircuts.values, newValues);            
            const oldValues:IHeatingCircutsValues = this.heatingCircuts.values;

            //finds the max temp
            let maxTemp:number = 0;
            if(values.floor.isEnabled && maxTemp < values.floor.max) {
                maxTemp = values.floor.max;
            }
            if(values.radiator.isEnabled && maxTemp < values.radiator.max) {
                maxTemp = values.radiator.max;
            }
            if(values.ventilation.isEnabled && maxTemp < values.ventilation.max) {
                maxTemp = values.ventilation.max;
            }
            if(values.pool.isEnabled && maxTemp < values.pool.max) {
                maxTemp = values.pool.max;
            }
            if(values.extraWithoutValve.isEnabled && maxTemp < values.extraWithoutValve.max) {
                maxTemp = values.extraWithoutValve.max;
            }
            if(values.extra.isEnabled && maxTemp < values.extra.max) {
                maxTemp = values.extra.max;
            }
            values.maxOperatingTemp = maxTemp;
            

            //checks to see if the 3-way valeve is dissabled or not
            if(!values.floor.isEnabled    
                || (
                    values.floor.isEnabled
                    && !values.radiator.isEnabled
                    && !values.ventilation.isEnabled
                    && !values.pool.isEnabled
                    && !values.extraWithoutValve.isEnabled
                    && !values.extra.isEnabled
            )) {
                values.hasFloorHeatingThreeWayValve = false;
                values.is3WayFloorHeatingValveDisabled = true;
            } 
            else {
                values.is3WayFloorHeatingValveDisabled = false;
            }

            //turns off the dry cooling if heating is turned off
            const coolingCircuts:ICoolingCircutsValues = this.coolingCircuts.values;
            if(oldValues.floor.isEnabled != values.floor.isEnabled && !values.floor.isEnabled) {
                const coolingNewValues:Partial<ICoolingCircutsValues> = {
                    dryCoolingFloor: _.cloneDeep(coolingCircuts.dryCoolingFloor)
                };
                coolingNewValues.dryCoolingFloor!.isEnabled = false;
                this.coolingCircuts.setValues(coolingNewValues);  
            }
            if(oldValues.ventilation.isEnabled != values.ventilation.isEnabled && !values.ventilation.isEnabled) {
                const coolingNewValues:Partial<ICoolingCircutsValues> = {
                    dryCoolingVentilation: _.cloneDeep(coolingCircuts.dryCoolingVentilation)
                };
                coolingNewValues.dryCoolingVentilation!.isEnabled = false;
                this.coolingCircuts.setValues(coolingNewValues);  
            }
            if(oldValues.extra.isEnabled != values.extraWithoutValve.isEnabled && !values.extraWithoutValve.isEnabled) {
                const coolingNewValues:Partial<ICoolingCircutsValues> = {
                    dryCoolingExtraWithoutValve: _.cloneDeep(coolingCircuts.dryCoolingExtraWithoutValve)
                };
                coolingNewValues.dryCoolingExtraWithoutValve!.isEnabled = false;
                this.coolingCircuts.setValues(coolingNewValues);  
            }
            if(oldValues.extra.isEnabled != values.extra.isEnabled && !values.extra.isEnabled) {
                const coolingNewValues:Partial<ICoolingCircutsValues> = {
                    dryCoolingExtra: _.cloneDeep(coolingCircuts.dryCoolingExtra)
                };
                coolingNewValues.dryCoolingExtra!.isEnabled = false;
                this.coolingCircuts.setValues(coolingNewValues);  
            }
            
            _isDnInError(values.floorErrors, oldValues.floor, values.floor);
            _isDnInError(values.radiatorErrors, oldValues.radiator, values.radiator);
            _isDnInError(values.ventilationErrors, oldValues.ventilation, values.ventilation);
            _isDnInError(values.poolErrors, oldValues.pool, values.pool);
            _isDnInError(values.extraWithoutValveErrors, oldValues.extraWithoutValve, values.extraWithoutValve);
            _isDnInError(values.extraErrors, oldValues.extra, values.extra);            

            this.heatingCircuts.values = values;

            this.setHeatpumpDimensioning();

            if(!isSystem) {
                this.hasChanges = true;
            }
        },
        isInError: ():boolean => {
            const values:IHeatingCircutsValues = this.heatingCircuts.values;

            let ret:boolean = _setCircutInError(values.floorErrors, values.floor);
            ret = _setCircutInError(values.radiatorErrors, values.radiator) || ret;
            ret = _setCircutInError(values.ventilationErrors, values.ventilation) || ret;
            ret = _setCircutInError(values.poolErrors, values.pool) || ret;
            ret = _setCircutInError(values.extraWithoutValveErrors, values.extraWithoutValve) || ret;
            ret = _setCircutInError(values.extraErrors, values.extra) || ret;

            ret = ret || (
                !values.floor.isEnabled
                && !values.radiator.isEnabled
                && !values.ventilation.isEnabled
                && !values.pool.isEnabled
                && !values.extraWithoutValve.isEnabled
                && !values.extra.isEnabled
            );

            return ret;
        }
    }

    public coolingCircuts:ICoolingSection = {
        key: SectionKeys.coolingCircuts,
        icon: AcUnit,
        isRequired: true,        
        values: {
            min: 7,
            max: 14,
            coolingInnerParts: {
                isEnabled: false,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null,
                min: 7,
                max: 12
            },
            ventilationCoolingBatteries: {
                isEnabled: false,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null,
                min: 7,
                max: 12
            },

            coolingInnerPartsErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            ventilationCoolingBatteriesErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },

            dryCoolingFloor: {
                isEnabled: false,
                coolingLoad: null,
                isCoolingLoadInError: false
            },            
            dryCoolingVentilation: {
                isEnabled: false,
                coolingLoad: null,
                isCoolingLoadInError: false
            },   
            dryCoolingExtraWithoutValve: {
                isEnabled: false,
                coolingLoad: null,
                isCoolingLoadInError: false
            },         
            dryCoolingExtra: {
                isEnabled: false,
                coolingLoad: null,
                isCoolingLoadInError: false
            }            
        },     
        areRequiredFieldsFilled:() => {
            const values:ICoolingCircutsValues = this.coolingCircuts.values;

            let ret:boolean = false;
            switch(this.generalInfo.values.coolingType) {
                case CoolingType.Wet: {
                    ret = (
                        g_isCircutRequredFilled(values.coolingInnerParts)
                        && g_isCircutRequredFilled(values.ventilationCoolingBatteries)
                        && (
                            values.coolingInnerParts.isEnabled
                            || values.ventilationCoolingBatteries.isEnabled
                        )
                    );
                    break;
                }
                case CoolingType.Dry: {
                    ret = !!(
                        values.dryCoolingFloor.isEnabled && values.dryCoolingFloor.coolingLoad
                        || values.dryCoolingVentilation.isEnabled && values.dryCoolingVentilation.coolingLoad
                        || values.dryCoolingExtraWithoutValve.isEnabled && values.dryCoolingExtraWithoutValve.coolingLoad
                        || values.dryCoolingExtra.isEnabled && values.dryCoolingExtra.coolingLoad
                    );
                    break;
                }
            }

            return ret;
        },
        setValues:(newValues:Partial<ICoolingCircutsValues>, isSystem?:boolean) => {
            const values:ICoolingCircutsValues = g_mergeSettings(this.coolingCircuts.values, newValues);
            const oldValues:ICoolingCircutsValues = this.coolingCircuts.values;

            //the function to guarantee values to the wet cooling circut
            const fnSetWetCoolingCircutOperatingTemp = (circut:IWetCoolingCircut) => {
                if(typeof circut.max != 'number') {
                    circut.max = values.max;
                }
                if(typeof circut.min != 'number') {
                    circut.min = values.min;
                }
            };

            const generalInfo:IGeneralInfoValues = this.generalInfo.values;            
            switch(generalInfo.coolingType) {
                case CoolingType.Wet: {
                    _isDnInError(values.coolingInnerPartsErrors, oldValues.coolingInnerParts, values.coolingInnerParts); 
                    _isDnInError(values.ventilationCoolingBatteriesErrors, oldValues.ventilationCoolingBatteries, values.ventilationCoolingBatteries);
                    fnSetWetCoolingCircutOperatingTemp(values.coolingInnerParts);
                    fnSetWetCoolingCircutOperatingTemp(values.ventilationCoolingBatteries);

                    //finds the max and min wet cooling temps
                    let max:number|null = null;
                    let min:number|null = null;

                    if(values.coolingInnerParts.isEnabled) {
                        min = values.coolingInnerParts.min
                        max = values.coolingInnerParts.max;
                    }

                    if(values.ventilationCoolingBatteries.isEnabled) {
                        if(!values.coolingInnerParts.isEnabled) {
                            min = values.ventilationCoolingBatteries.min;
                            max = values.ventilationCoolingBatteries.max;
                        }
                        else {
                            if(max! < values.ventilationCoolingBatteries.max!) {
                                max = values.ventilationCoolingBatteries.max;
                            }
                            if(min! > values.ventilationCoolingBatteries.min!) {
                                min = values.ventilationCoolingBatteries.min;
                            }
                        }
                    }

                    values.max = max || 0;
                    values.min = min || 0;

                    break;
                }
            }

            this.coolingCircuts.values = values;
            if(!isSystem) {
                this.hasChanges = true;
            }
        },
        isInError: ():boolean => {
            const values:ICoolingCircutsValues = this.coolingCircuts.values;

            let ret:boolean = false;

            const generalInfo:IGeneralInfoValues = this.generalInfo.values;            
            switch(generalInfo.coolingType) {
                case CoolingType.Dry: {
                    values.dryCoolingFloor.isCoolingLoadInError = !values.dryCoolingFloor.coolingLoad;
                    values.dryCoolingVentilation.isCoolingLoadInError = !values.dryCoolingVentilation.coolingLoad;
                    values.dryCoolingExtraWithoutValve.isCoolingLoadInError = !values.dryCoolingExtraWithoutValve.coolingLoad;
                    values.dryCoolingExtra.isCoolingLoadInError = !values.dryCoolingExtra.coolingLoad;

                    ret = ret || (
                        !values.dryCoolingFloor.isEnabled
                        && !values.dryCoolingFloor.isEnabled
                        && !values.dryCoolingExtra.isEnabled
                    );
                    break;
                }
                case CoolingType.Wet: {
                    ret = _setCircutInError(values.coolingInnerPartsErrors, values.coolingInnerParts) || ret;
                    ret = _setCircutInError(values.ventilationCoolingBatteriesErrors, values.ventilationCoolingBatteries) || ret;
                    ret = ret || (
                        !values.coolingInnerParts.isEnabled
                        && !values.ventilationCoolingBatteries.isEnabled
                    );
                    break;
                }
            }

            return ret;
        }
    }

    public heatpump:IHeatpumpSection = {
        key: SectionKeys.heatpump,
        icon: HeatPump,
        values: {
            type: HeatpumpType.NotSet,
            groundId: null,
            airId: null,
            groundDimensioning: DEFAULT_GROUND_PUMP_DIMENSIONING, 
            airDimensioning: DEFAULT_AIR_PUMP_DIMENSIONING,
            earthType: DEFAULT_EARTH_TYPE_VALUE,
            earthContureCalType: EarthContureCalculationType.Automatic,
            circleLength: 0,
            nrOfContures: 0,
            totalEathContureLength: 0,
            extraHeatingSource: {
                isEnabled: false,
                min: 30,
                max: 35,
                heatingLoad: null,
                resistance: null,
                dn: null,
                flow: null,
                flow_m3: null
            },

            selectedAirPumpType: null,
            selectedGroundPumpType: null,
            extraHeatingSourceLabel: null,

            extraHeatSourceErrors: {
                isHeatloadInError: false,
                isResistanceInError: false,
                isDnInError: false,
            },
            isCircleLenghtInError: false,
            isNrOfConturesInError: false,

            pumps: [],
            airDimensioningRange: AIR_DIMENSIONING,
            groundDimensioningRange: GROUND_DIMENSIONING,

            areDimensioningRangesChecked: false,
            singlePumpViewPump: HeatpumpType.NotSet
        },
        isRequired: true,  
        areRequiredFieldsFilled: ():boolean => {
            const values:IHeatpumpValuesForForm = this.heatpump.values;
            let ret:boolean = (
                (
                    !!values.groundId
                    || !!values.airId
                )
                && !!values.type
            );

            //makes sure that the circle length and the nr of contures are set for ground pumps
            if(ret && values.type === HeatpumpType.Ground) {
                ret = !!(values.circleLength && values.nrOfContures);
            }

            if(values.extraHeatingSource.isEnabled) {
                ret = ret && g_isCircutRequredFilled(values.extraHeatingSource);
            }

            return ret;
        },
        setValues:(newValues:Partial<IHeatpumpValuesForForm>, isSystem?:boolean) => {
            const values:IHeatpumpValuesForForm = g_mergeSettings(this.heatpump.values, newValues);
            const oldValues:IHeatpumpValuesForForm = this.heatpump.values;

            if(values.airDimensioning !== oldValues.airDimensioning 
                || values.groundDimensioning !== oldValues.groundDimensioning
            ) {
                this._setHeatpumpsBasedOffDimensioning(values);
            }

            //finds the selected pump and disables the extra heading source if needed
            let pumpId:string|null = null;
            switch(values.type) {
                case HeatpumpType.Air:
                    pumpId = values.airId;
                   break;
                case HeatpumpType.Ground:
                    pumpId = values.groundId;
                    break;
            }
            const heatpump:IHeatpump|null = this.devices?.heatpumps.find((p:IHeatpump) => p.guid === pumpId) || null;
            if(heatpump && heatpump.disableExtraHeatSource) {
                values.extraHeatingSource.isEnabled = false;
            }

            if(
                oldValues.type !== values.type
                || oldValues.airId !== values.airId && values.type == HeatpumpType.Air
                || oldValues.groundId !== values.groundId && values.type == HeatpumpType.Ground
            ) {
                const tanks:ITankSelectionFormValues = this.tanksSelection.values;
                tanks.accuTankId = null;
                tanks.accuTankHeatingElementId = null;
                tanks.boilerId = null;
                tanks.boilerHeatingElementId = null;
                tanks.accuTankId = null;
                tanks.coolingAccuTankId = null;
                tanks.hasRecirculation = false;
                tanks.isRecirculationDisabled = false;
            }

            if(oldValues.groundId !== values.groundId && !values.airId && values.groundId) {
                values.type = HeatpumpType.Ground;
            }

            if(oldValues.airId !== values.airId && !values.groundId && values.airId) {
                values.type = HeatpumpType.Air;
            }

            this._setIsReserculationDisabled(values, this.tanksSelection.values);

            this.heatpump.values = values;
            if(!isSystem) {
                this.hasChanges = true;
            }
        },
        isInError: ():boolean => {
            const values:IHeatpumpValuesForForm = this.heatpump.values;
            let ret:boolean = (
                !values.type
                || values.type === HeatpumpType.Ground && !values.groundId
                || values.type === HeatpumpType.Air && !values.airId
            );

            if(values.type === HeatpumpType.Ground) {
                values.isCircleLenghtInError = !values.circleLength;
                values.isNrOfConturesInError = !values.nrOfContures;

                ret = (
                    ret 
                    || values.isCircleLenghtInError
                    || values.isNrOfConturesInError
                );
            }
            
            if(values.extraHeatingSource.isEnabled) {
                ret = _setCircutInError(values.extraHeatSourceErrors, values.extraHeatingSource) || ret;
            }

            return ret;
        }
    }
    /**
     * The method to check if the reserculation needs to be disabled or not
     * @param heatpumpValues The active/new heatpump selction values
     * @param newTankValues The active/new tank selection values
     */
    private _setIsReserculationDisabled = (heatpumpValues:IHeatpumpValuesForForm, newTankValues:ITankSelectionFormValues):void => {
        
        //if no heatpump is selected
        if(heatpumpValues.type === HeatpumpType.NotSet) {
            newTankValues.hasRecirculation = false;
            newTankValues.isRecirculationDisabled = true;
            return;
        }

        //gets the pump boilers
        const pumpId:string|null = heatpumpValues.type === HeatpumpType.Ground ? heatpumpValues.groundId : heatpumpValues.airId;
        const selectedPump:IHeatpump|null = this.devices?.heatpumps.find((pump:IHeatpump) => pump.guid === pumpId) || null;    
        const boilers:(IBoiler|null)[] = selectedPump?.boilerIds.map((guid:string) => this.devices?.boilers.find((b:IBoiler) => b.guid === guid) || null) || [];
        
        //enables the selection when the heatpump have boilers (except when no boiler has been selected)
        let isRecirculationDisabled = !boilers.length;
        if(!newTankValues.boilerId) {
            isRecirculationDisabled = true;
        }        

        //enables the selection if the pump has an integrated boilder
        if(isRecirculationDisabled && selectedPump?.integratedBoiler) {
            isRecirculationDisabled = false; //makes sure you can select the recirculation with the integrated boiler types
        }

        //sets the values
        newTankValues.isRecirculationDisabled = isRecirculationDisabled;
        if(isRecirculationDisabled) {
            newTankValues.hasRecirculation = false;
        }
    }

    public tanksSelection:ITankSelectionSection = {
        key: SectionKeys.tanks,
        icon: WaterDrop,
        values: {
            boilerId: null,
            accuTankId: null,
            coolingAccuTankId: null,
            boilerHeatingElementId: null,
            accuTankHeatingElementId: null,
            hasRecirculation: false,
            isRecirculationDisabled: false
        },
        isRequired: true,  
        areRequiredFieldsFilled: ():boolean => {           
            const values:ITankSelectionFormValues = this.tanksSelection.values;
            let ret:boolean = !!values.accuTankId;
            if(this.generalInfo.values.coolingType === CoolingType.Wet) {
                ret = ret && !!values.coolingAccuTankId;
            }
            return ret;
        },
        setValues:(newValues:Partial<ITankSelectionFormValues>, isSystem?:boolean) => {
            const values:ITankSelectionFormValues = g_mergeSettings(this.tanksSelection.values, newValues);
            const oldValues:ITankSelectionFormValues = this.tanksSelection.values;

            if(oldValues.accuTankId !== values.accuTankId) {
                values.accuTankHeatingElementId = null;
            }
            if(oldValues.boilerId !== values.boilerId) {
                values.boilerHeatingElementId = null;
            }
            
            this._setIsReserculationDisabled(this.heatpump.values, values);
            
            this.tanksSelection.values = values;
            if(!isSystem) {
                this.hasChanges = true;
            }
        },
        isInError: ():boolean => {
            const values:ITankSelectionFormValues = this.tanksSelection.values;
            const ret:boolean = (
                !values.accuTankId
                || this.generalInfo.values.coolingType === CoolingType.Wet && !values.coolingAccuTankId
            );
            return ret;
        }
    }

    public result:IResultsSection = {
        key: SectionKeys.result,
        icon: Folder,
        values: {
            generationResult: null,
            isEnabled: false,
            projectGuid: null,
            projectId: null,
            hasLogo: false
        },
        isRequired: true, //this is not requires per-se, but we do not want the optional marker
        areRequiredFieldsFilled: ():boolean => {
            return this.areAllSectionsFilled;
        },
        setValues:(newValues:Partial<IResultValues>) => {
            const values:IResultValues = g_mergeSettings(this.result.values, newValues);
            
            if(values.generationResult && values.generationResult !== this.result.values.generationResult) {
                this.hasChanges = false;
            } else {
                this.hasChanges = true;
            }

            this.result.values = values;            
        },
        isInError: ():boolean => {
            const ret:boolean = false;
            return ret;
        }
    }

    /**
     * Returns the heatpumps for the dimensioning value
     * @param dimensioning The dimensioning value to be filtered to
     * @param type The type of pump this is i.e. Air/Ground
     * @returns All the valid pumps
     */
    private _getPumpsWithDimensioning = (dimensioning:number, type:HeatpumpType):IHeatpump[] => {

        const generalInfo:IGeneralInfoValues = this.generalInfo.values;
        let ret:IHeatpump[] = (this.devices?.heatpumps || []).filter((p:IHeatpump) => p.type === type);

        let hasHeatLoadFiltering = false;
        //checks to see if heatload relavent pumps are available
        if(
            generalInfo.isCoolingCircutsEnabled && generalInfo.heatload! >= generalInfo.coolingLoad! 
            || !generalInfo.isCoolingCircutsEnabled
        ) { //only allow filtering based off heatload if the heatload is bigger than cooling load or no cooling is applied

            const power:number = (generalInfo.heatload || 0) * (dimensioning / 100);
            const minPower:number = power * 0.8; //the minimum power allowed in the dimensioning range
            const maxPower:number = power * 1.2; //the maximum power allowed in the dimensioning range
            hasHeatLoadFiltering = true;

            ret = ret.filter((pump:IHeatpump) => {
                
                if(pump.type !== type) {
                    return false;
                }
    
                let isInPowerRange:boolean = false;
                const pumpPower:number = pump.power || pump.maxPower;
    
                if(
                    minPower <= pumpPower
                    && maxPower >= pumpPower
                ) {
                    isInPowerRange = true;
                }
    
                return isInPowerRange;
            });
        }

        //checks to see if the pumps need to be filtered to cooling
        if(generalInfo.isCoolingCircutsEnabled) {
            let coolingLoad:number = generalInfo.coolingLoad!;
            let coolingLoadMax:number = coolingLoad * (dimensioning / 100);;
            
            ret = ret.filter((p:IHeatpump) => {
                let isVisible:boolean = false;
                if(generalInfo.coolingLoad) {
                    switch(generalInfo.coolingType) {
                        case CoolingType.Wet:
                            isVisible = generalInfo.coolingLoad <= p.coolingPowerWet && (hasHeatLoadFiltering || !hasHeatLoadFiltering && p.coolingPowerWet <= coolingLoadMax);
                            break;
                        case CoolingType.Dry:
                            isVisible = generalInfo.coolingLoad <= p.coolingPowerDry;
                            break;
                    }
                }
                return isVisible;
            });                
        }

        //filters out the hidden pumps
        const hiddenId:string|null = g_getHiddenId();
        ret = ret.filter((p:IHeatpump) => {
            let isNotHidden = !p.hiddenId;
            if(!isNotHidden) {
                isNotHidden = p.hiddenId?.toLocaleLowerCase() === hiddenId;
            }
            return isNotHidden;
        });

        return ret;
    };
    /**
     * Calculates the heatpumps' dimensioning range
     */
    private _calculatePumpDimensioningRange(heatpumps:IHeatpumpValuesForForm) {

        if(!this.devices) {
            return;
        }

        //gets the actual dimensioning values
        const fnGetDimensioning = (dimensioning:IDimensioningRange, type:HeatpumpType):IDimensioningRange|null => {
            let ret:IDimensioningRange|null = {
                max: 0,
                min: 0
            };

            let dimensioningValue:number;
            for(dimensioningValue = dimensioning.min; dimensioningValue <= dimensioning.max; dimensioningValue++) {
                const pumps:IHeatpump[] = this._getPumpsWithDimensioning(dimensioningValue, type).filter((pump:IHeatpump) => {
                    //filters down the pump based off the operating temp
                    return pump.maxInletTemp >= this.heatingCircuts.values.maxOperatingTemp;
                });

                if(pumps.length) {
                    if(!ret.min || ret.min > dimensioningValue) {
                        ret.min = dimensioningValue;
                    }
                    if(ret.max < dimensioningValue) {
                        ret.max = dimensioningValue;
                    }
                }
            }
    
            if(!ret.max || !ret.min) {
                ret = null
            }

            return ret;
        };

        const ground:IDimensioningRange|null = fnGetDimensioning(GROUND_DIMENSIONING, HeatpumpType.Ground);
        const air:IDimensioningRange|null = fnGetDimensioning(AIR_DIMENSIONING, HeatpumpType.Air);
        
        heatpumps.airDimensioningRange = air;
        heatpumps.groundDimensioningRange = ground;

        if(air && !(heatpumps.airDimensioning >= air.min && heatpumps.airDimensioning <= air.max)) {
            heatpumps.airDimensioning = air.max;
        }

        if(ground && !(heatpumps.groundDimensioning >= ground.min && heatpumps.groundDimensioning <= ground.max)) {
            heatpumps.groundDimensioning = ground.max;
        }

        if(
            this.generalInfo.values.heatload
            && (
                this.generalInfo.values.coolingType !== CoolingType.None && this.generalInfo.values.coolingLoad
                || this.generalInfo.values.coolingType === CoolingType.None
            )
        ) {
            heatpumps.areDimensioningRangesChecked = true;
        } else {
            heatpumps.areDimensioningRangesChecked = false;
        }
    }   
    /**
     * Gets the selection of the pump type tab for given pumps
     * @param pumps The pumps who should own the type in question
     * @param selectedType The currently selected pump type (If NULL then no selection has been made)
     * @returns The new type or NULL if notthing could be chosen
     */
    private _getSelectePumpType = (pumps:IHeatpump[], selectedType:PumpTypes|null):PumpTypes|null => {
        let ret:PumpTypes|null = selectedType;

        //starts to search for a type only if the selection does not exist or there is no pump with that setting
        if(ret === null || pumps.length && !pumps.find((p:IHeatpump) => p.pumpType === ret)) {
            ret = null;

            const arr:PumpTypes[] = g_getEnumValues<PumpTypes>(PumpTypes);
            for(let index:number = 0; index < arr.length; index++) {
                const type:PumpTypes = arr[index];
                if(pumps.find((p:IHeatpump) => p.pumpType === type)) {
                    ret = type;
                    break;
                }
            }
        }

        return ret;
    };
    /**
     * Sets the heatpumps for the heatpump selection based off the dimensioning setting in the heatpumps
     * @param newHeatpumpValues The new heatpump values to be populated with values
     */
    private _setHeatpumpsBasedOffDimensioning = (newHeatpumpValues:IHeatpumpValuesForForm) => {

        //gets all the pumps
        const groundPumps:IHeatpump[] = this._getPumpsWithDimensioning(newHeatpumpValues.groundDimensioning, HeatpumpType.Ground);
        const airPumps:IHeatpump[] = this._getPumpsWithDimensioning(newHeatpumpValues.airDimensioning, HeatpumpType.Air);

        const heatincCircucts = this.heatingCircuts.values;

        //creates the full pump list
        let pumps:IHeatpump[] = [...groundPumps, ...airPumps];       

        //sorts the pumps so only the enabled heating circuts are available
        pumps = pumps.filter((pump:IHeatpump) => {
            if(!pump.allowedHeatingCircuts) {
                return true; //allows all the circuts if not specified
            }

            

            //does the floor check by hand because it has the 3 way valve on it!
            const canFoorBeEnabled = pump.allowedHeatingCircuts.includes(HeatinCircutType.Floor);
            const canFoor3WBeEnabled = pump.allowedHeatingCircuts.includes(HeatinCircutType.Floor3W);


            const isFloor3WEnabled = (
                !heatincCircucts.is3WayFloorHeatingValveDisabled
                && heatincCircucts.hasFloorHeatingThreeWayValve
            ) && heatincCircucts.floor.isEnabled;

            const isFloorEnabled = (
                !heatincCircucts.is3WayFloorHeatingValveDisabled 
                || (
                    heatincCircucts.is3WayFloorHeatingValveDisabled
                    && !heatincCircucts.hasFloorHeatingThreeWayValve
                )

            ) && heatincCircucts.floor.isEnabled && !isFloor3WEnabled;             
            
            let isAnyAllowedCircutsEnabled = false;
            let areAllDisallowedCircutsDissabled = true;

            const allTypes:HeatinCircutType[] = g_getEnumValues<HeatinCircutType>(HeatinCircutType);
            const enabledCircuts:{
                [key in HeatinCircutType]?: boolean
            } = {};
            allTypes.forEach((type:HeatinCircutType) => {

                //checks to see if the circut is enabled
                let isEnabled = false;
                switch(type) {
                    case HeatinCircutType.Floor: 
                        isEnabled = isFloorEnabled;
                        break;
                    case HeatinCircutType.Floor3W: 
                        isEnabled = isFloor3WEnabled;
                        break;
                    case HeatinCircutType.Radiator:
                        isEnabled = heatincCircucts.radiator.isEnabled;
                        break;
                    case HeatinCircutType.Ventilation:
                        isEnabled = heatincCircucts.ventilation.isEnabled;
                        break;
                    case HeatinCircutType.Pool:
                        isEnabled = heatincCircucts.pool.isEnabled;
                        break;
                    case HeatinCircutType.ExtraHeating:
                        isEnabled = heatincCircucts.extra.isEnabled;
                        break;
                    case HeatinCircutType.ExtraHeatingWithoutValve:
                        isEnabled = heatincCircucts.extraWithoutValve.isEnabled;
                        break;
                }

                //checks to see if the enabled circust are enabled and the disabled circuts are disabled
                if(pump.allowedHeatingCircuts.includes(type)) {
                    isAnyAllowedCircutsEnabled = isAnyAllowedCircutsEnabled || isEnabled;
                }
                else {
                    areAllDisallowedCircutsDissabled = areAllDisallowedCircutsDissabled && !isEnabled;
                }
            });            

            return isAnyAllowedCircutsEnabled && areAllDisallowedCircutsDissabled;
        });

        //checks to see if the selected pump is available, if not clears it
        if(!pumps.find((p:IHeatpump) => newHeatpumpValues.airId === p.guid)) {
            newHeatpumpValues.airId = null;
            if(newHeatpumpValues.type === HeatpumpType.Air) {
                newHeatpumpValues.type = HeatpumpType.NotSet;
            }
        }
        if(!pumps.find((p:IHeatpump) => newHeatpumpValues.groundId === p.guid)) {
            newHeatpumpValues.groundId = null;
            if(newHeatpumpValues.type === HeatpumpType.Ground) {
                newHeatpumpValues.type = HeatpumpType.NotSet;
            }
        }

        //sorts the pumps
        pumps.sort((a:IHeatpump, b:IHeatpump) => {
            return (a.power || a.maxPower) - (b.power || b.maxPower);
        });
        newHeatpumpValues.pumps = pumps;

        //checks to see if the tab selection needs to be updated
        let updateTabs:boolean = false;
        const selectedGroundPumpType:PumpTypes|null = this._getSelectePumpType(groundPumps, newHeatpumpValues.selectedGroundPumpType);
        if(selectedGroundPumpType !== newHeatpumpValues.selectedGroundPumpType) {
            newHeatpumpValues.selectedGroundPumpType = selectedGroundPumpType;
        }

        const selectedAirPumpType:PumpTypes|null = this._getSelectePumpType(airPumps, newHeatpumpValues.selectedAirPumpType);
        if(selectedAirPumpType !== newHeatpumpValues.selectedAirPumpType) {
            newHeatpumpValues.selectedAirPumpType = selectedAirPumpType;
        }

        if(this.devices) {
            //removes the selections if not in the range anymore
            if(newHeatpumpValues.groundId && !groundPumps.find((p:IHeatpump) => p.guid === newHeatpumpValues.groundId)) {            
                newHeatpumpValues.groundId = null;
                if(newHeatpumpValues.type === HeatpumpType.Ground) {
                    newHeatpumpValues.type = HeatpumpType.NotSet;
                }
            }
            if(newHeatpumpValues.airId && !airPumps.find((p:IHeatpump) => p.guid === newHeatpumpValues.airId)) {
                newHeatpumpValues.airId = null;
                if(newHeatpumpValues.type === HeatpumpType.Air) {
                    newHeatpumpValues.type = HeatpumpType.NotSet;
                }
            }
        }
    }
    /**
     * Gets the name of the pump that needs to be displayed on the UI
     * @param pump The pump whom name you seek
     */
    public getPumpName(pump?:IHeatpump):string{
        let ret:string = pump?.name || '';
        if(pump){
            switch(this.generalInfo.values.coolingType) {
                case CoolingType.None:
                    ret = pump.heatingDisplayName || ret;
                    break;
                case CoolingType.Wet:
                    ret = pump.wetCoolingDisplayName || ret;
                    break;
                case CoolingType.Dry:
                    ret = pump.dryCoolingDisplayName || ret;
                    break;
            }
        }
        return ret;
    }
    /**
     * Sets the heatpump dimensioning. In essence checks to see what pumps are available.
     */    
    public setHeatpumpDimensioning(heatincCircucts?:IHeatingCircutsValues | undefined) {
        const newHeatpumpValues:IHeatpumpValuesForForm = _.cloneDeep(this.heatpump.values);
        this._calculatePumpDimensioningRange(newHeatpumpValues);
        this._setHeatpumpsBasedOffDimensioning(newHeatpumpValues);
        this.heatpump.setValues(newHeatpumpValues);
    }
    /**
     * Checks to see if a all the prior requirements are met by the system
     * @param key The section to with we want to check the required status
     */
    public areAllPiorRequirmentsMet(key:SectionKeys):boolean {
        let ret = true;
        const sections:ISection[] = this.getSections();

        for(let index:number = 0; index < sections.length; index++) {
            const currentSection:ISection = sections[index];
            if(currentSection.key === key) {
                break;
            }

            ret = ret && currentSection.areRequiredFieldsFilled();
            if(!ret) {
                break;
            }
        }

        return ret;
    }
    /**
     * Checks to see if all sections are filled, if so then returns TRUE
     */
    public get areAllSectionsFilled():boolean {
        let sections:ISection[] = this.getSections();
        let ret:boolean = true;
        let index:number = sections.length;
        while(index--) {
            const section:ISection = sections[index];

            if(section.key === SectionKeys.result) {
                continue;
            }

            ret = ret && section.areRequiredFieldsFilled();

            if(!ret) {
                break;
            }
        }

        return ret;
    }
    /**
     * Returns all the sections of the form
     */
    public getSections = ():ISection[] => {
        let ret:ISection[] = [
            this.generalInfo,
            this.heatingCircuts,           
        ];

        if(this.generalInfo.values.isCoolingCircutsEnabled) {
            ret.push(this.coolingCircuts);
        }

        ret = ret.concat([            
            this.heatpump,
            this.tanksSelection
        ])

        if(this.result.values.isEnabled) {
            ret.push(this.result);
        }

        return ret;
    }
    /**
     * If TRUE the form has changes done to it
     */
    public hasChanges:boolean = false;
    /**
     * Holds the section key of the section that is furthest along
     */
    public furthestActiveSection:SectionKeys = SectionKeys.generalInfo;
    /**
     * Holds the currently active section of the form
     */
    private _activeSection:SectionKeys = SectionKeys.generalInfo;
    /**
     * Returns the currently active section of the form
     */
    public get activeSection():SectionKeys {
        return this._activeSection;
    }
    /**
     * Activates the next section in the sections list
     */    
    public setNextSection = ():void => {

        const sections:ISection[] = this.getSections();
        let activeStepIndex:number = sections.findIndex((s:ISection) => s.key === this._activeSection);
        activeStepIndex++;

        //safty check so we wont go over the bounds of the sections
        if(activeStepIndex >= sections.length) {
            activeStepIndex--;
        }

        const activeSection:ISection = sections[activeStepIndex];
        this._activeSection = activeSection.key;

        //sets the furthest section when needed
        const furtherstStepIndex:number = sections.findIndex((s:ISection) => s.key === this.furthestActiveSection);
        if(furtherstStepIndex < activeStepIndex) {
            this.furthestActiveSection = activeSection.key;
        }
    }
    /**
     * Activates the previous section in the sections list
     */    
    public setPreviousSection = ():void => {        
        const sections:ISection[] = this.getSections();
        let activeStepIndex:number = sections.findIndex((s:ISection) => s.key === this._activeSection);
        activeStepIndex--;

        //safty check so we wont go over the bounds of the sections
        if(activeStepIndex < 0) {
            activeStepIndex++;
        }

        const activeSection:ISection = sections[activeStepIndex];
        this._activeSection = activeSection.key;
    }
    /**
     * Activates specific section named in the key
     * @param key The section to be activated
     */    
    public setSection = (key:SectionKeys):void => {
        this._activeSection = key;

        const sections:ISection[] = this.getSections();
        const activeStepIndex:number = sections.findIndex((s:ISection) => s.key === key);

        //sets the furthest section when needed
        const furtherstStepIndex:number = sections.findIndex((s:ISection) => s.key === this.furthestActiveSection);
        if(furtherstStepIndex < activeStepIndex) {
            this.furthestActiveSection = key;
        }
    }
    /**
     * Checks to see if the step is in error or not
     * @param key The section key for whom we want to check if the section is in error or not
     * @returns TRUE if the section is in error and false if not
     */
    public isSectionInError = (key:SectionKeys):boolean => {
        const sections:ISection[] = this.getSections();
        const stepIndex:number = sections.findIndex((s:ISection) => s.key === key);
        const furtherstStepIndex:number = sections.findIndex((s:ISection) => s.key === this.furthestActiveSection);

        let ret:boolean = false;
        if(stepIndex < furtherstStepIndex) {
            const section:ISection = sections[stepIndex];
            ret = section.isInError();
        }

        return ret;
    }
    /**
     * Returns TRUE if the active section is in error and FALSE if not.
     * In essence checks if the active section fields are in error or not!
     */
    public isActiveSectionInErrorCheck = ():boolean => {
        const sections:ISection[] = this.getSections();
        const activeSection:ISection|null = sections.find((s:ISection) => s.key === this._activeSection) || null;
        return activeSection?.isInError() || false;
    }
    /**
     * Does a full check on the entire form to see if it is in error or not
     * @returns TRUE if in error and FALSE if not
     */
    public isFormInError = ():boolean => {
        let ret:boolean = false;
        const sections:ISection[] = this.getSections();
        let index:number = sections.length;
        while(index--) {
            const section:ISection = sections[index];
            ret = section.isInError() || ret;
        } 
        return ret;
    }
    /**
     * Checks all the settings that are device dependent
     * @param devices The new devices to be added
     */
    public checkDeviceSettings = ():void => {
        this._calculatePumpDimensioningRange(this.heatpump.values);
        this._setHeatpumpsBasedOffDimensioning(this.heatpump.values);
        this._setIsReserculationDisabled(
            this.heatpump.values,
            this.tanksSelection.values
        );        
    }
}

export const FormContext = React.createContext<IRefState<FormClass>|null>(null);

export interface IUseFormHookResult {
    data:FormClass;
    setGeneralInfo:SetValuesFnType;
    setHeatingCircuts:SetValuesFnType;
    setCoolingCircuts:SetValuesFnType;    
    setHeatingpump:SetValuesFnType;
    setTankSelection:SetValuesFnType;
    setResult:SetValuesFnType;
    next:() => void;
    previous:() => void;
    setSection:(key:SectionKeys) => void;
    reRender:() => void;
}

/**
 * Creates a hook where you can interact with the form where the values can be updated with a render
 */
export function useForm():IUseFormHookResult {
    const form:IRefState<FormClass>|null = React.useContext(FormContext);
    
    let ret:IUseFormHookResult = {} as IUseFormHookResult;
    if(form?.value) {
        ret = {
            data: form.value,
            setGeneralInfo: (values:Partial<IGeneralInfoValues>, isSystem?:boolean):void => {
                if(form.value) {
                    form.value?.generalInfo.setValues(values, isSystem);
                    form.set(new FormClass(form.value?.devices, form.value));
                }
            },
            setHeatingCircuts: (values:Partial<IHeatingCircutsValues>, isSystem?:boolean):void => {
                if(form.value) {
                    form.value.heatingCircuts.setValues(values, isSystem);
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },
            setCoolingCircuts: (values:Partial<ICoolingCircutsValues>, isSystem?:boolean):void => {
                if(form.value) {
                    form.value.coolingCircuts.setValues(values, isSystem);
                    form.set(new FormClass(form.value.devices, form.value));                
                }
            },
            setHeatingpump: (values:Partial<IHeatpumpSelectionValues>, isSystem?:boolean):void => {
                if(form.value) {
                    form.value.heatpump.setValues(values, isSystem);
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },
            setTankSelection: (values:Partial<ITankSelectionFormValues>, isSystem?:boolean):void => {
                if(form.value) {
                    form.value.tanksSelection.setValues(values, isSystem);
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },
            setResult: (values:Partial<IResultValues>, isSystem?:boolean):void => {
                if(form.value) {
                    form.value.result.setValues(values, isSystem);
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },
            next: ():void => {
                if(form.value) {
                    form.value.setNextSection();
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },
            previous: ():void => {
                if(form.value) {
                    form.value.setPreviousSection();
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },        
            setSection:(key:SectionKeys) => {
                if(form.value) {
                    form.value.setSection(key);
                    form.set(new FormClass(form.value.devices, form.value));
                }
            },
            reRender:() => {
                if(form.value) {
                    form.set(new FormClass(form.value.devices, form.value));
                }
            }
        };        
    }


    return React.useMemo(() => ret, [form?.ref.current]);
}
import { IMsalContext } from "@azure/msal-react";
import { IActiveAccount } from "../models/IActiveAccount";
import * as _ from 'lodash';

/**
 * The session storage key that holds the saved user values
 */
const ACCOUNT_SESSION_STORAGE_KEY:string = "AlphaProSavedAccountDetails";

/**
 * Returns a parsed user object that is easier to use for the active user
 * @param msal The MSAL context used to parse the user out of
 */
export function g_getActiveUser(msal:IMsalContext):IActiveAccount {
    const activeUser = msal.instance.getActiveAccount();    
    let ret:IActiveAccount = {
        id: activeUser?.localAccountId,
        name: activeUser?.name,
        userName: activeUser?.username,
        environment: activeUser?.environment,
        emails: activeUser?.idTokenClaims?.emails        
    };

    ret.canGenerate =  activeUser?.idTokenClaims?.extension_CanGenerate as boolean || false;
    ret.projectDrafter = activeUser?.idTokenClaims?.extension_ProjectDrafter as string || '';
    ret.projectManager = activeUser?.idTokenClaims?.extension_ProjectManager as string || '';
    ret.accountableProjectDrafter = activeUser?.idTokenClaims?.extension_AccountableProjectDrafter as string || '';
    ret.projectNumber = activeUser?.idTokenClaims?.extension_ProjectNumber as string || '';

    ret.company = activeUser?.idTokenClaims?.extension_Company as string || '';
    ret.phone = activeUser?.idTokenClaims?.extension_Phone as string || '';
    ret.logoURL = activeUser?.idTokenClaims?.extension_LogoURL as string || null;
    ret.logoBase64 = null;
    
    const savedAccountDetails:IActiveAccount = JSON.parse(window.sessionStorage.getItem(ACCOUNT_SESSION_STORAGE_KEY) as string);
    if(savedAccountDetails) {
        ret = _.merge({}, ret, savedAccountDetails);
    }

    return ret;
}
/**
 * Saves user info into session storage for use in the session
 * @param user The user into to be saved in the session storage (If NULL then the user info will be removed)
 * @remarks This is required because the MSAL claims can not be updated without a logout
 */
export function g_saveUserAccountDetails(user:IActiveAccount|null):void {
    if(user) {
        window.sessionStorage.setItem(ACCOUNT_SESSION_STORAGE_KEY, JSON.stringify(user));
    }
    else {
        window.sessionStorage.removeItem(ACCOUNT_SESSION_STORAGE_KEY);
    }
}
import * as React from 'react';
import styles from "./TankSelectionPreview.module.scss";

import { IHeatpumpValuesForForm, ITankSelectionFormValues, IUseFormHookResult, useForm } from '../FormContext';
import { DevicesContext, IDevices } from '../DevicesContext';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { IAccuTank } from '../IAccuTank';
import { IBoiler } from '../IBoiler';
import { Box, Typography } from '@mui/material';

/**
 * Renders out the tanks' preview for the sepper form
 */
export function TankSelectionPreview(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:ITankSelectionFormValues = _form.data.tanksSelection.values;
    const _devices:IDevices|null = React.useContext(DevicesContext);
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    
    if(!_data.accuTankId && !_data.boilerId && !_data.coolingAccuTankId) {
        return null;
    }

    const _accuTank:IAccuTank|null = _devices?.accuTanks.find((t:IAccuTank|null) => t?.guid === _data.accuTankId) || null;
    const _coolingAccuTank:IAccuTank|null = _devices?.accuTanks.find((t:IAccuTank|null) => t?.guid === _data.coolingAccuTankId) || null;
    const _boiler:IBoiler|null = _devices?.boilers.find((t:IBoiler|null) => t?.guid === _data.boilerId) || null;
    
    return (
        <>            
            {
                !!_accuTank &&
                <Box>
                    <Typography
                        variant='body2'     
                        component={'span'}
                    >
                        { _translations?.phrases.accuTank + ' ' }
                    </Typography>
                    <Typography
                        variant='body2'     
                        component={'span'}
                        sx={{
                            color: "primary.dark",
                        }}
                        className={styles.name}
                    >
                        { _accuTank.name }
                    </Typography>
                </Box>
            }
            {
                !!_coolingAccuTank &&
                <Box>
                    <Typography
                        variant='body2'     
                        component={'span'}
                    >
                        { _translations?.phrases.coolingAccuTank + ' ' }
                    </Typography>
                    <Typography
                        variant='body2'     
                        component={'span'}
                        sx={{
                            color: "primary.dark",
                        }}
                        className={styles.name}
                    >
                        { _coolingAccuTank.name }
                    </Typography>
                </Box>
            }
            {
                !!_boiler &&
                <Box>
                    <Typography
                        variant='body2'     
                        component={'span'}
                    >
                        { _translations?.phrases.boiler + ' ' }
                    </Typography>
                    <Typography
                        variant='body2'     
                        component={'span'}
                        sx={{
                            color: "primary.dark",
                        }}
                        className={styles.name}
                    >
                        { _boiler.name }
                    </Typography>
                </Box>
            }
            {
                _data.hasRecirculation &&
                <Typography variant='body2' className={styles.recirculation}>
                    { _translations?.phrases.recirculation }
                </Typography>
            }
        </>
    );
}
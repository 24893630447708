import * as React from 'react';
import { ISvgViewerProps } from './ISvgViewerProps';

import { IState, useState } from '../../react-hooks/useState';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Grow } from '@mui/material';

/**
 * Renders out the SVG viewer with a ZOOM/PINCH available.
 * Makes sure that the SVG is always with the dimensions of the container.
 */
export function SvgViewer(props:ISvgViewerProps) {
    const _svgContainerRef = React.useRef<HTMLDivElement>();
    const _svgWidth:IState<number> = useState<number>(0);

    /**
     * Sets the SVG dimensions based of the SVG container
     */
    const _setSvgDimensions = () => {
        const width:number = _svgContainerRef.current?.offsetWidth || 0;
        if(!width) {
            return;
        }
        _svgWidth.set(width);
    };
    /**
     * The observer that tracks the size of the SVG container
     */
    const _observer = new ResizeObserver(_setSvgDimensions);

    if(!props.src) {
        return null;
    }
    
    return (
        <div 
            ref={(elm:HTMLDivElement) => {                
                if(elm) {
                    _observer.disconnect();
                    _observer.observe(elm);
                }
                _svgContainerRef.current = elm;
            }}
        >
        {
            !_svgWidth.value &&
            <Grow in>
                <img 
                    src={props.src}                        
                    onLoad={(e) => {
                        const img:HTMLImageElement = e.target as HTMLImageElement; 
                        _svgWidth.set(img.width);
                    }}
                    style={{
                        width: '100%'
                    }}
                />
            </Grow>
        }
        {
            !!_svgWidth.value &&
            <TransformWrapper
                initialScale={1}
            >
                <TransformComponent>
                    <img 
                        src={props.src} 
                        width={_svgWidth.value}
                    />
                </TransformComponent>
            </TransformWrapper>
        }
        </div>
    )
}
import * as React from 'react';
import { IAppInputProps } from './IAppInputProps';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import { IState, useState } from '../../react-hooks/useState';
import { ITranslationsContext, TranslationsContext } from '../../translations/TranslationsContext';

export function AppInput(props:IAppInputProps) {
    /**
     * The value displayed in the input box
     */
    const _value:IState<string|null> = useState<string|null>(null);
    /**
     * The value gotten from the props
     */
    let _propsValue:string|null = null;
    /**
     * Holds tha translations of the app
     */
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    /**
     * The Mui Theme
     */
    const _theme = useTheme();

    if(props.numericValue === null || typeof props.numericValue === 'number') {
        if(typeof props.numericValue === 'number') {
            _propsValue = `${props.numericValue}`;
        } 
        else {
            _propsValue = `${props.numericValue || ''}` || null;
        }
    }
    if(props.value === null || typeof props.value === 'string') {
        _propsValue = props.value;
    }

    //makes sure that the propsValue would take over on it's change
    React.useEffect(() => {
        if(_value.value !== _propsValue && !(_value.value && _propsValue === null)) {
            _value.set(_propsValue);
        }
    }, [_propsValue]);
    /**
     * Returns the input value as a Numberic or NULL if empty
     * @param value The input to be converted
     */
    const _getNumericValue = (value:string):number|null => {
        const ret:number|null = (value ? Number(value.replaceAll(',', '.')) : null) as number;
        return ret;
    }
    /**
     * If TRUE shows that this is a Numeric input and is currently NaN
     */
    let _isNumbericError:IState<boolean> = useState<boolean>(false);
    
    return (
        <TextField 
            label={props.label || ''} 
            variant="standard"                                
            InputProps={{
                endAdornment: props.unit ? (
                    <InputAdornment 
                        position="start"
                        sx={{
                            '.MuiTypography-root': {
                                color: !!props.inErrorMessage || _isNumbericError.value ? _theme.palette.error.dark : 'inherit'
                            }
                        }}
                    >
                        {props.unit}
                    </InputAdornment>
                ) : null
            }}
            InputLabelProps={{
                shrink: !!_value.value && typeof _value.value === 'string'
            }}
            fullWidth={props.isFullWidth}    
            required={props.isRequired}
            color={props.isSecondary ? 'secondary' : 'primary'}    
            onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                _value.set(e.target.value);
                if(props.onChange) {
                    props.onChange(e.target.value);
                }
                if(props.onNumberChange) {
                    const nrValue:number|null = _getNumericValue(e.target.value);
                    const isValueNaN:boolean = isNaN(nrValue as number);
                    if(nrValue === null || !isValueNaN) {
                        props.onNumberChange(nrValue);
                        _isNumbericError.set(false);
                    }
                    else if(isValueNaN){
                        props.onNumberChange(null);
                        _isNumbericError.set(true);
                    }
                }
            }}
            value={_value.value || ''} 
            disabled={props.isDisabled}
            onClick={(e) => {
                if(props.stopOnClickEventBubbeling) {
                    e.nativeEvent.stopImmediatePropagation();
                    e.nativeEvent.stopPropagation();
                    e.stopPropagation();
                    return false;
                }
            }}
            error={!!props.inErrorMessage || _isNumbericError.value}
            helperText={(_isNumbericError.value ? _translations?.phrases.nonNumbericInputErrorMessage : props.inErrorMessage) || null}
            onBlur={() => props.onBlur?.()}   
            onFocus={() => props.onFocus?.()}
        />
    );
}
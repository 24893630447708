import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { g_msalConfig } from "./authConfig";

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const _msalInstance = new PublicClientApplication(g_msalConfig);

// Default to using the first account if no account is active on page load
if (!_msalInstance.getActiveAccount() && _msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    _msalInstance.setActiveAccount(_msalInstance.getAllAccounts()[0]);
}

_msalInstance.addEventCallback((event:any) => {
    if ((
            event.eventType === EventType.LOGIN_SUCCESS 
            || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
            || event.eventType === EventType.SSO_SILENT_SUCCESS
        )
        && event.payload.account
    ) {
        _msalInstance.setActiveAccount(event.payload.account);
    }
});

const _root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
_root.render(
  <React.StrictMode>
    <BrowserRouter>      
      <App instance={_msalInstance} />      
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from 'react';
import { IHeatingCircutProps } from './IHeatingCircutProps';
import styles from './HeatingCircut.module.scss';

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, DoneOutline } from '@mui/icons-material';
import { IState, useState } from '../../../react-hooks/useState';
import CheckIcon from '@mui/icons-material/Check';
import { AppSlider } from '../../AppSlider/AppSlider';

import * as _ from 'lodash';
import { AppInput } from '../../AppInput/AppInput';
import { POSSIBLE_DN_VALUES, IDNValue, g_getDNValue } from '../../../helpers/dnHelper';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { IHeatingCircut } from '../IProjectData';

/**
 * Renders out an individual heating circut
 */
export function HeatingCircut(props:IHeatingCircutProps) {

    const _tempLabel:IState<string> = useState<string>('');
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    //automatically calculates the DN value
    React.useEffect(() => {        
        const newDN:IDNValue|null = g_getDNValue(props.value.max, props.value.min, props.value.heatingLoad as number);
        if(newDN && (
            newDN.DN !== props.value.dn
            || newDN.flow !== props.value.flow
            || newDN.flow_m3 !== props.value.flow_m3
        )) {
            const value:IHeatingCircut = _.cloneDeep(props.value);
            value.dn = newDN.DN;
            value.flow = newDN.flow as number;
            value.flow_m3 = newDN.flow_m3 as number;
            props.onChange(value);
        }
    }, [
        props.value.min,
        props.value.max,
        props.value.heatingLoad
    ]);
    
    return (
        <Accordion 
            expanded={props.value.isEnabled}
            onChange={(e, isExpanded:boolean) => {
                const value:IHeatingCircut = _.cloneDeep(props.value);
                value.isEnabled = isExpanded;
                props.onChange(value);                
            }}  
            className={(props.doesNotHaveBorders ? styles.noBorders : '') + ' ' + (props.className || '')}
            sx={props.sx}
        >
            <AccordionSummary>
                <Stack 
                    spacing={1} 
                    direction={"row"}
                    flexGrow={1}
                >
                    <Box
                        sx={{
                            alignSelf: 'center'
                        }}
                    >
                        { props.icon }
                    </Box>
                    <Box 
                        sx={{
                            flexGrow: 1,
                            flexBasis: "100%"
                        }}
                    >
                        {
                            props.value.isEnabled ? (
                                !!props.onLabelChange ? (
                                    <AppInput 
                                        value={props.label || ''}
                                        onChange={(newLabel:string) => {
                                            props.onLabelChange?.(newLabel);
                                        }}
                                        label={props.labelInputLabel}
                                        isFullWidth
                                        unit={_tempLabel.value} 
                                        stopOnClickEventBubbeling
                                    />
                                ) : (
                                    <Typography>{ props.label } { props.value.isEnabled && _tempLabel.value }</Typography>
                                )
                            ) : (
                                <Typography>{ props.label || props.labelInputLabel }</Typography>
                            )
                        }
                    </Box>
                    <Box
                        sx={{
                            alignSelf: 'center'
                        }}
                    >
                        {
                            props.value.isEnabled ? (
                                <CheckIcon color={'primary'} />
                            ) : (
                                <CheckBoxOutlineBlank /> 
                            )
                        }
                    </Box> 
                </Stack>              
            </AccordionSummary>
            <AccordionDetails> 
                <AppSlider
                    min={typeof props.minTemp === 'number' ? props.minTemp : 20}
                    max={typeof props.maxTemp === 'number' ? props.maxTemp : 50}
                    initial={[props.value.min, props.value.max]}
                    hideLabel
                    onLabelChange={(newLabel:string) => {
                        _tempLabel.set(newLabel);
                    }}
                    onChange={(newValue:number|number[]) => {
                        const minMax:number[] = newValue as number[];
                        const value:IHeatingCircut = _.cloneDeep(props.value);
                        value.min = minMax[0];
                        value.max = minMax[1];
                        props.onChange(value);
                    }}
                />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>                        
                        <AppInput
                            label={props.heatloadLabel || _translations?.phrases.heatingCircutHeatingLoad}
                            numericValue={props.value.heatingLoad}
                            onNumberChange={(newValue:number|null) => {
                                const value:IHeatingCircut = _.cloneDeep(props.value);
                                value.heatingLoad = newValue;
                                props.onChange(value);
                            }}
                            isFullWidth
                            isRequired
                            unit='kW'
                            inErrorMessage={
                                props.inErrorValues?.isHeatloadInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }
                            onBlur={() => props.onHeatloadBlur?.()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <AppInput
                            label={_translations?.phrases.heatingCircutResistance}
                            numericValue={props.value.resistance}
                            onNumberChange={(newValue:number|null) => {
                                const value:IHeatingCircut = _.cloneDeep(props.value);
                                value.resistance = newValue;
                                props.onChange(value);
                            }}
                            isFullWidth
                            isRequired
                            unit='kPa'
                            inErrorMessage={
                                props.inErrorValues?.isResistanceInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }
                            onBlur={() => props.onResistanceBlur?.()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField 
                            label={_translations?.phrases.heatingCircutDN}
                            variant="standard"                                   
                            select
                            fullWidth 
                            value={props.value.dn}
                            onChange={(e) => {
                                const value:IHeatingCircut = _.cloneDeep(props.value);
                                value.dn = Number(e.target.value);
                                props.onChange(value);
                            }}
                            required  
                            InputLabelProps={{
                                shrink: typeof props.value.dn === 'number'
                            }}   
                            error={props.inErrorValues?.isDnInError}
                            helperText={
                                props.inErrorValues?.isDnInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }                                                  
                        >
                            {
                                POSSIBLE_DN_VALUES.map((value:IDNValue) => (
                                    <MenuItem value={value.DN} key={value.DN}>
                                        { value.DN }
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                {
                    props.extraSelection
                }
            </AccordionDetails>
        </Accordion>
    );
}
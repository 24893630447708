import * as React from 'react';
import { IDryCoolingCircutProps } from './IDryCoolingCircutProps';
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Grid, 
    Typography 
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { AppInput } from '../../AppInput/AppInput';
import * as _ from 'lodash';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { IDryCoolingCircut } from '../IProjectData';

/**
 * Renders out an individual cooling circut
 */
export function DryCoolingCircut(props:IDryCoolingCircutProps) {
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    if(!props.heatingCircut.isEnabled) {
        return null;
    }

    return (
        <Accordion 
            expanded={props.coolingCircut.isEnabled}
            onChange={(e, isExpanded:boolean) => {
                const value:IDryCoolingCircut = _.cloneDeep(props.coolingCircut);
                value.isEnabled = isExpanded;
                props.onChange(value);
            }}
        >
            <AccordionSummary>
                <Grid container spacing={1}>
                    <Grid item >
                        <props.icon />
                    </Grid>
                    <Grid item sx={{flexGrow: 1}}>
                        <Typography>{ props.label }</Typography>
                    </Grid>
                    <Grid item>
                        {
                            props.coolingCircut.isEnabled ? (
                                <CheckIcon color={'primary'} />
                            ) : (
                                <CheckBoxOutlineBlank />
                            )
                        }                        
                    </Grid>
                </Grid>                
            </AccordionSummary>
            <AccordionDetails> 
                <AppInput
                    label={_translations?.phrases.coolingCircutHeatingLoad}
                    numericValue={props.coolingCircut.coolingLoad}
                    onNumberChange={(newValue:number|null) => {
                        const value:IDryCoolingCircut = _.cloneDeep(props.coolingCircut);
                        value.coolingLoad = newValue;
                        props.onChange(value);
                    }}
                    isFullWidth
                    isRequired
                    unit='kW'
                    inErrorMessage={
                        props.coolingCircut.isCoolingLoadInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                    }
                    onBlur={props.onCoolingLoadBlur}
                />
            </AccordionDetails>
        </Accordion>
    );
}
import * as React from 'react';
import styles from './Dashboard.module.scss';
import { IDashboardProps } from './IDashboardProps';

import { IMsalContext, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { g_getActiveUser } from '../../helpers/msalHelper';
import { IActiveAccount } from '../../models/IActiveAccount';
import { ITranslationsContext, TranslationsContext } from '../../translations/TranslationsContext';
import { IState, useState } from '../../react-hooks/useState';
import { IProjectQuckInfo, EMPTY_PROJECT } from '../../models/IProjectQuckInfo';
import useFetchWithMsal from '../../react-hooks/useFetchWithMsal';
import { g_protectedResources } from '../../authConfig';
import { Loader } from '../Loader/Loader';
import { DataGrid, GridActionsCellItem, GridColDef, GridLocaleText, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { g_getNameOf } from '../../helpers/generalHelpers';
import { Format } from '../../helpers/Format';
import { Alert, Box, Button, Link, SxProps, Theme, Tooltip } from '@mui/material';
import { ExpandButton } from '../ExpandButton/ExpandButton';
import { Add, ContentCopy, Delete, Margin, Visibility } from '@mui/icons-material';
import { DraggableDialog } from '../DraggableDialog/DraggableDialog';
import { FooterContext } from '../../App';


/**
 * Renders out the projects dashboard
 */
export function Dashboard(props:IDashboardProps) {
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _projects:IState<IProjectQuckInfo[]|null> = useState<IProjectQuckInfo[]|null>(null);

    const _msal:IMsalContext = useMsal();
    const _activeUser:IActiveAccount = g_getActiveUser(_msal);
    const _msalFetchProjcts = useFetchWithMsal(g_protectedResources.apiCadGenerator.scopes.generator);
    const _msalDeleteProjcts = useFetchWithMsal(g_protectedResources.apiCadGenerator.scopes.generator);
    const _isAuth:boolean = useIsAuthenticated();
    const _footer:IState<React.ReactNode>|null = React.useContext(FooterContext);
    
    //Fetches the projects for the active user
    React.useEffect(() => {
        if(
            !_projects.value
            && _isAuth 
            && !_msalFetchProjcts.isLoading.ref.current //not already loading
            && !_msalFetchProjcts.error.ref.current //not in error
        ) {
            _msalFetchProjcts.execute('POST', g_protectedResources.apiGetProjects.endpoint, _activeUser, true).then((projects:IProjectQuckInfo[]) => {
                _projects.set(projects || null);
            });
        }
    }, [
        _isAuth, 
        _msalFetchProjcts,
        _projects.value
    ]);

    //clears the footer of the page
    React.useEffect(() => {
        _footer?.set(null);
    }, []);

    /**
     * Holds the project for whom you want to know if the projct shoulc be deleted or not.
     * If NULL then no project is to be deleted.
     */
    const _deleteDialogProject:IState<IProjectQuckInfo|null> = useState<IProjectQuckInfo|null>(null);

    const _gridLocaleText: GridLocaleText = {
        // Root
        noRowsLabel: _translations?.phrases.noRowsLabel as string,
        noResultsOverlayLabel: _translations?.phrases.noResultsOverlayLabel as string,
        
        // Density selector toolbar button text
        toolbarDensity: _translations?.phrases.toolbarDensity as string,
        toolbarDensityLabel: _translations?.phrases.toolbarDensityLabel as string,
        toolbarDensityCompact: _translations?.phrases.toolbarDensityCompact as string,
        toolbarDensityStandard: _translations?.phrases.toolbarDensityStandard as string,
        toolbarDensityComfortable: _translations?.phrases.toolbarDensityComfortable as string,
        
        // Columns selector toolbar button text
        toolbarColumns: _translations?.phrases.toolbarColumns as string,
        toolbarColumnsLabel: _translations?.phrases.toolbarColumnsLabel as string,
        
        // Filters toolbar button text
        toolbarFilters: _translations?.phrases.toolbarFilters as string,
        toolbarFiltersLabel: _translations?.phrases.toolbarFiltersLabel as string,
        toolbarFiltersTooltipHide: _translations?.phrases.toolbarFiltersTooltipHide as string,
        toolbarFiltersTooltipShow: _translations?.phrases.toolbarFiltersTooltipShow as string,
        toolbarFiltersTooltipActive: (count:number) =>
            count !== 1 ? Format.text(_translations?.phrases.toolbarFiltersTooltipActiveMultiple, count) : Format.text(_translations?.phrases.toolbarFiltersTooltipActiveSingle, count),
        
        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: _translations?.phrases.toolbarQuickFilterPlaceholder as string,
        toolbarQuickFilterLabel: _translations?.phrases.toolbarQuickFilterLabel as string,
        toolbarQuickFilterDeleteIconLabel: _translations?.phrases.toolbarQuickFilterDeleteIconLabel as string,
        
        // Export selector toolbar button text
        toolbarExport: _translations?.phrases.toolbarExport as string,
        toolbarExportLabel: _translations?.phrases.toolbarExportLabel as string,
        toolbarExportCSV: _translations?.phrases.toolbarExportCSV as string,
        toolbarExportPrint: _translations?.phrases.toolbarExportPrint as string,
        toolbarExportExcel: _translations?.phrases.toolbarExportExcel as string,
        
        // Columns panel text
        columnsPanelTextFieldLabel: _translations?.phrases.columnsPanelTextFieldLabel as string,
        columnsPanelTextFieldPlaceholder: _translations?.phrases.columnsPanelTextFieldPlaceholder as string,
        columnsPanelDragIconLabel: _translations?.phrases.columnsPanelDragIconLabel as string,
        columnsPanelShowAllButton: _translations?.phrases.columnsPanelShowAllButton as string,
        columnsPanelHideAllButton: _translations?.phrases.columnsPanelHideAllButton as string,
        
        // Filter panel text
        filterPanelAddFilter: _translations?.phrases.filterPanelAddFilter as string,
        filterPanelRemoveAll: _translations?.phrases.filterPanelRemoveAll as string,
        filterPanelDeleteIconLabel: _translations?.phrases.filterPanelDeleteIconLabel as string,
        filterPanelLogicOperator: _translations?.phrases.filterPanelLogicOperator as string,
        filterPanelOperator: _translations?.phrases.filterPanelOperator as string,
        filterPanelOperatorAnd: _translations?.phrases.filterPanelOperatorAnd as string,
        filterPanelOperatorOr: _translations?.phrases.filterPanelOperatorOr as string,
        filterPanelColumns: _translations?.phrases.filterPanelColumns as string,
        filterPanelInputLabel: _translations?.phrases.filterPanelInputLabel as string,
        filterPanelInputPlaceholder: _translations?.phrases.filterPanelInputPlaceholder as string,
        
        // Filter operators text
        filterOperatorContains: _translations?.phrases.filterOperatorContains as string,
        filterOperatorEquals: _translations?.phrases.filterOperatorEquals as string,
        filterOperatorStartsWith: _translations?.phrases.filterOperatorStartsWith as string,
        filterOperatorEndsWith: _translations?.phrases.filterOperatorEndsWith as string,
        filterOperatorIs: _translations?.phrases.filterOperatorIs as string,
        filterOperatorNot: _translations?.phrases.filterOperatorNot as string,
        filterOperatorAfter: _translations?.phrases.filterOperatorAfter as string,
        filterOperatorOnOrAfter: _translations?.phrases.filterOperatorOnOrAfter as string,
        filterOperatorBefore: _translations?.phrases.filterOperatorBefore as string,
        filterOperatorOnOrBefore: _translations?.phrases.filterOperatorOnOrBefore as string,
        filterOperatorIsEmpty: _translations?.phrases.filterOperatorIsEmpty as string,
        filterOperatorIsNotEmpty: _translations?.phrases.filterOperatorIsNotEmpty as string,
        filterOperatorIsAnyOf: _translations?.phrases.filterOperatorIsAnyOf as string,
        'filterOperator=': _translations?.phrases.filterOperatorEqual as string,
        'filterOperator!=': _translations?.phrases.filterOperatorNotEqual as string,
        'filterOperator>': _translations?.phrases.filterOperatorGratherThen as string,
        'filterOperator>=': _translations?.phrases.filterOperatorGratherThenEqual as string,
        'filterOperator<': _translations?.phrases.filterOperatorLessThen as string,
        'filterOperator<=': _translations?.phrases.filterOperatorLessThenEqual as string,

        // Header filter operators text
        headerFilterOperatorContains: _translations?.phrases.headerFilterOperatorContains as string,
        headerFilterOperatorEquals: _translations?.phrases.headerFilterOperatorEquals as string,
        headerFilterOperatorStartsWith: _translations?.phrases.headerFilterOperatorStartsWith as string,
        headerFilterOperatorEndsWith: _translations?.phrases.headerFilterOperatorEndsWith as string,
        headerFilterOperatorIs: _translations?.phrases.headerFilterOperatorIs as string,
        headerFilterOperatorNot: _translations?.phrases.headerFilterOperatorNot as string,
        headerFilterOperatorAfter: _translations?.phrases.headerFilterOperatorAfter as string,
        headerFilterOperatorOnOrAfter: _translations?.phrases.headerFilterOperatorOnOrAfter as string,
        headerFilterOperatorBefore: _translations?.phrases.headerFilterOperatorBefore as string,
        headerFilterOperatorOnOrBefore: _translations?.phrases.headerFilterOperatorOnOrBefore as string,
        headerFilterOperatorIsEmpty: _translations?.phrases.headerFilterOperatorIsEmpty as string,
        headerFilterOperatorIsNotEmpty: _translations?.phrases.headerFilterOperatorIsNotEmpty as string,
        headerFilterOperatorIsAnyOf: _translations?.phrases.headerFilterOperatorIsAnyOf as string,
        'headerFilterOperator=': _translations?.phrases.headerFilterOperatorEqual as string,
        'headerFilterOperator!=': _translations?.phrases.headerFilterOperatorNotEqual as string,
        'headerFilterOperator>': _translations?.phrases.headerFilterOperatorGratherThen as string,
        'headerFilterOperator>=': _translations?.phrases.headerFilterOperatorGratherThenEqual as string,
        'headerFilterOperator<': _translations?.phrases.headerFilterOperatorLessThen as string,
        'headerFilterOperator<=': _translations?.phrases.headerFilterOperatorLessThenEqual as string,
        
        // Filter values text
        filterValueAny: _translations?.phrases.filterValueAny as string,
        filterValueTrue: _translations?.phrases.filterValueTrue as string,
        filterValueFalse: _translations?.phrases.filterValueFalse as string,
        
        // Column menu text
        columnMenuLabel: _translations?.phrases.columnMenuLabel as string,
        columnMenuShowColumns: _translations?.phrases.columnMenuShowColumns as string,
        columnMenuManageColumns: _translations?.phrases.columnMenuManageColumns as string,
        columnMenuFilter: _translations?.phrases.columnMenuFilter as string,
        columnMenuHideColumn: _translations?.phrases.columnMenuHideColumn as string,
        columnMenuUnsort: _translations?.phrases.columnMenuUnsort as string,
        columnMenuSortAsc: _translations?.phrases.columnMenuSortAsc as string,
        columnMenuSortDesc: _translations?.phrases.columnMenuSortDesc as string,
        
        // Column header text
        columnHeaderFiltersTooltipActive: (count:number) =>
            count !== 1 ? Format.text(_translations?.phrases.columnHeaderFiltersTooltipActiveMultiple, count) : Format.text(_translations?.phrases.columnHeaderFiltersTooltipActiveSingle, count),
        columnHeaderFiltersLabel: _translations?.phrases.columnMenuSortDesc as string,
        columnHeaderSortIconLabel: _translations?.phrases.columnMenuSortDesc as string,
        
        // Rows selected footer text
        footerRowSelected: (count) =>
            count !== 1
            ? Format.text(_translations?.phrases.footerRowSelectedMultiple, count)
            : Format.text(_translations?.phrases.footerRowSelectedSingle, count),
        
        // Total row amount footer text
        footerTotalRows:  _translations?.phrases.columnMenuSortDesc as string,
        
        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount:number, totalCount:number) => 
            Format.text(_translations?.phrases.footerRowSelectedSingle, visibleCount.toLocaleString(), totalCount.toLocaleString()),
        
        // Checkbox selection text
        checkboxSelectionHeaderName: _translations?.phrases.checkboxSelectionHeaderName as string,
        checkboxSelectionSelectAllRows: _translations?.phrases.checkboxSelectionSelectAllRows as string,
        checkboxSelectionUnselectAllRows: _translations?.phrases.checkboxSelectionUnselectAllRows as string,
        checkboxSelectionSelectRow: _translations?.phrases.checkboxSelectionSelectRow as string,
        checkboxSelectionUnselectRow: _translations?.phrases.checkboxSelectionUnselectRow as string,
        
        // Boolean cell text
        booleanCellTrueLabel: _translations?.phrases.booleanCellTrueLabel as string,
        booleanCellFalseLabel: _translations?.phrases.booleanCellFalseLabel as string,
        
        // Actions cell more text
        actionsCellMore: _translations?.phrases.actionsCellMore as string,
        
        // Column pinning text
        pinToLeft: _translations?.phrases.pinToLeft as string,
        pinToRight: _translations?.phrases.pinToRight as string,
        unpin: _translations?.phrases.unpin as string,
        
        // Tree Data
        treeDataGroupingHeaderName: _translations?.phrases.treeDataGroupingHeaderName as string,
        treeDataExpand: _translations?.phrases.treeDataExpand as string,
        treeDataCollapse: _translations?.phrases.treeDataCollapse as string,
        
        // Grouping columns
        groupingColumnHeaderName: _translations?.phrases.groupingColumnHeaderName as string,
        groupColumn: (name) => Format.text(_translations?.phrases.groupColumn, name),
        unGroupColumn: (name) => Format.text(_translations?.phrases.unGroupColumn, name),
        
        // Master/detail
        detailPanelToggle: _translations?.phrases.detailPanelToggle as string,
        expandDetailPanel: _translations?.phrases.expandDetailPanel as string,
        collapseDetailPanel: _translations?.phrases.collapseDetailPanel as string,
        
        // Used core components translation keys
        MuiTablePagination: {},
        
        // Row reordering text
        rowReorderingHeaderName: _translations?.phrases.rowReorderingHeaderName as string,
        
        // Aggregation
        aggregationMenuItemHeader: _translations?.phrases.aggregationMenuItemHeader as string,
        aggregationFunctionLabelSum: _translations?.phrases.aggregationFunctionLabelSum as string,
        aggregationFunctionLabelAvg: _translations?.phrases.aggregationFunctionLabelAvg as string,
        aggregationFunctionLabelMin: _translations?.phrases.aggregationFunctionLabelMin as string,
        aggregationFunctionLabelMax: _translations?.phrases.aggregationFunctionLabelMax as string,
        aggregationFunctionLabelSize: _translations?.phrases.aggregationFunctionLabelSize as string,
    };

    if(_msalFetchProjcts.isLoading.value) {
        return <Loader />
    }

    const _columns:GridColDef[] = [
        {
            field: g_getNameOf(EMPTY_PROJECT, o => o.projectName),
            headerName: _translations?.phrases.projectName,
            type: 'string',
            flex: 1,
            minWidth: 150,
            renderCell: (params:GridRenderCellParams<IProjectQuckInfo>) => {
                return (
                    <Link
                        onClick={() => {
                            props.onOpenClick(params.row);
                        }}
                        sx={{
                            cursor: 'pointer'
                        }}
                        underline='hover'
                    >
                        { params.row.projectName }
                    </Link>
                );
            }            
        },
        {
            field: g_getNameOf(EMPTY_PROJECT, o => o.projectAddress),
            headerName: _translations?.phrases.projectAddress,
            type: 'string',
            flex: 1,
            minWidth: 150
        },
        {
            field: g_getNameOf(EMPTY_PROJECT, o => o.heatpumpName),
            headerName: _translations?.phrases.heatPumpPhrase,
            type: 'string',
            flex: 1,
            minWidth: 100
        },
        {
            field: g_getNameOf(EMPTY_PROJECT, o => o.generationIsoDate),
            headerName: _translations?.phrases.dashboardDate,
            type: 'date',
            valueGetter(params:GridValueGetterParams<IProjectQuckInfo>) {
                return params.row.generationIsoDate ? new Date(params.row.generationIsoDate) : null
            },
            renderCell: (params:GridRenderCellParams<IProjectQuckInfo>) => {
                return Format.isoDate(params.row.generationIsoDate);
            },
            flex: 1,
            minWidth: 100
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: _translations?.phrases.actionsHeading,            
            width: 125,
            getActions: (params:GridRowParams<IProjectQuckInfo>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={_translations?.phrases.viewProjectBtnTooltip}>
                            <Visibility 
                                color='primary'                                
                            />
                        </Tooltip>
                    }
                    label={_translations?.phrases.viewProjectBtnTooltip as string}                    
                    onClick={() => props.onOpenClick(params.row)}                     
                />,
                <GridActionsCellItem
                    icon={
                        <Tooltip title={_translations?.phrases.cloneProjectBtnTooltip}>
                            <ContentCopy 
                                color="secondary"
                            />
                        </Tooltip>
                    }
                    label={_translations?.phrases.cloneProjectBtnTooltip as string}                    
                    onClick={() => props.onCloneClick(params.row)}                     
                />,
                <GridActionsCellItem
                    icon={
                        <Tooltip title={_translations?.phrases.deleteProjectBtnTooltip}>
                            <Delete 
                                color="error"
                            />
                        </Tooltip>
                    }
                    label={_translations?.phrases.deleteProjectBtnTooltip as string}                    
                    onClick={() => {                        
                        _deleteDialogProject.set(params.row);
                    }}                     
                />
            ]
        }
    ];

    /**
     * The "sx" param values that will hide the datagrid scrollbar
     */
    let _hiddenScrollbarSX:SxProps<Theme> = {}
    if(!_projects.value?.length) {
        _hiddenScrollbarSX = {
            '.MuiDataGrid-virtualScroller': {
                opacity: 0,
                height: 0,
                overflow: 'hidden'
            }
        };
    }

    if(!_projects.value?.length) {
        return (
            <>
                
                <Box 
                    sx={{
                        textAlign: 'center',
                        margin: '2em'
                    }}
                >
                    <ExpandButton
                        label={_translations?.phrases.addNewProjectBtnLabel}
                        icon={Add}
                        onClick={props.onNewClick}
                    />
                </Box>
                <Alert severity='info'>
                    {_translations?.phrases.noProjectsYet}
                </Alert>
            </>
        )
    }

    return (
        <>
            <Box className={styles.buttons}>
                <ExpandButton
                    label={_translations?.phrases.addNewProjectBtnLabel}
                    icon={Add}
                    onClick={props.onNewClick}
                />
            </Box> 
            <DataGrid 
                rows={_projects.value || []}
                columns={_columns}
                getRowId={(project:IProjectQuckInfo) => project.guid}
                localeText={_gridLocaleText}
                className={styles.dataGrid}
                rowSpacingType={'margin'}
                getRowSpacing={(params) => ({
                    top: 0,
                    bottom: 0
                })}
                sx={_hiddenScrollbarSX}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: { 
                        paginationModel: { 
                            pageSize: 10 
                        } 
                    },
                    sorting: {
                        sortModel: [{ 
                            field: g_getNameOf(EMPTY_PROJECT, o => o.generationIsoDate), 
                            sort: 'desc' 
                        }],
                    },
                }}
                pagination
            />
            
            <DraggableDialog
                isOpen={!!_deleteDialogProject.value}
                onClose={() => {
                    _deleteDialogProject.set(null);
                }}
                title={_translations?.phrases.deleteProjectDialogHeading}
                actions={
                    <>
                        <Button 
                            onClick={() => {
                                _msalDeleteProjcts.execute('DELETE', g_protectedResources.apiDeleteProject.endpoint, _deleteDialogProject.value, true).then((deletedProject:IProjectQuckInfo) => {
                                    
                                    //removes the deleted project from the projects list
                                    const newProjects:IProjectQuckInfo[] = _projects.value?.filter((p:IProjectQuckInfo) => p.guid !== deletedProject.guid) || [];
                                    _projects.set(newProjects);

                                    //closes the delete if applicable
                                    if(deletedProject.guid === _deleteDialogProject.value?.guid) {
                                        _deleteDialogProject.set(null);
                                    }
                                });
                            }}
                            disabled={_msalDeleteProjcts.isLoading.value}
                        >
                            { _translations?.phrases.deleteBtnLabel }
                        </Button> 
                        <Button 
                            autoFocus 
                            onClick={() => _deleteDialogProject.set(null)}
                            variant='contained'
                        >
                            { _translations?.phrases.cancelBtnLabel }
                        </Button>                       
                    </>
                }
            >
                { 
                    Format.text(
                        _translations?.phrases.deleteProjectWarning,
                        _deleteDialogProject.value?.projectName,
                        _deleteDialogProject.value?.projectAddress
                    ) 
                }
            </DraggableDialog>      
        </>
    )
}
import styles from './AppIcons.module.scss';
import { IAppIconsProps } from './IAppIconsProps';

export function Waves(props:IAppIconsProps) {
    return (
        <svg 
            style={{
                width: props.width || '1.95em',
                ...(props.style || {})
            }}
            viewBox="0 0 32 32" 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill={props.color || '#000'}  d="M0 28v4q2.496 0 4.256-1.76t1.76-4.224v-20q0-0.832 0.576-1.408t1.408-0.608 1.408 0.608 0.608 1.408v20q0 2.496 1.728 4.224t4.256 1.76 4.256-1.76 1.76-4.224v-20q0-0.832 0.576-1.408t1.408-0.608 1.408 0.608 0.608 1.408v20q0 2.496 1.728 4.224t4.256 1.76v-4q-0.832 0-1.408-0.576t-0.576-1.408v-20q0-2.496-1.76-4.256t-4.256-1.76-4.256 1.76-1.728 4.256v20q0 0.832-0.608 1.408t-1.408 0.576-1.408-0.576-0.576-1.408v-20q0-2.496-1.76-4.256t-4.256-1.76-4.256 1.76-1.728 4.256v20q0 0.832-0.608 1.408t-1.408 0.576z"></path>
        </svg>
    )
}
import {
    useState,
    useCallback,
} from 'react';

import { AccountInfo, InteractionType, PopupRequest } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { g_msalConfig } from '../authConfig';
import { g_delay } from '../helpers/generalHelpers';
import { IRefState, useRefState } from './useRefState';
import { g_errorToast } from '../helpers/toastHelper';
import { g_parses } from '../translations/Translations';


/**
 * If TRUE shows that this instance has the localhost enviroment setup
 */
const IS_LOCAL:boolean = process.env.REACT_APP_LOCATION === 'localhost';

export interface IFectchResult {
    /**
     * If TRUE shows that the object is doing a query ATM
     */
    isLoading:IRefState<boolean>,
    /**
     * The error object of the fetch if in error
     */
    error: IRefState<any>,
    /**
     * The data gotten from the fetch
     */
    data: Object,
    /**
     * Execute a fetch request with the given options
     * @param method: GET, POST, PUT, DELETE
     * @param endpoint: The endpoint to call
     * @param data: The data to send to the endpoint, if any 
     * @param doSiglentFastFail If TRUE the too fast fail will be siglent
     * @returns JSON response
     */
    execute:(method:string, endpoint:string, data?:Object|null, doSiglentFastFail?:boolean) => Promise<any>
}

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest Used to give the extra MSAL objects needed like "scopes" array
 */
const _useFetchWithMsal = (scopes:string[]):IFectchResult => {
    const _msal = useMsal();
    const _isLoading:IRefState<boolean> = useRefState(false);
    const _error:IRefState<any> = useRefState<any>(null);
    const [_data, _setData] = useState<any>(null);

    const ret:IFectchResult = {
        isLoading: _isLoading,
        error: _error,
        data: _data,
        execute: async (method:string, endpoint:string, data:Object|null = null):Promise<any> => undefined
    };    

    const _auth = useMsalAuthentication(InteractionType.Redirect, {
        scopes,
        account: _msal.instance.getActiveAccount() as AccountInfo,
        redirectUri: g_msalConfig.auth.redirectUri
    });
    const _result = _auth.result;
    const _msalError = _auth.error;

    // console.error('_auth & scopes', _auth, scopes);

    /**
     * Execute a fetch request with the given options
     * @param method: GET, POST, PUT, DELETE
     * @param endpoint: The endpoint to call
     * @param data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const _fnMsalExecute = async (method:string, endpoint:string, data:Object|null = null, doSiglentFastFail?:boolean):Promise<any> => {

        try {

            if(_msalError) {
                throw _msalError;
            }

            if(_result) {
                    const headers = new Headers();
                    const bearer = `Bearer ${_result.accessToken}`;            
                    headers.append("Authorization", bearer);

                    if (data) headers.append('Content-Type', 'application/json');

                    let options = {
                        method: method,
                        headers: headers,
                        body: data ? JSON.stringify(data) : null,
                    };

                    //does the request
                    _isLoading.set(true);
                    const fetchResult:Response = await fetch(endpoint, options);
                    const responseText:string = await fetchResult.text();
                    _isLoading.set(false);

                    //parses the result
                    try {
                        const response = JSON.parse(responseText);

                        //for error
                        if(!fetchResult.ok) {                
                            throw response;
                        } 
                        //for success
                        else {
                            _setData(response);
                            _error.set(null);
                        }

                        return response;
                    } catch(e) {
                        throw `Could not parse respone: ${responseText}`;
                    }
            }

            if(!doSiglentFastFail) {
                console.warn(`_useFetchWithMsal() - Too Fast Request, no auth error nor result: ${method} ${endpoint}`, data);
            }

        } catch (e) {
            _error.set(e as any);
            _setData(null);
            g_errorToast(g_parses.unknownFetchErrorMessage);
            throw e;
        }
    };

    ret.execute = useCallback(_fnMsalExecute, [_result, _msalError]); // to avoid infinite calls when inside a `useEffect`    

    return ret;
};

/**
 * Custom hook to call a web API using the same structure as MSAL fetch
 * @param {PopupRequest} msalRequest Used to give the extra MSAL objects needed like "scopes" array
 */
const _useFetch = (scopes:string[]):IFectchResult => {
    const _msal = useMsal();
    const _isLoading:IRefState<boolean> = useRefState(false);
    const _error:IRefState<any> = useRefState<any>(null);
    const [_data, _setData] = useState<any>(null);

    const ret:IFectchResult = {
        isLoading: _isLoading,
        error: _error,
        data: _data,
        execute: async (method:string, endpoint:string, data:Object|null = null):Promise<Object|undefined> => undefined
    };

    /**
     * Execute a fetch request with the given options
     * @param method: GET, POST, PUT, DELETE
     * @param endpoint: The endpoint to call
     * @param data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const _fnFetchExecute = async (method:string, endpoint:string, data:Object|null = null, doSiglentFastFail?:boolean):Promise<Object|undefined> => {
        try {
            const headers = new Headers();
            if (data) headers.append('Content-Type', 'application/json');

            let options = {
                method: method,
                headers: headers,
                body: data ? JSON.stringify(data) : null,
            };

            //does the request
            _isLoading.set(true);
            const fetchResult:Response = await fetch(endpoint, options);
            const responseText:string = await fetchResult.text();
            _isLoading.set(false);

            //parses the request
            try {
                const response = JSON.parse(responseText);                

                //for error
                if(!fetchResult.ok) {              
                    throw response;
                } 
                //for success
                else {
                    _setData(response);
                    _error.set(null);
                }

                return response;
            } catch(e) {          
                throw `Could not parse respone: ${responseText}`;
            }
            
        } catch(e) {
            _error.set(e as any);
            _setData(null);
            g_errorToast(g_parses.unknownFetchErrorMessage);
            throw e;
        }
    };

    ret.execute = _fnFetchExecute;

    return ret;
};

export default (IS_LOCAL ? _useFetch : _useFetchWithMsal);
import * as React from 'react';
import styles from './Welcome.module.scss';

import { IState } from '../../react-hooks/useState';
import { FooterContext } from '../../App';
import { CultureContext, EE_CULTURE, EN_CULTURE } from '../../helpers/cultureHelper';
import { Welcome_etEE } from './Welcome_etEE/Welcome_etEE';
import { Welcome_enGB } from './Welcome_enGB/Welcome_enGB';
import { WelcomeFooter } from './WelcomeFooter/WelcomeFooter';

/**
 * Renders out the Welcome page
 */
export function Welcome(props:{}) {

    const _footer:IState<React.ReactNode>|null = React.useContext(FooterContext);
    const _culture:IState<string>|null = React.useContext(CultureContext);
    
    //clears the footer of the page
    React.useEffect(() => {
        _footer?.set(<WelcomeFooter />);
    }, []);

    switch(_culture?.value) {        
        case EE_CULTURE:
            return <Welcome_etEE />;
        default:
        case EN_CULTURE:
            return <Welcome_enGB />;
    }
}
import * as React from 'react';
import styles from './CoolingCircuts.module.scss';

import { AppSlider } from '../../AppSlider/AppSlider';
import { 
    useForm,
    ICoolingCircutsValues,
    IUseFormHookResult,
    ICircutInErrorValues,
    IGeneralInfoValues,
    IHeatingCircutsValues
} from '../FormContext';
import { CoolingCircut } from '../CoolingCircut/CoolingCircut';
import { Alert, Box, Typography } from '@mui/material';
import { IState, useState } from '../../../react-hooks/useState';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { 
    CoolingInnerPartsIcon, 
    VentilationCoolingIcon,
    FloorHeatingIcon,
    VentilationHeatingIcon,
    ExtraCircleHeatingIcon,
    ExtraCircleWithoutValveHeatingIcon

} from '../Icons';
import { CoolingType, IWetCoolingCircut, IDryCoolingCircut, IHeatingCircut } from '../IProjectData';
import * as _ from 'lodash';
import { DryCoolingCircut } from '../DryCoolingCircut/DryCoolingCircut';
import { PumpWarning } from '../PumpWarning/PumpWarning';

/**
 * Renders out the cooling circuts selection
 */
export function CoolingCircuts(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:ICoolingCircutsValues = _form.data.coolingCircuts.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _general:IGeneralInfoValues = _form.data.generalInfo.values;    

    const _operatingRange:IState<number[]> = useState<number[]>([_data.min, _data.max]);

    React.useEffect(() => {
        if(_operatingRange.value[0] !== _data.min || _operatingRange.value[1] !== _data.max) {
            _operatingRange.set([_data.min, _data.max]);
        }
    }, [_data.min, _data.max]);



    let _totalCoolingLoad:number = 0;
    let _isOperatingRangeDisabled:boolean = false;
    let _coolingOptions:React.ReactNode;
    let _operatingRangeMin:number = 7; 
    let _operatingRangeMax:number = 25; 

    switch(_general.coolingType) {
        case CoolingType.Wet: {

            //calculates the total cooling load
            if(_data.coolingInnerParts.isEnabled) {        
                _totalCoolingLoad += _data.coolingInnerParts.heatingLoad || 0;
            }
            if(_data.ventilationCoolingBatteries.isEnabled) {        
                _totalCoolingLoad += _data.ventilationCoolingBatteries.heatingLoad || 0;
            }

            _isOperatingRangeDisabled = (
                !_data.coolingInnerParts.isEnabled
                && !_data.ventilationCoolingBatteries.isEnabled
            );
            _coolingOptions = (
                <>
                    <CoolingCircut
                        icon={<CoolingInnerPartsIcon />}
                        label={_translations?.phrases.coolingInnerParts}
                        value={_data.coolingInnerParts}
                        onChange={(newValue:IWetCoolingCircut) => {
                            const newData:Partial<ICoolingCircutsValues> = {
                                coolingInnerParts: newValue
                            };
                            _form.setCoolingCircuts(newData);
                        }}
                        maxTemp={_operatingRangeMax}
                        minTemp={_operatingRangeMin}
                        inErrorValues={_data.coolingInnerPartsErrors}
                        onHeatloadBlur={() => {
                            const errors:ICircutInErrorValues = _.cloneDeep(_data.coolingInnerPartsErrors);
                            errors.isHeatloadInError = !_data.coolingInnerParts.heatingLoad;
                            const newData:Partial<ICoolingCircutsValues> = {
                                coolingInnerPartsErrors: errors
                            };
                            _form.setCoolingCircuts(newData);
                        }}
                        onResistanceBlur={() => {
                            const errors:ICircutInErrorValues = _.cloneDeep(_data.coolingInnerPartsErrors);
                            errors.isResistanceInError = !_data.coolingInnerParts.resistance;
                            const newData:Partial<ICoolingCircutsValues> = {
                                coolingInnerPartsErrors: errors
                            };
                            _form.setCoolingCircuts(newData);
                        }}
                    />
                    <CoolingCircut
                        icon={<VentilationCoolingIcon />}
                        label={_translations?.phrases.ventilationCoolingBatteries}
                        value={_data.ventilationCoolingBatteries}
                        onChange={(newValue:IWetCoolingCircut) => {
                            const newData:Partial<ICoolingCircutsValues> = {
                                ventilationCoolingBatteries: newValue
                            };
                            _form.setCoolingCircuts(newData);
                        }}
                        maxTemp={_operatingRangeMax}
                        minTemp={_operatingRangeMin}
                        inErrorValues={_data.ventilationCoolingBatteriesErrors}
                        onHeatloadBlur={() => {
                            const errors:ICircutInErrorValues = _.cloneDeep(_data.ventilationCoolingBatteriesErrors);
                            errors.isHeatloadInError = !_data.ventilationCoolingBatteries.heatingLoad;
                            const newData:Partial<ICoolingCircutsValues> = {
                                ventilationCoolingBatteriesErrors: errors
                            };
                            _form.setCoolingCircuts(newData);
                        }}
                        onResistanceBlur={() => {
                            const errors:ICircutInErrorValues = _.cloneDeep(_data.ventilationCoolingBatteriesErrors);
                            errors.isResistanceInError = !_data.ventilationCoolingBatteries.resistance;
                            const newData:Partial<ICoolingCircutsValues> = {
                                ventilationCoolingBatteriesErrors: errors
                            };
                            _form.setCoolingCircuts(newData);
                        }}
                    /> 
                </>
            )
            break;
        }
        case CoolingType.Dry: {
            _isOperatingRangeDisabled = (
                !_data.dryCoolingFloor.isEnabled
                && !_data.dryCoolingVentilation.isEnabled
                && !_data.dryCoolingExtra.isEnabled
                && !_data.dryCoolingExtraWithoutValve.isEnabled
            );            
            const heatingCircuts:IHeatingCircutsValues = _form.data.heatingCircuts.values;

            if(_data.dryCoolingFloor) {
                _totalCoolingLoad += _data.dryCoolingFloor.coolingLoad || 0;
            }
            if(_data.dryCoolingVentilation) {
                _totalCoolingLoad += _data.dryCoolingVentilation.coolingLoad || 0;
            }
            if(_data.dryCoolingExtra) {
                _totalCoolingLoad += _data.dryCoolingExtra.coolingLoad || 0;
            }
            if(_data.dryCoolingExtraWithoutValve) {
                _totalCoolingLoad += _data.dryCoolingExtraWithoutValve.coolingLoad || 0;
            }

            _operatingRangeMin = 18;
            _operatingRangeMax = 24;

            _coolingOptions = (
                <>
                    <Box className={styles.temperatureRange}>
                        <AppSlider
                            min={_operatingRangeMin}
                            max={_operatingRangeMax}
                            initial={_operatingRange.value}
                            label={_translations?.phrases.operatingRange}
                            onChange={(newValues:number|number[]) => {
                                const minMax:number[] = newValues as number[];
                                const newData:Partial<ICoolingCircutsValues> = {
                                    min: minMax[0],
                                    max: minMax[1]
                                };
                                _form.setCoolingCircuts(newData);                    
                            }}
                            isDisabled={_isOperatingRangeDisabled}
                        />
                    </Box>
                    <DryCoolingCircut
                        heatingCircut={heatingCircuts.floor}
                        coolingCircut={_data.dryCoolingFloor}
                        label={_translations?.phrases.floorHeating}
                        icon={FloorHeatingIcon} 
                        onChange={(circut:IDryCoolingCircut):void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingFloor: circut
                            };
                            _form.setCoolingCircuts(newValues)
                        }} 
                        onCoolingLoadBlur={():void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingFloor: _.cloneDeep(_data.dryCoolingFloor)
                            };
                            newValues.dryCoolingFloor!.isCoolingLoadInError = !newValues.dryCoolingFloor?.coolingLoad;
                            _form.setCoolingCircuts(newValues);
                        }}
                    />
                    <DryCoolingCircut
                        heatingCircut={heatingCircuts.ventilation}
                        coolingCircut={_data.dryCoolingVentilation}
                        label={_translations?.phrases.ventilationHeating}
                        icon={VentilationHeatingIcon} 
                        onChange={(circut:IDryCoolingCircut):void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingVentilation: circut
                            };
                            _form.setCoolingCircuts(newValues)
                        }} 
                        onCoolingLoadBlur={():void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingVentilation: _.cloneDeep(_data.dryCoolingVentilation)
                            };
                            newValues.dryCoolingVentilation!.isCoolingLoadInError = !newValues.dryCoolingVentilation?.coolingLoad;
                            _form.setCoolingCircuts(newValues);
                        }}
                    />
                    <DryCoolingCircut
                        heatingCircut={heatingCircuts.extraWithoutValve}
                        coolingCircut={_data.dryCoolingExtraWithoutValve}
                        label={heatingCircuts.extraCircutWithoutValveLabel ||  _translations?.phrases.extraCircleWithoutValveHeating}
                        icon={ExtraCircleWithoutValveHeatingIcon} 
                        onChange={(circut:IDryCoolingCircut):void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingExtraWithoutValve: circut
                            };
                            _form.setCoolingCircuts(newValues)
                        }} 
                        onCoolingLoadBlur={():void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingExtraWithoutValve: _.cloneDeep(_data.dryCoolingExtraWithoutValve)
                            };
                            newValues.dryCoolingExtraWithoutValve!.isCoolingLoadInError = !newValues.dryCoolingExtraWithoutValve?.coolingLoad;
                            _form.setCoolingCircuts(newValues);
                        }}
                    />
                    <DryCoolingCircut
                        heatingCircut={heatingCircuts.extra}
                        coolingCircut={_data.dryCoolingExtra}
                        label={heatingCircuts.extraCircutLabel || _translations?.phrases.extraCircleHeating}
                        icon={ExtraCircleHeatingIcon} 
                        onChange={(circut:IDryCoolingCircut):void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingExtra: circut
                            };
                            _form.setCoolingCircuts(newValues)
                        }} 
                        onCoolingLoadBlur={():void => {
                            const newValues:Partial<ICoolingCircutsValues> = {
                                dryCoolingExtra: _.cloneDeep(_data.dryCoolingExtra)
                            };
                            newValues.dryCoolingExtra!.isCoolingLoadInError = !newValues.dryCoolingExtra?.coolingLoad;
                            _form.setCoolingCircuts(newValues);
                        }}
                    />
                </>
            );

            break;
        }
    }

    return (
        <> 
            <Typography variant='sectionHeading'>{_translations?.phrases.coolingCircutsSectionHeading}</Typography>
            <PumpWarning />
            {
                (_totalCoolingLoad > (_general.coolingLoad || 0)) &&
                <Alert severity="warning">
                    { _translations?.phrases.combinedCoolingLoadBiggerThanProject }
                </Alert>
            }             
            { _coolingOptions }         
        </>
    )
}
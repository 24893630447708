import { ITranslations } from "./ITranslations";

export const g_etEE:ITranslations = {
    projectInfoSectionHeading: 'Projekti algandmed',
    heatingLoadSectionHeading: 'Küttekoormus',
    heatingCircutsSectionHeading: 'Küte',
    coolingCircutsSectionHeading: 'Jahutus',
    extrasSectionHeading: 'Lisad',
    heatpumpSectionHeading: 'Soojuspump',
    tankSelectionSectionHeading: 'Boiler ja akumulatsiooni paak',
    
    optionalMarker: 'Valikuline',

    projectName: 'Projekti nimi',    
    projectAddress: 'Aadress',
    projectClimateZone: 'Kliimatsoon',
    projectHeatingArea: 'Köetav pind',

    coolingLoadLabel: "Jahutuskoormus",
    isCoolingEnabledLabel: "Jahutusega projekt",
    coolingTypeRadioGroupLabel: "Jahutuse valik",
    coolingTypeRadioNoneLabel: "Pole vaja",
    coolingTypeRadioWetLabel: "Märg (alates +7°C)",
    coolingTypeRadioDryLabel: "Kuiv (alates +18°C)",

    menuBarExpand: "Näita rohkem",
    menuBarCollapse: "Peida",

    dragAndDropTitle: "Lohista siia logo (PNG, SVG, JPG, GIF), 498x186px mõõtmetes või kliki, et laadida logo",
    dragAndDropDragText: "Lase logo lahti",
    dragAndDropPrompt: "Lohista siia logo või kliki, et laadida logo",
    dragAndDropSubPrompt: "489x186px (PNG, SVG, JPG, GIF)",

    profileCompanyLabel: "Ettevõte",
    profilePhoneLabel: "Telefon",

    //title block settings
    projectDrafterLabel: "Projekteerija",
    projectManagerLabel: "Projektijuht",
    accountableProjectDrafterLabel: "Vastutav spetsialist",
    projectNumberLabel: "Projekti number",
    projectClientLabel: "Klient",

    newBuilding: 'Uusehitis',
    renovatedHouse: 'Rekonstrueerimine',
    selectRenovatedHeatloadsubheading: 'Sisesta kütteliigi kulu aastas',    

    roomTemperature: 'Toatemperatuur',
    balanceTemperature: 'Tasakaalu temperatuur',
    heatingLoadLoss: 'Küttekoormus',
    electricityConsumptionPerYear: 'Elekter',
    gasConsumptionPerYear: 'Gaas',
    pelletConsumptionPerYear: 'Pellet',
    oilConsumptionPerYear: 'Õli',
    woodConsumptionPerYear: 'Puud',
    propaineConsumptionPerYear: 'Propaan',

    floorHeating: 'Põrandküte',
    radiatorHeating: 'Radiaatorküte',
    ventilationHeating: 'Ventilatsiooni kalorifeerküte',
    poolHeating: 'Basseiniküte',
    extraCircleWithoutValveHeating: 'Lisaring segusõlmeta',
    extraCircleHeating: 'Lisaring segusõlmega',

    heatingCircutHeatingLoad: 'Küttekoormus',
    heatingCircutResistance: 'Kogutakistus',
    heatingCircutDN: 'DN',
    coolingCircutHeatingLoad: "Jahutuskoormus",



    operatingRange: 'Jahutusgraafik',
    coolingInnerParts: 'Jahutuse siseosad',
    ventilationCoolingBatteries: 'Ventilatsiooni jahutuskalorifeer',

    recirculation: 'Tarbevee retsirkulatsioon',
    floorHeating3WayValve: '3-T ventiil',
    extraHeatingSource: 'Lisa soojusallikas',

    dimensioning: 'Dimensioneerimine',
    integratedBoilerPumpGroupName: 'Integ. boileriga',
    externalBoilerPumpGroupName: 'Välise boileriga',
    compactPumpGroupName: 'Kompakt',
    outdoorPumpGroupName: 'Välispaigaldusega',
    indoorPumpGroupName: 'Sisepaigaldusega',
    cascadePumpGroupName: 'Kaskaad',
    useCheckboxLabel: 'Vali soojuspump',
    groundpump: 'Maasoojuspump',
    airpump: 'Õhk-vesi soojuspump',



    earthContureLabel: '{0} W/jm - {1}',
    earthContureLength: 'Maakontuuri pikkus',
    earthContureLengthLabel: '{0}: {1}m',
    automatic: 'Automaatne',
    manual: 'Muuda käsitsi',
    circleLength: 'Ringi pikkus',
    nrOfContures: 'Kontuuride arv',
    earthTypeWater: 'Vesi',
    earthTypeOveryWetSoil: 'Liigniiske pinnas',
    earthTypeWetClay: 'Niiske savine pinnas',
    earthTypeDryClay: 'Kuiv savikas pinnas',
    earthTypeWetSandy: 'Niiske liivane pinnas',
    earthTypeDrySandy: 'Kuiv liivane pinnas',

    heatpumpSubTypeInverter: 'Inverter',
    heatpumpSubTypeOnOff: 'ON/OFF',
    heatpumpSubTypeInverterMonoblock: 'Inverter Monoblock',
    heatpumpSubTypeSplitHM: 'Inverter Split',
    heatpumpSubTypeSplitHT: 'Inverter Split',
    heatpumpSubTypeOnOffMonoblock: 'ON/OFF Monoblock',

    accuTank: 'Akumulatsiooni paak',
    coolingAccuTank: 'Jahutuse akumulatsiooni paak',
    boiler: 'Boiler',
    noBoiler: 'Ära lisa boilerit',
    noHeatingElementNeeded: 'Ära lisa küttekeha',
    heatingElement: 'Küttekeha',

    nextBtnLabel: 'Edasi',
    previousBtnLabel: 'Tagasi',
    generateBtnLabel: 'Loo projekt',

    heatingCoolingCircutPreviewUnit: '{0}-{1}°C',
    noHeatpumpToSelectWarning: 'Vabandame, kuid hetkel puuduvad pumbad, mis vastavad nõutud küttekoormusele.',
    generationResultsSectionHeating: 'Projekti failid',
    readMoreBtn: 'Tehnilised andmed',

    downloadDfxBtnLabel: "Lae alla DXF",
    downloadDfxToolip: "Lae joonis alla Autocadi formaadis(DXF)",
    homeBtn: "Tagasi töölauale",
    downloadPdfBtnLabel: "Lae alla PDF",
    downloadPdfTooltip: "Lae joonis alla PDF formaadis",

    downloadExcelBtnLabel: "Lae alla Excel",
    downloadExcelTooltip: "Lae alla projekti spetsifikatsioon Exceli formaadis",

    saveChangesBtnLabel: "Salvesta muudatused",
    backToHomeWarningTitle: "Muudatusi pole salvestatud",
    backToHomeWarningContent: "Oled kindel, et soovid minna tagasi töölauale?",

    //manuals 
    manualsButtonText: "Juhendid",
    manualsButtonTooltip: "Vali juhend mida soovid alla laadida",
    installationManual: "Paigaldus",
    technicalnManual: "Tehniline",
    declarationManual: "Deklaratsioon",
    automationManual: "Automaatika",
    productLineManual: "Toote liin",

    //hetpump technical details
    hasElementIndicator: "Komplektis",
    doesNotHaveElementIndicator: "Ei kuulu komplekti",
    domesticHotWaterABValveLabel: "Tarbevee AB klapp",
    heatingCircuitPumpLabel: "Kütte tsirkulatsioonipump",
    brineCircuitPumpLabel: "Maakontuuri tsirkulatsioonipump",
    maxInletTempLabel: "Maks. pealevoolu temp",
    maxInletTempValue: "{0}°C",
    integratedHeatingElementLabel: "Integreeritud küttekeha",
    integratedHeatingElementValue: "{0}kW",
    integratedBoilerLabel: "Integreeritud boiler",
    integratedBoilerValue: "{0}l",
    compressorLabel: "Kompressor",
    powerLabel: "Võimsus",
    compressorTypeInverter: "Inverter",
    compressorTypeOnOff: "ON/OFF",
    compressorTypeDualCompressorOnOff: "ON/OFF",
    dualCompressorPowerValue: "{0}kW / {1}",
    powerValue: "{0}kW",
    inverterPowerValue: "{0}-{1}kW",
    tooltipLabelValue: "{0}: {1}",

    // Tanks tech details
    coilLabel: "Siug",
    heightLabel: "Kõrgus",
    widthLabel: "Diameeter",

    //error messages
    requiredFieldErrorMessage: "Kohustuslik väli",
    nonNumbericInputErrorMessage: "Ainult numbrid lubatud",
    noHeatingCircutSelectedErrorMessage: "Vähemalt üks kütteringidest peab olema valitud!",
    noCoolingCircutsSelectedErrrorMessage: "Vähemalt üks jahutuseringidest peab olema valitud!",
    noHeatpumpSelectedErrorMessage: "Soojuspumpa pole valitud!",
    combinedHeatloadBiggerThanProject: "Kombineeritud küttekoormus on suurem, kui sai määratud projekti algandmetes.",
    combinedCoolingLoadBiggerThanProject: "Kombineeritud jahutuskoormus on suurem, kui sai määratud projektis algandmetes.",

    successfulyGeneratedMessage: "Projekt \"{0}\" on loodud",
    successfulySavedMessage: "Projekt \"{0}\" on salvestatud",
    successfullyClonedMessage: "Projekt \"{0}\" on kloonitud",
    longGenerationMessage: "Projekti loomine on töös, palun oodake!",

    unknownFetchErrorMessage: "Toimus viga, palun proovige uuesti. Kui viga jätkub teatage sellest toesse.",
    noSuitablePumpsErrorMessage: "Puudub sobiv pump sisestatud parameetrite jaoks.",

    // Dashboard 
    heatPumpPhrase: "Soojuspump",
    dashboardDate: "Kuupäev",
    actionsHeading: "Tegevused",

    deleteBtnLabel: "Kustuta",
    cancelBtnLabel: "Tühista",
    deleteProjectBtnTooltip: "Kustuta",
    cloneProjectBtnTooltip: "Tee koopia",
    viewProjectBtnTooltip: "Ava",
    deleteProjectDialogHeading: "Kustuta projekt",
    addNewProjectBtnLabel: "Loo uus projekt",
    deleteProjectWarning: "Oled kindel, et soovid kustutada projekti? {0}",

    editProfile: "Muuda andmeid",
    resetPassword: "Muuda salasõna",
    noProjectsYet: "Hetkel pole ühtki projekti loodud, palun vajutage \"+\" nupule, et luua uus projekt",

    //profile avatar
    settingsMenuLabel: "Profiili seaded",
    settingPanelHeading: "Profiili seaded",
    profileNameLabel: "Nimi",
    titleBlockSettingsHeading: "Projekti kirjanurk",
    settingsProjectDrafterLabel: "Projekteerija",
    settingsProjectManagerLabel: "Projektijuht",
    settingsAccountableProjectDrafterLabel: "Vastutav spetsialist",
    settingsProjectNumberLabel: "Projekti number",
    settingsSaveSettingsBtnLabel: "Säilita seaded",

    // Pre-login welcome
    preLoginWelcome: "Sisene AlphaPRO keskkonda kasutades LOGI SISSE nuppu",
    logInButton: "Logi sisse",
    logOutButton: "Logi välja",

    // Root
    noRowsLabel: 'Pole ridu',
    noResultsOverlayLabel: 'Tulemusi ei leitud.',
    
    // Density selector toolbar button text
    toolbarDensity: 'Tihedus',
    toolbarDensityLabel: 'Tihedus',
    toolbarDensityCompact: 'Kompaktne',
    toolbarDensityStandard: 'Standartne',
    toolbarDensityComfortable: 'Mugav',
    
    // Columns selector toolbar button text
    toolbarColumns: 'Tulbad',
    toolbarColumnsLabel: 'Vali tulbad',
    
    // Filters toolbar button text
    toolbarFilters: 'Filtrid',
    toolbarFiltersLabel: 'Näita filtreid',
    toolbarFiltersTooltipHide: 'Peida filtrid',
    toolbarFiltersTooltipShow: 'Näita filtreid',
    toolbarFiltersTooltipActiveSingle: '{0} aktiivne filter',
    toolbarFiltersTooltipActiveMultiple: '{0} aktiivset filtrit',
        
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Otsi…',
    toolbarQuickFilterLabel: 'Otsi',
    toolbarQuickFilterDeleteIconLabel: 'Tühista',
    
    // Export selector toolbar button text
    toolbarExport: 'Ekspordi',
    toolbarExportLabel: 'Eksport',
    toolbarExportCSV: 'Lae alla CSV',
    toolbarExportPrint: 'Prindi',
    toolbarExportExcel: 'Lae alla Excel',
    
    // Columns panel text
    columnsPanelTextFieldLabel: 'Leia tulp',
    columnsPanelTextFieldPlaceholder: 'Tulba pealkiri',
    columnsPanelDragIconLabel: 'Muuda tulba asupaika',
    columnsPanelShowAllButton: 'Näita kõiki',
    columnsPanelHideAllButton: 'Peida kõik',
    
    // Filter panel text
    filterPanelAddFilter: 'Lisa filter',
    filterPanelRemoveAll: 'Eemalda kõik',
    filterPanelDeleteIconLabel: 'Kustuta',
    filterPanelLogicOperator: 'Loogika',
    filterPanelOperator: 'Operaator',
    filterPanelOperatorAnd: 'Ja',
    filterPanelOperatorOr: 'Või',
    filterPanelColumns: 'Tulbad',
    filterPanelInputLabel: 'Väärtus',
    filterPanelInputPlaceholder: 'Filtri väärtus',
    
    // Filter operators text
    filterOperatorContains: 'sisaldab',
    filterOperatorEquals: 'võrdub',
    filterOperatorStartsWith: 'algab sedasi',
    filterOperatorEndsWith: 'lõppeb sedasi',
    filterOperatorIs: 'on',
    filterOperatorNot: 'ei ole',
    filterOperatorAfter: 'on hiljem',
    filterOperatorOnOrAfter: 'on selle peal või hiljem',
    filterOperatorBefore: 'on ennem',
    filterOperatorOnOrBefore: 'on selle peal või varem',
    filterOperatorIsEmpty: 'on tühi',
    filterOperatorIsNotEmpty: 'ei ole tühi',
    filterOperatorIsAnyOf: 'on suvaline nendest',
    filterOperatorEqual: '=',
    filterOperatorNotEqual: '!=',
    filterOperatorGratherThen: '>',
    filterOperatorGratherThenEqual: '>=',
    filterOperatorLessThen: '<',
    filterOperatorLessThenEqual: '<=',
    
    // Header filter operators text
    headerFilterOperatorContains: 'Sisaldab',
    headerFilterOperatorEquals: 'Võrdub',
    headerFilterOperatorStartsWith: 'Algab sellega',
    headerFilterOperatorEndsWith: 'Lõppeb sellega',
    headerFilterOperatorIs: 'On',
    headerFilterOperatorNot: 'Ei ole',
    headerFilterOperatorAfter: 'On hiljem',
    headerFilterOperatorOnOrAfter: 'On selle peal või hiljem',
    headerFilterOperatorBefore: 'On ennem',
    headerFilterOperatorOnOrBefore: 'On selle peal või varem',
    headerFilterOperatorIsEmpty: 'On tühi',
    headerFilterOperatorIsNotEmpty: 'Ei ole tühi',
    headerFilterOperatorIsAnyOf: 'On suvaline nendest',
    headerFilterOperatorEqual: 'Võrdub',
    headerFilterOperatorNotEqual: 'Ei võrdu',
    headerFilterOperatorGratherThen: 'Suurem kui',
    headerFilterOperatorGratherThenEqual: 'Suurem või võrdne',
    headerFilterOperatorLessThen: 'Väiksem',
    headerFilterOperatorLessThenEqual: 'Väiksem või võrdne',
    
    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'tõene',
    filterValueFalse: 'väär',
    
    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Näita tulpi',
    columnMenuManageColumns: 'Halda tulpi',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Peida tulp',
    columnMenuUnsort: 'Ära sordi',
    columnMenuSortAsc: 'Sordi ASC',
    columnMenuSortDesc: 'Sordi DESC',
    
    // Column header text
    columnHeaderFiltersTooltipActiveSingle: '{0} aktiivne filter',
    columnHeaderFiltersTooltipActiveMultiple: '{0} aktiivset filtrit',    
    columnHeaderFiltersLabel: 'Näita filtrit',
    columnHeaderSortIconLabel: 'Sordi',
    
    // Rows selected footer text
    footerRowSelectedSingle: '{0} rida valitud',
    footerRowSelectedMultiple: '{0} rida valitud',
        
    // Total row amount footer text
    footerTotalRows: 'Read kokku:',
    
    // Total visible row amount footer text
    footerTotalVisibleRows: '{0}/{1}',
    
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Märkeruudu valik',
    checkboxSelectionSelectAllRows: 'Vali kõik read',
    checkboxSelectionUnselectAllRows: 'Tühista kõikide ridade valik',
    checkboxSelectionSelectRow: 'Vali rida',
    checkboxSelectionUnselectRow: 'Tühista rea valik',
    
    // Boolean cell text
    booleanCellTrueLabel: 'jah',
    booleanCellFalseLabel: 'ei',
    
    // Actions cell more text
    actionsCellMore: 'veel',
    
    // Column pinning text
    pinToLeft: 'Määra vasakule',
    pinToRight: 'Määra paremale',
    unpin: 'Tühista määrang',
    
    // Tree Data
    treeDataGroupingHeaderName: 'Grup',
    treeDataExpand: 'näe lapsi',
    treeDataCollapse: 'peida lapsed',
    
    // Grouping columns
    groupingColumnHeaderName: 'Grup',
    groupColumn: 'Grupeeri {0} järgi',
    unGroupColumn: 'Tühista grupeering {0} järgi',
    
    // Master/detail
    detailPanelToggle: 'Detail vaade',
    expandDetailPanel: 'Ava',
    collapseDetailPanel: 'Sulge',
        
    // Row reordering text
    rowReorderingHeaderName: 'Rea järjestus',
    
    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
};
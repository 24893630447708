import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, PaperProps } from "@mui/material";
import Draggable from "react-draggable";
import { IDraggableDialogProps } from './IDraggableDialogProps';
import * as React from 'react';


/**
 * The paper component of the dialog wrapped in a Draggable component.
 * Basically the comonent that gives a Paper surface a draggable functionality.
 */
function PaperComponent(props: PaperProps) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}
/**
 * Renders out a draggable dialog.
 * 
 */
export function DraggableDialog(props:React.PropsWithChildren<IDraggableDialogProps>) {

    const _content:React.ReactNode = !!props.children ? props.children : props.content;
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            PaperComponent={PaperComponent} 
            aria-labelledby="draggable-dialog-title"           
        >
            {
                !!props.title &&
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    { props.title }
                </DialogTitle>
            }
            {
                !!_content &&
                <DialogContent>
                    {
                        typeof _content === 'string' ? (
                            <DialogContentText>
                                { _content }
                            </DialogContentText>
                        ) : _content
                    }
                </DialogContent>
            }
            {
                !!props.actions &&
                <DialogActions>
                    { props.actions }
                </DialogActions>
            }
        </Dialog>
    )
}
import * as React from 'react';
import { ICalculatorProps } from './ICalculatorProps';
import { g_loginRequest} from '../../authConfig';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { CalculatorForm } from '../../components/CalculatorForm/CalculatorForm';
import { Loader } from '../../components/Loader/Loader';

/**
 * Renders out the calculator page
 */
export function Calculator(props:React.PropsWithChildren<ICalculatorProps>) {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={g_loginRequest}
            loadingComponent={Loader}
        >
            <CalculatorForm />
        </MsalAuthenticationTemplate>
    );
}
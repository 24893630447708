import { ILogoUploader } from "./ILogoUploaderProps";
import React, {useCallback} from 'react';
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import styles from './LogoUploader.styles.module.scss';
import { useState } from "../../react-hooks/useState";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ITranslationsContext, TranslationsContext } from "../../translations/TranslationsContext";
import { Close } from "@mui/icons-material";

const LOGO_WIDTH:number = 498;
const LOGO_HEIGHT:number = 186;
const SAMPLE_RATIO = 0.7;
const SAMPLE_RATIO_MULTIPLIER = 1 / SAMPLE_RATIO;

/**
 * Load in a logo and resizes it to 498x486px 
 */
export function LogoUploader(props:ILogoUploader) {
    /**
     * Holds the logo uploaded and re-sized to 498x186 as a base64 PNG
     */
    const _logoBase64 = useState<string|null>(null);
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    const onDrop = useCallback((acceptedFiles:File[]) => {

        // Do something with the files
        const file:File = acceptedFiles[0];

        const _resizeByWidth = (img:HTMLImageElement|HTMLCanvasElement):HTMLCanvasElement => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext("2d");
            const oc = document.createElement('canvas');
            const octx = oc.getContext('2d');

            //make the image with a solid white background
            const imgCanvas = document.createElement('canvas');
            const imgCanvasContext = imgCanvas.getContext("2d");

            imgCanvas.width = img.width;
            imgCanvas.height = img.height;

            imgCanvasContext!.fillStyle = "#fff";
            imgCanvasContext!.fillRect(0, 0, imgCanvas.width, imgCanvas.height);

            imgCanvasContext?.drawImage(img, 0, 0, img.width, img.height);
            img = imgCanvas;
            
            const targetWidth:number = LOGO_WIDTH * 0.80;

            //creates the white background image                
            if(img.width <= targetWidth) {
                canvas.width = img.width; // destination canvas size
                canvas.height = img.height;   
                
                //makes sure the logo always has a white background
                octx!.fillStyle = "#fff";
                octx!.fillRect(0, 0, canvas.width, canvas.height);

                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
            //resizes the image to width 498 if bigger than that
            else {
                
                canvas.width = targetWidth; // destination canvas size
                canvas.height = canvas.width * img.height / img.width;   
                
                //makes sure the logo always has a white background
                octx!.fillStyle = "#fff";
                octx!.fillRect(0, 0, canvas.width, canvas.height);
                
                var cur = {
                    width: Math.floor(img.width * SAMPLE_RATIO),
                    height: Math.floor(img.height * SAMPLE_RATIO)
                }
                
                oc.width = cur.width;
                oc.height = cur.height;
                
                octx?.drawImage(img, 0, 0, cur.width, cur.height);
                
                while (cur.width * SAMPLE_RATIO > targetWidth) {
                    cur = {
                        width: Math.floor(cur.width * SAMPLE_RATIO),
                        height: Math.floor(cur.height * SAMPLE_RATIO)
                    };                    
                    octx?.drawImage(oc, 0, 0, cur.width * SAMPLE_RATIO_MULTIPLIER, cur.height * SAMPLE_RATIO_MULTIPLIER, 0, 0, cur.width, cur.height);
                }
                
                ctx?.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
            }
            return canvas;
        };

        const _resizeByHeight = (img:HTMLImageElement|HTMLCanvasElement):HTMLCanvasElement => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext("2d");
            const oc = document.createElement('canvas');
            const octx = oc.getContext('2d');
            
            //make the image with a solid white background
            const imgCanvas = document.createElement('canvas');
            const imgCanvasContext = imgCanvas.getContext("2d");

            imgCanvas.width = img.width;
            imgCanvas.height = img.height;

            imgCanvasContext!.fillStyle = "#f00";
            imgCanvasContext!.fillRect(0, 0, imgCanvas.width, imgCanvas.height);

            imgCanvasContext?.drawImage(img, 0, 0, img.width, img.height);
            img = imgCanvas;

            const targetHeight:number = LOGO_HEIGHT * 0.8;

            //creates the white background image                
            if(img.width <= targetHeight) {
                canvas.width = img.width; // destination canvas size
                canvas.height = img.height;   

                //makes sure the logo always has a white background
                octx!.fillStyle = "#fff";
                octx!.fillRect(0, 0, canvas.width, canvas.height);

                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
            //resizes the image to width 498 if bigger than that
            else {
                
                canvas.height = targetHeight;   
                canvas.width = canvas.height * img.width / img.height; // destination canvas size
                
                //makes sure the logo always has a white background
                octx!.fillStyle = "#fff";
                octx!.fillRect(0, 0, canvas.width, canvas.height);
                
                var cur = {
                    width: Math.floor(img.width * SAMPLE_RATIO),
                    height: Math.floor(img.height * SAMPLE_RATIO)
                }
                
                oc.width = cur.width;
                oc.height = cur.height;
                
                octx?.drawImage(img, 0, 0, cur.width, cur.height);
                
                while (cur.height * SAMPLE_RATIO > targetHeight) {
                    cur = {
                        width: Math.floor(cur.width * SAMPLE_RATIO),
                        height: Math.floor(cur.height * SAMPLE_RATIO)
                    };                    
                    octx?.drawImage(oc, 0, 0, cur.width * SAMPLE_RATIO_MULTIPLIER, cur.height * SAMPLE_RATIO_MULTIPLIER, 0, 0, cur.width, cur.height);
                }
                
                ctx?.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
            }
            return canvas;
        };

        const reader = new FileReader();
        reader.onload = function() {
            
            const img = new Image();
            img.src = reader.result as string;            
            img.onload = function() {

                
                let canvas = _resizeByWidth(img);
                canvas = _resizeByHeight(canvas);

                //creates the actual logo to be passed along
                const logoCanvas:HTMLCanvasElement = document.createElement('canvas');
                const logoContext:CanvasRenderingContext2D = logoCanvas.getContext('2d')!;
                                
                logoCanvas.width = LOGO_WIDTH;                
                logoCanvas.height = LOGO_HEIGHT;

                logoContext.fillStyle = "#fff";
                logoContext.fillRect(0, 0, LOGO_WIDTH, LOGO_HEIGHT);

                //calculates the center position of the logo
                let posX:number = LOGO_WIDTH - canvas.width;
                posX = posX ? (posX / 2) : posX;
                let posY:number = LOGO_HEIGHT - canvas.height;
                posY = posY ? (posY / 2) : posY;

                logoContext.drawImage(canvas, posX, posY, canvas.width, canvas.height);
                _logoBase64.set(logoCanvas.toDataURL());
            }
        };
        reader.readAsDataURL(file);
    }, [])
    const options:DropzoneOptions = {
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpge': ['.jpg', '.jpeg'],
            'image/gif': ['.gif'],
            'image/svg+xml': ['.svg']
        },
        maxFiles: 1
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone(options);

    React.useEffect(() => {
        props.onUpload?.(_logoBase64.value);
    }, [_logoBase64.value]);

    return (        
        <div className={styles.container}>
            <Tooltip title={_translations?.phrases.dragAndDropTitle}>
                <div 
                    {...getRootProps()}                 
                    data-haslogo={!!(_logoBase64.value || props.url)}
                >
                    <Box 
                        className={styles.deleteButtonContainer}
                        onClick={(e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation();
                        }}
                    >
                        <Tooltip title={_translations?.phrases.deleteBtnLabel}>
                            <IconButton
                                onClick={() => {
                                    _logoBase64.set(null);
                                    props.onDelete?.();
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <input {...getInputProps()} />
                    <div className={styles.inner}>
                        {
                            isDragActive ? (
                                <Typography variant="body1">{_translations?.phrases.dragAndDropDragText}</Typography>
                            ) : (
                                <>
                                    {
                                        !!(_logoBase64.value || props.url)? (
                                            <img src={_logoBase64.value || props.url!} />
                                        ) : (
                                            <>
                                                <Typography variant="body1">{_translations?.phrases.dragAndDropPrompt}</Typography>
                                                <Typography variant="body2">{_translations?.phrases.dragAndDropSubPrompt}</Typography>
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                        }                
                    </div>
                </div>
            </Tooltip>
        </div>
    )
}
import * as React from 'react';
import styles from './Result.module.scss';

import { Loader } from '../../Loader/Loader';
import { IHeatpumpValuesForForm, IResultValues, IUseFormHookResult, useForm } from '../FormContext';
import useFetchWithMsal from '../../../react-hooks/useFetchWithMsal';
import { g_protectedResources } from '../../../authConfig';
import { IMsalContext, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { g_getActiveUser } from '../../../helpers/msalHelper';
import { IActiveAccount } from '../../../models/IActiveAccount';
import { IGenerationSuccess } from '../../../models/IGenerationSuccess';
import { Box, Button, Grid, Link, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Dataset, Download, PictureAsPdf } from '@mui/icons-material';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';

import { IState, useState } from '../../../react-hooks/useState';
import { SvgViewer } from '../../SvgViewer/SvgViewer';
import { DevicesContext, IDevices } from '../DevicesContext';
import { HeatpumpType, IProjectData, IResult } from '../IProjectData';
import { IBoiler } from '../IBoiler';
import { IDevice } from '../IDevice';
import { IAccuTank } from '../IAccuTank';
import { IHeatingElement } from '../IHeatingElement';
import { HeatpumpManualLinkType, IHeatpump, IHeatpumpManualLink } from '../IHeatpump';
import { g_successToast, g_warningToast } from '../../../helpers/toastHelper';
import { Format } from '../../../helpers/Format';
import * as _ from 'lodash';
import { GridColumns } from '../../GridColumns/GridColumns';
import { CultureContext } from '../../../helpers/cultureHelper';
import { ManualsButton } from '../ManualsButton/ManualsButton';

/**
 * The context used to tell the project with project is currently loaded in as the result
 */
export const ResultProjectContext = React.createContext<IState<IProjectData|null>|null>(null);

/**
 * The stats incrementation target i.e. shows with stat to increment
 */
enum IncrementStatsTargetType {
    Excel,
    Pdf,
    Dxf
}
/**
 * The payload used to increment the project stats
 */
interface IIncrementStatsPayload {
    /**
     * Tells the system with stat to update
     */
    target:IncrementStatsTargetType;
    /**
     * The project GUID of the project whom stat needs to be updated
     */
    projectGuid:string;
}

/**
 * Renders out the generation result
 */
export function Result(props:{}) {    
    const _form:IUseFormHookResult = useForm();
    const _data:IResultValues = _form.data.result.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _devices:IDevices|null = React.useContext(DevicesContext);
    
    const _msalFetchIncrementStats = useFetchWithMsal(g_protectedResources.apiIncrementStats.scopes.generator);
    const _msalFetchGeneratedFiles = useFetchWithMsal(g_protectedResources.apiCadGenerator.scopes.generator);
    const _msal:IMsalContext = useMsal();
    const _activeUser:IActiveAccount = g_getActiveUser(_msal);
    const _isAuth:boolean = useIsAuthenticated();

    const _resultProject:IState<IProjectData|null>|null = React.useContext(ResultProjectContext);

    /**
     * The URL for the SVG (has a unique "sid" variable attached to it for chacing)
     */
    const _svgUrl:IState<string> = useState<string>('');
    /**
     * The URL to with the generator uploads the files.
     * In essence the root of the blob with holds the generated files.
     */
    const _generatedFilesFolderURL:string = process.env.REACT_APP_GENERATED_FILES_FOLDER as string;

    const _getFiePath = (result:IGenerationSuccess, extention:string = 'svg') => {
        return `${_generatedFilesFolderURL}/${result.userId}/${result.projectGuid}/${result.fileName}.${extention}?sid=${Math.random()}`
    };
    
    //Start the generator i.e. fetches the generated data from Azure
    React.useEffect(() => {
        if(_data.generationResult 
            || !_isAuth 
            || _msalFetchGeneratedFiles.isLoading.ref.current //is already loading
            || _msalFetchGeneratedFiles.error.ref.current //is request in error
        ) {
            return; //we do not need to load the data if its not null
        }

        //creates the projkect data to be generated
        const heatpumpData:IHeatpumpValuesForForm = _form.data.heatpump.values;        
        const heatpump:IHeatpump|null = _devices?.heatpumps.find((d:IDevice) => d.guid === (heatpumpData.type === HeatpumpType.Ground ? heatpumpData.groundId : heatpumpData.airId)) || null;
        const accuTank:IAccuTank|null = _devices?.accuTanks.find((d:IDevice) => d.guid === _form.data.tanksSelection.values.accuTankId) || null;
        const coolingAccuTank:IAccuTank|null = _devices?.accuTanks.find((d:IDevice) => d.guid === _form.data.tanksSelection.values.coolingAccuTankId) || null;
        const boiler:IBoiler|null = _devices?.boilers.find((d:IDevice) => d.guid === _form.data.tanksSelection.values.boilerId) || null;
        const boilerHeatingElement:IHeatingElement|null = _devices?.heatingElements.find((d:IDevice) => d.guid === _form.data.tanksSelection.values.boilerHeatingElementId) || null;
        const accuTankHeatingElement:IHeatingElement|null = _devices?.heatingElements.find((d:IDevice) => d.guid === _form.data.tanksSelection.values.accuTankHeatingElementId) || null;

        const projectData:IProjectData = {
            id: _data.projectId,
            guid: _data.projectGuid,
            user: _activeUser,
            generalInfo: _form.data.generalInfo.values,
            heatingCircuts: _form.data.heatingCircuts.values,
            coolingCircuts: _form.data.coolingCircuts.values,
            heatpump: {
                ...heatpumpData,
                selection: heatpump
            },
            tanks: {
                ..._form.data.tanksSelection.values,
                accuTankSelection: accuTank,
                coolingAccuTankSelection: coolingAccuTank,
                boilerSelection: boiler,
                boilerHeatingElementSelection: boilerHeatingElement,
                accuTankHeatingElementSelection: accuTankHeatingElement
            },
            hasLogo: !!_activeUser.logoURL
        };

        projectData.heatingCircuts.extraCircutWithoutValveLabel = projectData.heatingCircuts.extraCircutWithoutValveLabel || _translations?.phrases.extraCircleWithoutValveHeating || '';
        projectData.heatingCircuts.extraCircutLabel = projectData.heatingCircuts.extraCircutLabel || _translations?.phrases.extraCircleHeating || '';
        projectData.heatpump.extraHeatingSourceLabel = projectData.heatpump.extraHeatingSourceLabel || _translations?.phrases.extraHeatingSource || '';

        //makes a long generation warning if generation is over 5s
        let generationTimeout = setTimeout(() => {
            g_warningToast(_translations?.phrases.longGenerationMessage);
        }, 5000);

        _msalFetchGeneratedFiles.execute('POST', g_protectedResources.apiCadGenerator.endpoint, projectData, true).then((result:IGenerationSuccess) => {
            
            clearTimeout(generationTimeout);

            if(!result) {
                return;
            }           

            const newData:Partial<IResultValues> = {
                generationResult: result,
                projectGuid: result.projectGuid,
                projectId: result.projectId,
                hasLogo: projectData.hasLogo
            };
            
            const svgUrl:string = _getFiePath(result);
            _svgUrl.set(svgUrl);

            _resultProject?.set(_.cloneDeep(projectData));

            //outpust a successful generation result 
            if(!_data.projectGuid) {
                g_successToast(Format.text(_translations?.phrases.successfulyGeneratedMessage, projectData.generalInfo.projectName));
            } 
            //outputs a successful save result
            else {
                g_successToast(Format.text(_translations?.phrases.successfulySavedMessage, projectData.generalInfo.projectName));
            }

            _form.setResult(newData);
        });

    }, [_data.generationResult, _isAuth, _msalFetchGeneratedFiles]);

    //makes sure that the SVG has an URL if loaded off screen
    React.useEffect(() => {
        if(_data.generationResult && !_svgUrl.value) {
            const svgUrl:string = _getFiePath(_data.generationResult);            
            _svgUrl.set(svgUrl);
        }
    }, [_data.generationResult, _svgUrl.value]);

    if(!_data.generationResult) {
        return <Loader />; //simply displays the loader on generation
    }
    
    /**
     * Updates the project stats in cosmos DB
     * @param type The type of stat that needs to be updated
     */
    const _incrementDownloadStats = (type:IncrementStatsTargetType):void => {
        const payload:IIncrementStatsPayload = {
            target: type,
            projectGuid: _data.generationResult?.projectGuid || ''
        };

        //we do not care for the response the project stats need to be updated
        _msalFetchIncrementStats.execute('post', g_protectedResources.apiIncrementStats.endpoint, payload, true);
    }

    const _pump:IHeatpump|null = _devices?.heatpumps.find(p => p.guid === _resultProject?.value?.heatpump.selection?.guid) || null;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='sectionHeading'>{_translations?.phrases.generationResultsSectionHeating}</Typography>            
            </Grid>
            <Grid 
                item
                xs={12}
                className={styles.buttons}
            >
                <Tooltip title={_translations?.phrases.downloadPdfTooltip} arrow>
                    <Button                         
                        component={'a'}
                        href={_getFiePath(_data.generationResult, 'pdf')}
                        startIcon={<PictureAsPdf />}
                        className={styles.downloadBtn}
                        target='_blank'
                        onClick={() => {
                            _incrementDownloadStats(IncrementStatsTargetType.Pdf);
                        }}
                    >
                        {_translations?.phrases.downloadPdfBtnLabel}
                    </Button>                        
                </Tooltip>
                <Tooltip title={_translations?.phrases.downloadDfxToolip} arrow>
                    <Button                         
                        component={'a'}
                        href={_getFiePath(_data.generationResult, _data.hasLogo ? 'zip' : 'dxf')}
                        startIcon={<Download />}
                        className={styles.downloadBtn}
                        target='_blank'
                        onClick={() => {
                            _incrementDownloadStats(IncrementStatsTargetType.Dxf);
                        }}
                    >
                        {_translations?.phrases.downloadDfxBtnLabel}
                    </Button>                        
                </Tooltip>  
                <Tooltip title={_translations?.phrases.downloadExcelTooltip} arrow>
                    <Button                         
                        component={'a'}
                        href={_getFiePath(_data.generationResult, 'xlsx')}                            
                        startIcon={<Dataset />}
                        className={styles.downloadBtn}
                        target='_blank'
                        onClick={() => {
                            _incrementDownloadStats(IncrementStatsTargetType.Excel);
                        }}
                    >
                        {_translations?.phrases.downloadExcelBtnLabel}
                    </Button>                        
                </Tooltip>
                <ManualsButton />
            </Grid>
            <Grid
                item
                xs={12}
                className={styles.svgContainer}
            >
                <Paper
                    sx={{
                        borderRadius: '.5em'
                    }}
                >
                    <SvgViewer 
                        src={_svgUrl.value}
                    />  
                </Paper>    
            </Grid>            
        </Grid>
    )
}
import * as React from 'react';
import { ICoolingBlockProps } from './ICoolingCircutProps';
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Grid, 
    MenuItem, 
    TextField, 
    Typography 
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { AppInput } from '../../AppInput/AppInput';
import * as _ from 'lodash';
import { POSSIBLE_DN_VALUES, IDNValue, g_getDNValue } from '../../../helpers/dnHelper';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { IWetCoolingCircut } from '../IProjectData';
import { AppSlider } from '../../AppSlider/AppSlider';
import { IState, useState } from '../../../react-hooks/useState';

/**
 * Renders out an individual cooling circut
 */
export function CoolingCircut(props:ICoolingBlockProps) {

    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _tempLabel:IState<string> = useState<string>('');

    React.useEffect(() => {
        const newDN:IDNValue|null = g_getDNValue(props.value.max || 0, props.value.min || 0, props.value.heatingLoad as number);
        if(newDN && (
            newDN.DN !== props.value.dn
            || newDN.flow !== props.value.flow
            || newDN.flow_m3 !== props.value.flow_m3
        )) {
            const value:IWetCoolingCircut = _.cloneDeep(props.value);
            value.dn = newDN.DN;
            value.flow = newDN.flow as number;
            value.flow_m3 = newDN.flow_m3 as number;
            props.onChange(value);
        }
    }, [
        props.value.max,
        props.value.min,
        props.value.heatingLoad
    ]);

    return (
        <Accordion 
            expanded={props.value.isEnabled}
            onChange={(e, isExpanded:boolean) => {
                const value:IWetCoolingCircut = _.cloneDeep(props.value);
                value.isEnabled = isExpanded;
                props.onChange(value);
            }}
        >
            <AccordionSummary>
                <Grid container spacing={1}>
                    <Grid item >
                        { props.icon }
                    </Grid>
                    <Grid item sx={{flexGrow: 1}}>
                        <Typography>{ props.label } { props.value.isEnabled && _tempLabel.value }</Typography>
                    </Grid>
                    <Grid item>
                        {
                            props.value.isEnabled ? (
                                <CheckIcon color={'primary'} />
                            ) : (
                                <CheckBoxOutlineBlank />
                            )
                        }                        
                    </Grid>
                </Grid>                
            </AccordionSummary>
            <AccordionDetails> 
                <AppSlider
                    min={typeof props.minTemp === 'number' ? props.minTemp : 20}
                    max={typeof props.maxTemp === 'number' ? props.maxTemp : 50}
                    initial={[props.value.min ||0, props.value.max || 0]}
                    hideLabel
                    onLabelChange={(newLabel:string) => {
                        _tempLabel.set(newLabel);
                    }}
                    onChange={(newValue:number|number[]) => {
                        const minMax:number[] = newValue as number[];
                        const value:IWetCoolingCircut = _.cloneDeep(props.value);
                        value.min = minMax[0];
                        value.max = minMax[1];
                        props.onChange(value);
                    }}
                />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>                        
                        <AppInput
                            label={_translations?.phrases.coolingCircutHeatingLoad}
                            numericValue={props.value.heatingLoad}
                            onNumberChange={(newValue:number|null) => {
                                const value:IWetCoolingCircut = _.cloneDeep(props.value);
                                value.heatingLoad = newValue;
                                props.onChange(value);
                            }}
                            isFullWidth
                            isRequired
                            unit='kW'
                            inErrorMessage={
                                props.inErrorValues?.isHeatloadInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }
                            onBlur={() => props.onHeatloadBlur?.()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <AppInput
                            label={_translations?.phrases.heatingCircutResistance}
                            numericValue={props.value.resistance}
                            onNumberChange={(newValue:number|null) => {
                                const value:IWetCoolingCircut = _.cloneDeep(props.value);
                                value.resistance = newValue;
                                props.onChange(value);
                            }}
                            isFullWidth
                            isRequired
                            unit='kPa'
                            inErrorMessage={
                                props.inErrorValues?.isResistanceInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }                            
                            onBlur={() => props.onResistanceBlur?.()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField 
                            label={_translations?.phrases.heatingCircutDN}
                            variant="standard"                                   
                            select
                            fullWidth 
                            value={props.value.dn || ''}
                            onChange={(e) => {
                                const value:IWetCoolingCircut = _.cloneDeep(props.value);
                                value.dn = Number(e.target.value);
                                props.onChange(value);
                            }}
                            required 
                            InputLabelProps={{
                                shrink: typeof props.value.dn === 'number'
                            }}   
                            error={props.inErrorValues?.isDnInError}
                            helperText={
                                props.inErrorValues?.isDnInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }                                                   
                        >
                            {
                                POSSIBLE_DN_VALUES.map((value:IDNValue) => (
                                    <MenuItem value={value.DN} key={value.DN}>
                                        { value.DN }
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
import { ButtonPropsColorOverrides, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { amber, blue, deepOrange, indigo, lime, teal, yellow } from '@mui/material/colors';
import { createTheme, Theme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { DistributiveOmit, OverridableStringUnion } from '@mui/types';
import { enUS, etEE } from '@mui/material/locale';
import * as React from 'react';
import { IState } from '../react-hooks/useState';


declare module '@mui/material/styles' {
    interface TypographyVariants {
        sectionHeading: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        sectionHeading?: React.CSSProperties;
    }
}
  
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        sectionHeading: true;
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        subIcon: Palette['primary'];
        profileColor: Palette['text'];
    }
  
    interface PaletteOptions {
        subIcon?: PaletteOptions['primary'];
        profileColor: PaletteOptions['text'];
    }
}

/**
 * The MUI Theme options for AlphaPro EE
 */
export const g_appThemeOptions:ThemeOptions = { 
    palette: {
        primary: {
            light: '#b8d3e6', //8ef7d3
            main: '#587b93', //2878ff
            dark: '#171b1e', //0054e6
            contrastText: '#fff',
        },
        secondary: deepOrange,
        divider: '#ccc',
        subIcon: {
            light: 'rgba(0,0,0,0.1)',
            main: 'rgba(0,0,0,0.3)', //we only use this one :P
            dark: 'rgba(0,0,0,0.6)', 
            contrastText: '#fff',
        },
        profileColor: {
            primary: '#181A1D'
        }
    },
    typography: {
        fontFamily: [            
            'Montserrat',  
            'Roboto',          
            'Arial',
            'Helvetica',
            'sans-serif'
        ].join(', '),
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        sectionHeading: {
            textAlign: 'left',
            paddingBottom: '1em',
            fontWeight: '700',
            color: "#C92128",
            fontSize: "2em",
            marginTop: '0.25em'
        }
    },
    
    components: {
        MuiTooltip: {
            defaultProps: {
                PopperProps: {
                    style: {
                        //zIndex: ,
                    }
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    // Map the new variant to render a <h1> by default
                    sectionHeading: 'h2',
                },
            },
            variants: [
                {
                    props: { variant: 'h4' },
                    style: {
                        color: '#54595F'
                    }
                }
            ]
        },
        MuiAlert: {
            defaultProps: {
                sx: {
                    borderRadius: '0',
                    marginBottom: '1em'
                }
            }
        },
        MuiAppBar: {
            defaultProps: {
                sx: {
                    borderRadius: '0',                     
                    backgroundColor: 'transparent',
                    color: '#54595F',
                    margin: '0px',
                    backdropFilter: ' blur(13px)',
                    boxShadow: 'inset 0 0 0 200px rgba(255,255,255,0.08), 0 4px 30px rgba(0, 0, 0, .1)'
                }
            }
        },
        MuiAccordion: {
            defaultProps: {
                sx: {
                    marginBottom: '0px',

                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    borderWidth: '0 1px 0 1px',
                    borderColor: 'rgba(0, 0, 0, 0.2)'
                }
            }
        },
        MuiDialogActions: {
            defaultProps: {
                sx: {
                    justifyContent: 'center'
                }
            }
        },
        MuiTab: {
            defaultProps: {
                sx: {
                    background: '#d8d8d8',                    
                    padding: '15px 20px',
                    borderRadius: '30px 2px 30px 2px',
                    color: '#54595F',
                    marginRight: '1em',
                    marginBottom: '1em',
                    textTransform: 'capitalize',
                    transition: 'all 0.3s',
                    '&:hover': {
                        backgroundColor: '#C9212894'
                    }
                }
            }
        },
        MuiTabs: {
            defaultProps: {                
                sx: {               
                    '.Mui-selected': {
                        background: '#C92128',
                        padding: '15px 20px',
                        borderRadius: '30px 2px 30px 2px',
                        color: '#ffffff !important',
                    },
                    '.MuiTabs-indicator': {
                        display: 'none'
                    }
                }
            }
        },           
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'elevation' },
                    style: {
                        borderRadius: '30px', //'30px 2px 30px 2px',
                        padding: '0.25em 0.25em',
                        boxSizing: 'border-box',
                        marginLeft: '3px',
                        marginRight: '3px',
                        marginBottom: '3px'
                    }
                }
            ]
        },
        MuiButton: {
          variants: [
            {
              props: { variant: 'contained' },
              style: {
                borderColor: '#000000',
                background: '#C92128',
                transition: 'all 500ms ease',
                borderRadius: '30px 2px 30px 2px', 
                padding: '15px 30px',
                //boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                fontWeight: 500,
                color: '#FCFCFC',   
                textTransform: 'none',
                '&:hover': {
                    boxShadow: 'rgba(0,0,0,.16) 0 1px 4px',
                    color: '#FCFCFC'                
                },
                margin: '1em 0 1em 1em',
                minWidth: '12em'     
              }
            },
            {
                props: { variant: 'text' },
                style: {                
                     
                }
              }
          ]
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: 'standard' },
                    style: {
                        background: 'transparent'
                    }
                }                
            ]
        },
        MuiDivider:{
            variants: [
                {
                    props: { },
                    style: {
                        borderColor: "#C92128"
                    }
                }
            ]
        },
        MuiLink: {
            variants: [
                {
                    props: {},
                    style: {
                        color: "#0A0908"
                    }
                }
            ]
        }
    }    
};
/**
 * The theme for the application
 */
export const g_defaultAppTheme:Theme = createTheme(g_appThemeOptions, etEE);
/**
 * The english theme for the MUI
 */
export const g_enGBAppTheme:Theme = createTheme(g_appThemeOptions, enUS);

/**
 * The type used for icons in MUI
 */
export type IconType = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
};
/**
 * The type used for the MUI button colors
 */
export type ButtonColor = OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>;
/**
 * Creates the context for the Mui Theme. 
 * NOTE! - Made so you can use the useState to build it
 */
export const MuiThemeContext = React.createContext<IState<Theme>|null>(null);
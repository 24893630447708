import * as React from 'react';
import { IDeviceSelectionProps } from './IDeviceSelectionProps';
import styles from './DeviceSelection.module.scss';

import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { IDevice } from '../IDevice';

/**
 * Used to select a device from a dropdown
 */
export function DeviceSelection(props:IDeviceSelectionProps) {
    return (
        <Box className={styles.container}>
            <Box>
                <FormControl fullWidth>
                    <InputLabel>{props.label}</InputLabel>
                    <Select
                        value={props.value?.guid || ''}
                        label={props.label}
                        onChange={(e, child) => {
                            const value:string = e.target.value;
                            const newDevice:IDevice|null = props.devices.find((d:IDevice) => d.guid === value) || null;
                            props.onChange(newDevice);
                        }}
                    >
                        <MenuItem value={''}>{props.clearSelectionLabel}</MenuItem>
                        {
                            props.devices.map((device:IDevice) => (
                                <MenuItem value={device.guid} key={device.guid}>{device.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>             
            </Box>
            <Box>
                {
                    !!props.value?.description &&
                    <Typography variant="body2" color="text.secondary" className={styles.decription}>
                        { props.value.description }
                    </Typography>
                }                
            </Box>
            
        </Box>
    );
}
export interface IProjectQuckInfo {
    /**
     * The ID of the project in Cosmos DB
     */
    id:string;
    /**
     * The projects GUID
     */
    guid:string;
    /**
     * The name of the project gotten from general info section
     */
    projectName:string;
    /**
     * The address of the project gotten from general info section
     */
    projectAddress:string;
    /**
     * The heatpump name of the selected heatpump
     */
    heatpumpName:string;
    /**
     * The projects ISO date, when it was generated
     */
    generationIsoDate:string;
    /**
     * The ID of the user who created the project
     */
    userId:string;
}

/**
 * An empty project, used simply to get the names of the project quick info object members
 */
export const EMPTY_PROJECT:IProjectQuckInfo = {
    id: '',
    guid: '',
    projectName: '',
    projectAddress: '',
    generationIsoDate: '',
    heatpumpName: '',
    userId: ''
};
import * as React from 'react';
import styles from './HeatingCircutsPreview.module.scss';

import { IHeatingCircutsValues, IUseFormHookResult, g_isCircutRequredFilled, useForm } from '../FormContext';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { HeatingCoolingCircutPreview } from '../HeatingCoolingCircutPreview/HeatingCoolingCircutPreview';
import { ExtraCircleWithoutValveHeatingIcon, ExtraCircleHeatingIcon, FloorHeatingIcon, PoolHeatingIcon, RadiatorHeatingIcon, VentilationHeatingIcon } from '../Icons';
import _ from 'lodash';
import { Typography } from '@mui/material';

/**
 * Renders out the heaing circuts preview on the stepper form
 */
export function HeatingCircutsPreview(props:{}) {

    const _form:IUseFormHookResult = useForm();
    const _data:IHeatingCircutsValues = _form.data.heatingCircuts.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    return (
        <>
            {
                g_isCircutRequredFilled(_data.floor) &&
                _data.floor.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_translations?.phrases.floorHeating}
                    min={_data.floor.min}
                    max={_data.floor.max}
                    icon={FloorHeatingIcon}
                />
            }
            {
                _data.hasFloorHeatingThreeWayValve &&
                <Typography variant='body2' className={styles.threeWayValve}>
                    { _translations?.phrases.floorHeating3WayValve }
                </Typography>
            }
            {
                g_isCircutRequredFilled(_data.radiator) &&
                _data.radiator.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_translations?.phrases.radiatorHeating}
                    min={_data.radiator.min}
                    max={_data.radiator.max}
                    icon={RadiatorHeatingIcon}
                />
            }
            {
                g_isCircutRequredFilled(_data.ventilation) &&
                _data.ventilation.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_translations?.phrases.ventilationHeating}
                    min={_data.ventilation.min}
                    max={_data.ventilation.max}
                    icon={VentilationHeatingIcon}
                />
            }
            {
                g_isCircutRequredFilled(_data.pool) &&
                _data.pool.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_translations?.phrases.poolHeating}
                    min={_data.pool.min}
                    max={_data.pool.max}
                    icon={PoolHeatingIcon}
                />
            }
            {
                g_isCircutRequredFilled(_data.extraWithoutValve) &&
                _data.extraWithoutValve.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_data.extraCircutWithoutValveLabel || _translations?.phrases.extraCircleWithoutValveHeating}
                    min={_data.extraWithoutValve.min}
                    max={_data.extraWithoutValve.max}
                    icon={ExtraCircleWithoutValveHeatingIcon}
                />
            }
            {
                g_isCircutRequredFilled(_data.extra) &&
                _data.extra.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_data.extraCircutLabel || _translations?.phrases.extraCircleHeating}
                    min={_data.extra.min}
                    max={_data.extra.max}
                    icon={ExtraCircleHeatingIcon}
                />
            }
        </>
    );
}
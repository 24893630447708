import * as React from 'react';
import styles from './Welcome_etEE.module.scss';
import { 
    Grid, 
    Stack, 
    Typography 
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


/**
 * Renders out the Welcome page for et-EE
 */
export function Welcome_etEE(props:{}) {
    return (
        <Stack alignItems={'center'}>
            <Grid 
                container 
                spacing={4} 
                className={styles.content}
            >
                    <Grid item xs={12} className={styles.Spacer}></Grid>
                    <Grid 
                        item 
                        xs={12}
                        sm={6} 
                        className={styles.whatIs}
                    >
                        <Typography variant='h6'>Mis on alphaPRO?</Typography>
                        <Typography variant='body2' component='p'>Suurepärane võimalus lihtsustada hoone kütteprojektide loomist!</Typography>
                        <Typography variant='body2' component='p'>Pakub kütte-, tarbevee- ja jahutusringide dimensioneerimist, valib torustiku õiged diameetrid, annab valiku sobivatest õhk-vesi ja maakütte soojuspumpadest ning ka maakontuuri vajadusest.</Typography>
                        <Typography variant='body2' component='p'>Genereerib detailitäpse küttesõlme skeemi .DXF formaadis koos küttesõlme spetsifikatsiooniga.</Typography>
                        <Typography variant='body2' component='p'>Sisaldab torustike DN, ventiilide ja klappide KV arve ning ringluspumpade ja soojusvahetite vooluhulkasid.</Typography>
                        <Typography variant='body2' component='p'>Nii loobki edaspidi kütteprojekte ca 10 minutiga!</Typography>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.LoginIMG}
                    >
                        <img src="/CADsample.webp"/>
                    </Grid>
                    <Grid item xs={12} className={styles.Spacer}></Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.LoginIMG}
                        sx={{
                            display:{
                                xs: 'none',
                                sm: 'flex'
                            }
                        }}
                    >
                        <img src="/LoginUseful.webp"/>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.Useful}                        
                    >
                        <Typography variant='h6'>Miks on alphaPRO kasulik?</Typography>
                        
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Projekteerija töö lihtsustamine enam kui 10x</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Sisendandmete automatiseerimine</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'>Täpne ja korrektne seadmete valik</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Küttesõlmeskeemi genereerimine Autocad ning PDF formaadis</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Projekt valmis 10 minutiga</Typography>
                        </Stack>
                        
                    </Grid>
                    <Grid item xs={12} className={styles.Spacer}></Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        className={styles.Useful}
                    >
                        <Typography variant='h6'>Kellele on alphaPRO mõeldud?</Typography>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Kütte- ja jahutuse projekteerijad</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Kütte- ja jahutuse insenerid</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <CheckCircleOutlineIcon color={'primary'}/>
                            <Typography variant='body2' component='p'> Kütte- ja jahutuse paigaldusettevõtted</Typography>
                        </Stack>    
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.LoginIMG}>
                        <img src="/loginPerson.webp"/>
                    </Grid>
            </Grid>            
        </Stack>
    )
}
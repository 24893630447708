import { IDevice } from './IDevice';
import { HeatpumpType } from './IProjectData';

export enum PumpTypes {
    WithIntegratedBoiler, //with integrated boiler
    WithExternalBoiler,
    Compact,
    Outdoor,
 	Indoor,
    Cascade
}

export enum SubTypes {
    Inverter,
    onOff,
    SplitHMInverter,
	SplitHTInverter, //with integated boiler
    InverterMonoblock,
	onOffMonoblock
}

export enum CompressorType {
    Inverter,
    OnOff,
    DualCompressorOnOff
}

export enum HeatpumpManualLinkType {
    Unknown,
    Installation,
    Technical,
    Declaration,
    Automation,
    ProductLine,
}

/**
 * Describes a single manual link
 */
export interface IHeatpumpManualLink {
    /**
     * The culture of the manual
     */
    lang?:string;
    /**
     * The type of manual this is
     */
    type:number;
    /**
     * The URL of the manual
     */
    url:string;
    /**
     * The display text of the link for the manual 
     */
    text?:string;
}

/**
 * Holds all the heating circut types
 */
export enum HeatinCircutType {
    Floor,
    Floor3W,
    Radiator,        
    Ventilation,
    Pool,
    ExtraHeating,
    ExtraHeatingWithoutValve
}


export interface IHeatpump extends IDevice {
    /**
     * The type of heatpump
     */
    type:HeatpumpType;
    /**
     * The group of the pump i.e. the tab of the pump
     */
    pumpType:PumpTypes;
    /**
     * The subgroup i.e. the radio group of the pump
     */
    group:SubTypes;
    /**
     * The power of the pump in kW
     */
    power:number;
    /**
     * The maximum power of the pump in kW
     */
    maxPower:number;
    /**
     * The minimum power of the pump in kW
     */
    minPower:number;
    /**
     * The template GUID of the template that is used to create this pump
     */
    templateId:string;
    /**
     * The GUIDs of the suitable acumalation tanks for the pump
     */
    accuTankIds:string[];
    /**
     * The GUIDs of the suitable boilers for the pump
     */
    boilerIds:string[];
    /**
     * If JS TRUTHY is the wet cooling power of the pump.
     * If JS FALSEY then the pump has no wet cooling power
     */
    coolingPowerWet:number;
    /**
     * If JS TRUTHY is the dry cooling power of the pump.
     * If JS FALSEY then the pump has no dry cooling power
     */
    coolingPowerDry:number;
    /**
     * Maximum inlet operating temperature
     */
    maxInletTemp:number;
    /**
     * The pumps compressor type
     */
    compressor:CompressorType;
    /**
     * The boiler tank size for the integrated boiler.
     * @remarks If 0 then the pump does not have an integrated boiler.
     */
    integratedBoiler:number;
    /**
     * The integrated heating element power in kW
     * @remarks If 0 then the pump does not have an integrated heating element
     */
    integratedHeatElement:number;
    /**
     * If TRUE the pump has a Domestic Hot Water AB valve
     */
    hasDhwABvalve:boolean;
    /**
     * The power of a single compressor in a dual compressor pump in kW.
     * @remarks Available only in a dualcompressor pump
     */
    powerSingleComp:number;
    /**
     * Whether circulation pump for heat circle is available
     */
    circPumpHeat:boolean;
    /**
     * Whether circulation pump for brine circle is available
     */
    circPumpBrine:boolean;
    /**
     * The deisplayName overwrite for no cooling
     */
    heatingDisplayName:string|null;
    /**
     * The deisplayName overwrite for wet cooling
     */
    wetCoolingDisplayName:string|null;
    /**
     * The deisplayName overwrite for dry cooling
     */
    dryCoolingDisplayName:string|null;
    /**
     * If set will hide the pump unless the query string has a "hiddenId" param with the exact same value in it
     * If left empty then is allowed everywhere.
     */
    hiddenId:string|null;
    /**
     * Holds the available manuals of the heatpump
     */
    manuals?:IHeatpumpManualLink[]|null;
    /**
     * The GUIDs of the suitable cooling acumalation tanks for the pump when the wet-cooling option is chosen.
     * If NULL [this].accuTankIds is used instead
     */
    wetCoolingAccuTankIds:string[]|null;
    /**
     * Shows the suitable accumalation tanks for when the extra heatsource is available.
     * By default [this].accuTankIds
     */
    extraHeatSourceAccuIds:string[]|null;
    /**
     * If TRUE the extra heatsource will be turned off for the pump.
     */
    disableExtraHeatSource:boolean;
    /**
     * Holds all the heating circut types available to the pump.
     * I.e. Only these circuts are allowed on this pump!
     */
    allowedHeatingCircuts:HeatinCircutType[];
    /**
     * Holds the possible pumps in cascade with the pump in question.
     * Basically the pump GUIDs in an array.
     * If NULL then the pump is not in cascade!
     */
    cascadePumpsIds:string[]|null;
}

import { AlphaProLoader } from "../AlphaProLoader/AlphaProLoader";


/**
 * Renders out the generation loader
 */
export function Loader(props:{}) {

    return (
        <>
            <AlphaProLoader />
        </>
    )
}
import * as React from 'react';
import styles from './CoolingCircutsPreview.module.scss';

import { CoolingInnerPartsIcon, ExtraCircleHeatingIcon, ExtraCircleWithoutValveHeatingIcon, FloorHeatingIcon, VentilationCoolingIcon, VentilationHeatingIcon } from '../Icons';
import { ICoolingCircutsValues, IHeatingCircutsValues, IUseFormHookResult, g_isCircutRequredFilled, useForm } from '../FormContext';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { HeatingCoolingCircutPreview } from '../HeatingCoolingCircutPreview/HeatingCoolingCircutPreview';
import { Box, Typography } from '@mui/material';
import { Format } from '../../../helpers/Format';
import { CoolingType } from '../IProjectData';

/**
 * Renders out the cooling circut stepper form review
 */
export function CoolingCircutsPreview(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:ICoolingCircutsValues = _form.data.coolingCircuts.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);

    const _heatingCircuts:IHeatingCircutsValues = _form.data.heatingCircuts.values;
    
    let _isTempVisible:boolean = false;
    switch(_form.data.generalInfo.values.coolingType) {
        case CoolingType.Dry:
            _isTempVisible = (
                _data.dryCoolingFloor.isEnabled && !!_data.dryCoolingFloor.coolingLoad
                || _data.dryCoolingVentilation.isEnabled && !!_data.dryCoolingVentilation.coolingLoad
                || _data.dryCoolingExtraWithoutValve.isEnabled && !!_data.dryCoolingExtraWithoutValve.coolingLoad
                || _data.dryCoolingExtra.isEnabled && !!_data.dryCoolingExtra.coolingLoad
            );
            break;
    }

    return (
        <>
            {
                _isTempVisible &&
                <Box 
                    sx={{
                        paddingBottom: '0.25em'
                    }}
                >
                    <Typography 
                        variant='body2'
                        component={'span'}
                    >
                        {_translations?.phrases.operatingRange}
                    </Typography>
                    <Typography
                        variant='body2'
                        component={"span"}
                        className={styles.unit}
                        sx={{
                            color: "primary.dark",
                        }}
                    >
                        { Format.text(_translations?.phrases.heatingCoolingCircutPreviewUnit, _data.min, _data.max) }
                    </Typography>
                </Box>
            }
            {
                g_isCircutRequredFilled(_data.coolingInnerParts) &&
                _data.coolingInnerParts.isEnabled &&
                <HeatingCoolingCircutPreview
                    label={_translations?.phrases.coolingInnerParts}
                    icon={CoolingInnerPartsIcon}
                    min={_data.coolingInnerParts.min!}
                    max={_data.coolingInnerParts.max!}
                />
            }
            {
                g_isCircutRequredFilled(_data.ventilationCoolingBatteries) &&
                _data.ventilationCoolingBatteries.isEnabled &&
                <HeatingCoolingCircutPreview
                    label={_translations?.phrases.ventilationCoolingBatteries}
                    icon={VentilationCoolingIcon}
                    min={_data.ventilationCoolingBatteries.min!}
                    max={_data.ventilationCoolingBatteries.max!}
                />
            }
            {
                _data.dryCoolingFloor.isEnabled && !!_data.dryCoolingFloor.coolingLoad &&
                <HeatingCoolingCircutPreview
                    label={_translations?.phrases.floorHeating}
                    icon={FloorHeatingIcon}
                    min={_data.min}
                    max={_data.max}
                    hideTemp
                />
            } 
            {
                _data.dryCoolingVentilation.isEnabled && !!_data.dryCoolingVentilation.coolingLoad &&
                <HeatingCoolingCircutPreview
                    label={_translations?.phrases.ventilationHeating}
                    icon={VentilationHeatingIcon}
                    min={_data.min}
                    max={_data.max}
                    hideTemp
                />
            }
            {
                _data.dryCoolingExtraWithoutValve.isEnabled && !!_data.dryCoolingExtraWithoutValve.coolingLoad &&
                <HeatingCoolingCircutPreview
                    label={_heatingCircuts.extraCircutWithoutValveLabel ||  _translations?.phrases.extraCircleWithoutValveHeating}
                    icon={ExtraCircleWithoutValveHeatingIcon}
                    min={_data.min}
                    max={_data.max}
                    hideTemp
                />
            }
            {
                _data.dryCoolingExtra.isEnabled && !!_data.dryCoolingExtra.coolingLoad &&
                <HeatingCoolingCircutPreview
                    label={_heatingCircuts.extraCircutLabel || _translations?.phrases.extraCircleHeating}
                    icon={ExtraCircleHeatingIcon}
                    min={_data.min}
                    max={_data.max}
                    hideTemp
                />
            }  
        </>
    );
}
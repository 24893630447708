import * as React from 'react';
import { IGeneralInfoProps } from './IGeneralInfoProps';

import styles from './GeneralInfo.module.scss';
import { Alert, Box, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Switch, TextField, Typography } from '@mui/material';
import { AppInput } from '../../AppInput/AppInput';
import { useState } from '../../../react-hooks/useState';
import { 
    useForm,
    IGeneralInfoValues,
    IUseFormHookResult,
    IHeatpumpValuesForForm
} from '../FormContext';
import * as _ from 'lodash';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { AppSlider } from '../../AppSlider/AppSlider';
import { AppTabs } from '../../AppTabs/AppTabs';
import { CoolingType, HeatingLoadBuildingType } from '../IProjectData';
import { PumpWarning } from '../PumpWarning/PumpWarning';

const GAS_UNDERLIEING_HEATING_VALUE:number = 10.5;
const PELLET_UNDERLIEING_HEATING_VALUE:number = 4.6;
const OIL_UNDERLIEING_HEATING_VALUE:number = 10;
const WOOD_UNDERLIEING_HEATING_VALUE:number = 1300;
const PROPANE_UNDERLIEING_HEATING_VALUE:number = 12.8;

const INNER_AND_OUTDOOR_TEMP_DIFFERENCE:number = 38;
const HOURS_PER_DAY:number = 0.024;
const BALANCE_TEMP_DEGREE_DAYS:number = 4220;

const BOILER_EFFICIENCY:number = 0.9;

const COOLING_TYPE_GROUP_NAME:string = 'cooling-type-d9267b04-b8c4-41e1-a323-1e34530869fc';

export function GeneralInfo(props:IGeneralInfoProps) {

    const _form:IUseFormHookResult = useForm();
    const _data:IGeneralInfoValues = _form.data.generalInfo.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    
    const _heatpumpSelection:IHeatpumpValuesForForm = _form.data.heatpump.values;
    const _hasNoPumpsWarning:boolean = !_heatpumpSelection.groundDimensioningRange && !_heatpumpSelection.airDimensioningRange && _heatpumpSelection.areDimensioningRangesChecked;

    /**
     * Updates the general info with the pump dimensioning check i.e. checks if there are any available pumps for the system
     * @param newData The data to be added/updated
     * @param alwaysDoCheck If TRUE the heatpump check is always done
     */
    const _updateGeneralInfoDataWithPumpCheck = (newData:Partial<IGeneralInfoValues>, alwaysDoCheck:boolean = false) => {
        if(_hasNoPumpsWarning || alwaysDoCheck) {
            _form.data.generalInfo.setValues(newData);
            _form.data.setHeatpumpDimensioning();
            _form.reRender();
        } 
        else {
            _form.setGeneralInfo(newData);
        }
    };

    //calculates the headigload automatically
    React.useEffect(() => {
        
        if((!_data.electricityConsumptionPerAnnum
                && !_data.gasConsumptionPerAnnum
                && !_data.pelletConsumptionPerAnnum
                && !_data.oilConsumptionPerAnnum
                && !_data.woodConsumptionPerAnnum
                && !_data.propaneConsumptionPerAnnum
            ) || _data.isHeatingLoadManual
        ) {
            //makes sure that the automatic clculation does not kick in on manual or when the values are all 0
            return;
        }

        const electricity_kWh:number = typeof _data.electricityConsumptionPerAnnum === 'number' ? _data.electricityConsumptionPerAnnum * 1000 : 0;
        const gas_kWh:number = typeof _data.gasConsumptionPerAnnum === 'number' ?_data.gasConsumptionPerAnnum * BOILER_EFFICIENCY * GAS_UNDERLIEING_HEATING_VALUE : 0;
        const pellet_kWh:number = typeof _data.pelletConsumptionPerAnnum === 'number' ?_data.pelletConsumptionPerAnnum * BOILER_EFFICIENCY * PELLET_UNDERLIEING_HEATING_VALUE : 0;
        const oil_kWh:number = typeof _data.oilConsumptionPerAnnum === 'number' ?_data.oilConsumptionPerAnnum * BOILER_EFFICIENCY * OIL_UNDERLIEING_HEATING_VALUE : 0;
        const wood_kWh:number = typeof _data.woodConsumptionPerAnnum === 'number' ?_data.woodConsumptionPerAnnum * BOILER_EFFICIENCY * WOOD_UNDERLIEING_HEATING_VALUE : 0;
        const propane_kWh:number = typeof _data.propaneConsumptionPerAnnum === 'number' ?_data.propaneConsumptionPerAnnum * BOILER_EFFICIENCY * PROPANE_UNDERLIEING_HEATING_VALUE : 0;

        const MWh:number = (electricity_kWh + gas_kWh + pellet_kWh + oil_kWh + wood_kWh + propane_kWh) / 1000;
        let heatingLoadLoss:number = (MWh * INNER_AND_OUTDOOR_TEMP_DIFFERENCE)/(BALANCE_TEMP_DEGREE_DAYS * HOURS_PER_DAY);
        heatingLoadLoss = Math.ceil(heatingLoadLoss * 10) / 10; //rounds the heating loss

        const newData:Partial<IGeneralInfoValues> = {
            heatload: heatingLoadLoss,
            isHeatloadInError: !heatingLoadLoss
        };
       _updateGeneralInfoDataWithPumpCheck(newData);
    }, [
        _data.electricityConsumptionPerAnnum,
        _data.gasConsumptionPerAnnum,
        _data.pelletConsumptionPerAnnum,
        _data.oilConsumptionPerAnnum,
        _data.woodConsumptionPerAnnum,
        _data.propaneConsumptionPerAnnum
    ]);

    return (
        <Box>
            <Typography variant='sectionHeading'>{_translations?.phrases.projectInfoSectionHeading}</Typography>
            {
                _heatpumpSelection.areDimensioningRangesChecked &&
                <PumpWarning />
            }
            <Grid 
                container 
                spacing={1}
            >
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.projectName} 
                        isRequired
                        isFullWidth
                        value={_data.projectName}
                        onChange={(newValue:string) => {
                            const values:Partial<IGeneralInfoValues> = {
                                projectName: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                        onBlur={() => {
                            const isInError:boolean = !(_data.projectName || '').trim();
                            const values:Partial<IGeneralInfoValues> = {
                                isProjectNameInError: isInError
                            };
                            _form.setGeneralInfo(values);
                        }}
                        inErrorMessage={
                            _data.isProjectNameInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput
                        label={_translations?.phrases.projectHeatingArea}
                        unit="m²"
                        isRequired
                        isFullWidth
                        numericValue={_data.area}
                        onNumberChange={(newValue:number|null) => {
                            const values:Partial<IGeneralInfoValues> = {
                                area: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                        onBlur={() => {
                            let isInError:boolean = !_data.area
                            const values:Partial<IGeneralInfoValues> = {
                                isHeatingAreaInError: isInError
                            };
                            _form.setGeneralInfo(values);
                        }}
                        inErrorMessage={
                            _data.isHeatingAreaInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.projectAddress}                         
                        isFullWidth 
                        value={_data.address}
                        onChange={(newValue:string) => {
                            const values:Partial<IGeneralInfoValues> = {
                                address: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.projectClientLabel} 
                        isFullWidth
                        value={_data.projectClient}
                        onChange={(newValue) => {
                            const values:Partial<IGeneralInfoValues> = {
                                projectClient: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.projectDrafterLabel} 
                        isFullWidth
                        value={_data.projectDrafter}
                        onChange={(newValue) => {
                            const values:Partial<IGeneralInfoValues> = {
                                projectDrafter: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.projectManagerLabel} 
                        isFullWidth
                        value={_data.projectManager}
                        onChange={(newValue) => {
                            const values:Partial<IGeneralInfoValues> = {
                                projectManager: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.accountableProjectDrafterLabel} 
                        isFullWidth
                        value={_data.accountableProjectDrafter}
                        onChange={(newValue) => {
                            const values:Partial<IGeneralInfoValues> = {
                                accountableProjectDrafter: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppInput 
                        label={_translations?.phrases.projectNumberLabel} 
                        isFullWidth
                        value={_data.projectNumber}
                        onChange={(newValue) => {
                            const values:Partial<IGeneralInfoValues> = {
                                projectNumber: newValue
                            };
                            _form.setGeneralInfo(values);
                        }}
                    />
                </Grid>                
            </Grid>
            <Grid 
                container 
                spacing={1}
                className={styles.heatload}
            >
                <Grid 
                    item 
                    xs={12} 
                    sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider' 
                    }}
                >
                    <AppTabs 
                        selectedValue={_data.buildingType}
                        tabs={[
                            {
                                label: _translations?.phrases.newBuilding,
                                value: HeatingLoadBuildingType.New
                            },
                            {
                                label: _translations?.phrases.renovatedHouse,
                                value: HeatingLoadBuildingType.Renovated
                            }
                        ]}
                        onChange={(newValue:HeatingLoadBuildingType) => {
                            const newData:Partial<IGeneralInfoValues> = {
                                buildingType: newValue as HeatingLoadBuildingType 
                            };
                            _form.setGeneralInfo(newData);
                        }}
                    />
                </Grid>
                {
                    _data.buildingType === HeatingLoadBuildingType.Renovated &&
                    <>
                        <Grid item xs={12}>
                            <Typography 
                                variant='subtitle2'
                                className={styles.renovatedHeatloadCalcHeading}
                            >
                                {_translations?.phrases.selectRenovatedHeatloadsubheading}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AppInput 
                                label={_translations?.phrases.electricityConsumptionPerYear}
                                unit={'MWh'}
                                isFullWidth
                                isSecondary
                                numericValue={_data.electricityConsumptionPerAnnum}
                                onNumberChange={(newValue:number|null) => {
                                    const newData:Partial<IGeneralInfoValues> = {
                                        electricityConsumptionPerAnnum: newValue,
                                        isHeatingLoadManual: false
                                    };
                                    _form.setGeneralInfo(newData);
                                }}
                                onBlur={() => {
                                    _form.data.setHeatpumpDimensioning();
                                    _form.reRender();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AppInput 
                                label={_translations?.phrases.gasConsumptionPerYear}
                                unit={'m³'}
                                isFullWidth
                                isSecondary
                                numericValue={_data.gasConsumptionPerAnnum}
                                onNumberChange={(newValue:number|null) => {
                                    const newData:Partial<IGeneralInfoValues> = {
                                        gasConsumptionPerAnnum: newValue,
                                        isHeatingLoadManual: false
                                    };
                                    _form.setGeneralInfo(newData);
                                }}
                                onBlur={() => {
                                    _form.data.setHeatpumpDimensioning();
                                    _form.reRender();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AppInput 
                                label={_translations?.phrases.pelletConsumptionPerYear}
                                unit={'kg'}
                                isFullWidth
                                isSecondary
                                numericValue={_data.pelletConsumptionPerAnnum}
                                onNumberChange={(newValue:number|null) => {
                                    const newData:Partial<IGeneralInfoValues> = {
                                        pelletConsumptionPerAnnum: newValue,
                                        isHeatingLoadManual: false
                                    };
                                    _form.setGeneralInfo(newData);
                                }}
                                onBlur={() => {
                                    _form.data.setHeatpumpDimensioning();
                                    _form.reRender();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AppInput 
                                label={_translations?.phrases.oilConsumptionPerYear}
                                unit={'kg'}
                                isFullWidth
                                isSecondary
                                numericValue={_data.oilConsumptionPerAnnum}
                                onNumberChange={(newValue:number|null) => {
                                    const newData:Partial<IGeneralInfoValues> = {
                                        oilConsumptionPerAnnum: newValue,
                                        isHeatingLoadManual: false
                                    };
                                    _form.setGeneralInfo(newData);
                                }}
                                onBlur={() => {
                                    _form.data.setHeatpumpDimensioning();
                                    _form.reRender();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AppInput 
                                label={_translations?.phrases.woodConsumptionPerYear}
                                unit={'m³'}
                                isFullWidth
                                isSecondary
                                numericValue={_data.woodConsumptionPerAnnum}
                                onNumberChange={(newValue:number|null) => {
                                    const newData:Partial<IGeneralInfoValues> = {
                                        woodConsumptionPerAnnum: newValue,
                                        isHeatingLoadManual: false
                                    };
                                    _form.setGeneralInfo(newData);
                                }}
                                onBlur={() => {
                                    _form.data.setHeatpumpDimensioning();
                                    _form.reRender();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AppInput 
                                label={_translations?.phrases.propaineConsumptionPerYear}
                                unit={'kg'}
                                isFullWidth
                                isSecondary
                                numericValue={_data.propaneConsumptionPerAnnum}
                                onNumberChange={(newValue:number|null) => {
                                    const newData:Partial<IGeneralInfoValues> = {
                                        propaneConsumptionPerAnnum: newValue,
                                        isHeatingLoadManual: false
                                    };
                                    _form.setGeneralInfo(newData);
                                }}
                                onBlur={() => {
                                    _form.data.setHeatpumpDimensioning();
                                    _form.reRender();
                                }}
                            />
                        </Grid>
                    </>
                }                                    
                <Grid item xs={12}>
                    <AppInput 
                        label={_translations?.phrases.heatingLoadLoss}
                        unit={'kW'}
                        isFullWidth
                        isRequired
                        numericValue={_data.heatload}
                        onNumberChange={(newValue:number|null) => {
                            const newData:Partial<IGeneralInfoValues> = {
                                heatload: newValue,
                                isHeatingLoadManual: true
                            };
                            
                            _updateGeneralInfoDataWithPumpCheck(newData);
                        }}
                        onBlur={() => {
                            const newData:Partial<IGeneralInfoValues> = {
                                isHeatloadInError: !_data.heatload
                            };
                            _form.data.setHeatpumpDimensioning();
                            _form.setGeneralInfo(newData);
                        }}                        
                        inErrorMessage={
                            _data.isHeatloadInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                        }
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12}
                >
                    <FormControl
                        sx={{
                            marginTop: '0.5em'
                        }}
                    >
                        <FormLabel>{_translations?.phrases.coolingTypeRadioGroupLabel}</FormLabel>
                        <RadioGroup
                            name={COOLING_TYPE_GROUP_NAME}
                            value={_data.coolingType}
                            onChange={(e, value:string) => {
                                const type:CoolingType = parseInt(value) as CoolingType;
                                const newData:Partial<IGeneralInfoValues> = {
                                    coolingType: type,
                                    isCoolingCircutsEnabled: !!type
                                };
                                
                                if(!type) {
                                    newData.isCoolingLoadInError = false;
                                }

                                _updateGeneralInfoDataWithPumpCheck(newData, true);
                            }}
                            row
                        >                            
                            <FormControlLabel 
                                value={CoolingType.None} 
                                control={<Radio size='small' />} 
                                label={_translations?.phrases.coolingTypeRadioNoneLabel}                                 
                            />
                            <FormControlLabel 
                                value={CoolingType.Wet} 
                                control={<Radio size='small' />} 
                                label={_translations?.phrases.coolingTypeRadioWetLabel}                                 
                            />
                            <FormControlLabel 
                                value={CoolingType.Dry} 
                                control={<Radio size='small' />} 
                                label={_translations?.phrases.coolingTypeRadioDryLabel}                                 
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {
                    _data.isCoolingCircutsEnabled &&
                    <Grid item xs={12}>
                        <AppInput 
                            label={_translations?.phrases.coolingLoadLabel}
                            unit={'kW'}
                            isFullWidth
                            isRequired
                            numericValue={_data.coolingLoad}
                            onNumberChange={(newValue:number|null) => {
                                const newData:Partial<IGeneralInfoValues> = {
                                    coolingLoad: newValue
                                };
                                _updateGeneralInfoDataWithPumpCheck(newData);
                            }}
                            onBlur={() => {
                                const newData:Partial<IGeneralInfoValues> = {
                                    isCoolingLoadInError: !_data.coolingLoad
                                };
                                _form.data.setHeatpumpDimensioning();
                                _form.setGeneralInfo(newData);
                            }}
                            inErrorMessage={
                                _data.isCoolingLoadInError ? _translations?.phrases.requiredFieldErrorMessage : ''
                            }
                        />
                    </Grid>
                }                
            </Grid>
        </Box> 
    );
}
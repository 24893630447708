import * as React from 'react';
import styles from './HeaingPumpPreview.module.scss';

import { IHeatpumpValuesForForm, IUseFormHookResult, g_isCircutRequredFilled, useForm } from '../FormContext';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';
import { IHeatpump } from '../IHeatpump';
import { DevicesContext } from '../DevicesContext';
import { DimensioningIcon, ExtraHeatingCircleIcon } from '../Icons';
import { Box, Typography } from '@mui/material';
import { Format } from '../../../helpers/Format';
import { HeatpumpType } from '../IProjectData';
import { HeatingCoolingCircutPreview } from '../HeatingCoolingCircutPreview/HeatingCoolingCircutPreview';

/**
 * Renders out the heatpump selection preview
 */
export function HeatingPumpPreview(props:{}) {
    const _form:IUseFormHookResult = useForm();
    const _data:IHeatpumpValuesForForm = _form.data.heatpump.values;
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    
    const _allPumps:IHeatpump[] = React.useContext(DevicesContext)?.heatpumps || [];

    if(_data.type === HeatpumpType.NotSet) {
        return null;
    }

    let _heatpump:IHeatpump;
    let _dimensioning:number;
    if(_data.type === HeatpumpType.Ground) {
        _heatpump = _allPumps.find((p:IHeatpump) => p.guid === _data.groundId) as IHeatpump;
        _dimensioning = _data.groundDimensioning;
    }
    else {
        _heatpump = _allPumps.find((p:IHeatpump) => p.guid === _data.airId) as IHeatpump;
        _dimensioning = _data.airDimensioning;
    }

    return (
        <>
            <Typography
                variant='body2'
                sx={{
                    color: "primary.dark",
                    
                }}
                className={styles.unit}
            >
                { _form.data.getPumpName(_heatpump) }
            </Typography>
            {
                _data.type === HeatpumpType.Ground &&
                <Box>
                    <Typography 
                        variant='body2'
                        component={"span"}
                        
                    >
                        { _translations?.phrases.earthContureLength + ' - '}
                    </Typography>
                    <Typography 
                        variant='body2'
                        component={"span"}
                        sx={{
                            color: "primary.dark",
                        }}
                        className={styles.unitBC}
                    >
                        {_data.totalEathContureLength}m
                    </Typography>     
                </Box>                
            }
            {
                g_isCircutRequredFilled(_data.extraHeatingSource) &&
                _data.extraHeatingSource.isEnabled &&
                <HeatingCoolingCircutPreview 
                    label={_data.extraHeatingSourceLabel || _translations?.phrases.extraHeatingSource}
                    min={_data.extraHeatingSource.min}
                    max={_data.extraHeatingSource.max}
                    icon={ExtraHeatingCircleIcon}
                    className={styles.extraHeatingSource}
                />
            }
        </>
    );
}
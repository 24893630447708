import { IActiveAccount } from "../../models/IActiveAccount";
import { IAccuTank } from "./IAccuTank";
import { IBoiler } from "./IBoiler";
import { IHeatingElement } from "./IHeatingElement";
import { IHeatpump, PumpTypes } from "./IHeatpump";

export enum HeatpumpType {
    NotSet,
    Ground,
    Air
}
export enum HeatingLoadBuildingType {
    New,
    Renovated
}
/**
 * Holds the possible cooling types
 */
export enum CoolingType {
    None,
    Wet,
    Dry
}

export interface IGeneralInfo {
    projectName:string|null;
    address:string|null;
    area:number|null;

    projectDrafter:string|null;
    projectManager:string|null;
    accountableProjectDrafter:string|null;
    projectNumber:string|null;
    projectClient:string|null;

    heatload:number|null;
    isHeatingLoadManual:boolean;
    buildingType:HeatingLoadBuildingType;

    electricityConsumptionPerAnnum:number|null;
    gasConsumptionPerAnnum:number|null;
    pelletConsumptionPerAnnum:number|null;
    oilConsumptionPerAnnum:number|null;
    woodConsumptionPerAnnum:number|null;
    propaneConsumptionPerAnnum:number|null;

    isCoolingCircutsEnabled:boolean;
    coolingLoad:number|null;
    coolingType:CoolingType;
}

export interface IHeatingCircut {
    isEnabled:boolean;
    min:number;
    max:number;  
    heatingLoad:number|null;
    resistance:number|null;
    dn:number|null;  
    /**
     * The flow in liters
     */
    flow:number|null;
    /**
     * The flow in m3/h intead of liters
     */
    flow_m3:number|null;
}

export interface IHeatingCircuts {
    floor:IHeatingCircut;
    radiator:IHeatingCircut;
    ventilation:IHeatingCircut;
    pool:IHeatingCircut;
    extraWithoutValve:IHeatingCircut;
    extra:IHeatingCircut;

    hasFloorHeatingThreeWayValve:boolean;
    /**
     * The label used for the extra heating circut
     */
    extraCircutLabel:string|null;
    /**
     * The label used for the extra heating circut (without mixture node)
     */
    extraCircutWithoutValveLabel:string|null;
}

/**
 * Describes the wet cooling circut
 */
export interface IWetCoolingCircut {
    /**
     * If TRUE the circut is enabled
     */    
    isEnabled:boolean; 
    /**
     * The cooling power in kW i.e. the cooling power
     */
    heatingLoad:number|null;
    /**
     * The resistance of the circut
     */
    resistance:number|null;
    /**
     * The pipe size in DN
     */
    dn:number|null; 
    /**
     * The flow in liters
     */
    flow:number|null;
    /**
     * The flow in m3/h intead of liters
     */
    flow_m3:number|null;
    /*
     * The max operation temp of the circut.
     * If NULL use the over all cooling operating temp
     */
    max:number|null;
    /*
     * The min operation temp of the circut.
     * If NULL use the over all cooling operating temp
     */
    min:number|null;
}
/**
 * Describes the dry cooling circut
 */
export interface IDryCoolingCircut {
    /**
     * If TRUE the circut is enabled
     */
    isEnabled:boolean;
    /**
     * Holds the cooling load in kW
     */
    coolingLoad:number|null;
    /**
     * If TRUE shows that the cooling load is in error
     * NOTE! - Not parsed into the server
     */
    isCoolingLoadInError:boolean;
}

export interface ICoolingCircuts {
    min:number;
    max:number;
    coolingInnerParts:IWetCoolingCircut;
    ventilationCoolingBatteries:IWetCoolingCircut;

    dryCoolingFloor:IDryCoolingCircut;
    dryCoolingVentilation:IDryCoolingCircut;
    dryCoolingExtraWithoutValve:IDryCoolingCircut,
    dryCoolingExtra:IDryCoolingCircut,
}

export enum EarthContureCalculationType {
    Automatic,
    Manual
}

export interface IHeatpumpSelectionValues {
    type:HeatpumpType;
    groundId:string|null;
    airId:string|null;
    groundDimensioning:number;
    airDimensioning:number;

    earthType:number;
    earthContureCalType:EarthContureCalculationType;
    circleLength:number;
    nrOfContures:number;
    totalEathContureLength:number;

    extraHeatingSource:IHeatingCircut;
    extraHeatingSourceLabel:string|null;

    selectedGroundPumpType:PumpTypes|null;
    selectedAirPumpType:PumpTypes|null;
}

export interface IHeatpumpSelectionProjectValues extends IHeatpumpSelectionValues {
    selection:IHeatpump|null;
}
export interface ITankSelectionValues {
    boilerId:string|null;
    accuTankId:string|null;
    coolingAccuTankId:string|null;

    accuTankHeatingElementId:string|null;
    boilerHeatingElementId:string|null;

    hasRecirculation:boolean;
}
export interface ITankSelectionProjectValues {
    boilerSelection:IBoiler|null;
    accuTankSelection:IAccuTank|null;
    coolingAccuTankSelection:IAccuTank|null;
    accuTankHeatingElementSelection:IHeatingElement|null;
    boilerHeatingElementSelection:IHeatingElement|null;
}

export interface IResult {
    /**
     * The name of teh generated files.
     * @remarks Without the extention
     */
    fileName:string;
    /**
     * The ISO date at with the files where generated
     */
    generationIsoDate:string;
}

export interface IProjectStats {
    saveCount:number;
    createdIsoDate:string;
    excelDownloadsCount:number;
    lastExcelDownloadISO:string;
    pdfDownloadsCount:number;
    lastPdfDownloadISO:string;
    dxfDownloadsCount:number;
    lastDxfDownloadISO:string;
}

export interface IProjectData {
    id:string|null;
    guid:string|null;
    user:IActiveAccount;
    generalInfo:IGeneralInfo;
    heatingCircuts:IHeatingCircuts;
    coolingCircuts:ICoolingCircuts;    
    heatpump:IHeatpumpSelectionProjectValues;
    tanks:ITankSelectionProjectValues;
    result?:IResult;
    /**
     * Holds the project stats
     * NOTE!- Is optional, because it does not need to be filled for the generator because it is not used and the generator downloads the lates version for the save
     */
    stats?:IProjectStats;
    /**
     * If TRUE the project has a logo
     */
    hasLogo?:boolean;
}

import * as React from 'react';
import { IHeatingCoolingCircutPreviewProps } from './IHeatingCoolingCircutPreviewProps';
import styles from './HeatingCoolingCircutPreview.module.scss';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IconType } from '../../../helpers/muiHelper';
import { Format } from '../../../helpers/Format';
import { ITranslationsContext, TranslationsContext } from '../../../translations/TranslationsContext';


/**
 * Renders out an individual heading/cooling circut preview
 */
export function HeatingCoolingCircutPreview(props:IHeatingCoolingCircutPreviewProps) {
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    
    const Icon:IconType = props.icon;
    return (
        <Grid container className={props.className}>
            <Grid 
                item 
                xs={2}
                sx={{
                    flex: '0 1 auto !important'
                }}
            >
                <Icon />
            </Grid>
            <Grid item xs={10}>                
                {
                    !!props.label &&
                    <Typography
                        variant='body2'
                        component={"span"}
                        className={styles.label}
                    >
                        { props.label }
                    </Typography>
                }
                {
                    !props.hideTemp &&
                    <Typography
                        variant='body2'
                        component={"span"}
                        className={styles.unit}
                        sx={{
                            color: "primary.dark",
                        }}
                    >
                        { Format.text(_translations?.phrases.heatingCoolingCircutPreviewUnit, props.min, props.max) }
                    </Typography>
                }
            </Grid>
        </Grid>
    );
}
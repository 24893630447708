import * as React from 'react';
import styles from './ProfileAvatar.module.scss';
import { IProfileAvatarProps } from './IProfileAvatarProps';

import { 
    Avatar, 
    Box, 
    Button, 
    Divider, 
    Drawer, 
    ListItemIcon, 
    Menu, 
    MenuItem, 
    Stack, 
    TextField, 
    Theme, 
    Typography, 
    useTheme
} from "@mui/material";
import { ITranslationsContext, TranslationsContext } from "../../translations/TranslationsContext";
import { g_getActiveUser, g_saveUserAccountDetails } from "../../helpers/msalHelper";
import { useMsal } from "@azure/msal-react";
import { IActiveAccount } from "../../models/IActiveAccount";
import { Cancel, Close, PowerSettingsNew, Save, Settings } from '@mui/icons-material';
import { IState, useState } from '../../react-hooks/useState';
import { InteractionStatus } from '@azure/msal-browser';
import { g_b2cPolicies, g_msalConfig, g_protectedResources } from '../../authConfig';
import * as _ from 'lodash';
import useFetchWithMsal from '../../react-hooks/useFetchWithMsal';
import { LoadingButton } from '@mui/lab';
import { CultureContext, EE_CULTURE, EN_CULTURE, LANGUAGE_PARAM_NAME, g_setLanguageParam } from '../../helpers/cultureHelper';
import { LogoUploader } from '../LogoUploader/LogoUploader';

/**
 * Renders out the logged in user with the profile menu
 */
export function ProfileAvatar(props:IProfileAvatarProps) {
    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _culture:IState<string>|null = React.useContext(CultureContext);
    const _msal = useMsal();
    const _user:IActiveAccount = g_getActiveUser(_msal);    
    
    const _theme:Theme = useTheme();

    /**
     * Holds the anchor element of the menu i.e. the avatar DIV.
     * If NULL shows that the menu should be hidden.
     */
    const _menuAnchor:IState<HTMLDivElement|null> = useState<HTMLDivElement|null>(null);
    /**
     * Closes the opened profile menu
     */
    const _handleMenuClose = ():void => {
        _menuAnchor.set(null);
    };

    const _userSettings:IState<IActiveAccount|null> = useState<IActiveAccount|null>(null);
    const _msalFetchUpdateSettings = useFetchWithMsal(g_protectedResources.apiUpdateProfile.scopes.generator);

    /**
     * Switches the language to the new specified language
     * @param culture The new language's culture
     */
    const _switchLanguage = (culture:string):void => {
        _culture?.set(culture);
    };

    return (
        <>
            <Stack 
                direction={"row"}
                className={props.className}
                sx={props.sx}
            >
                <Typography 
                    className={styles.name}
                    variant='h6'
                    color={_theme.palette.profileColor.primary}
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'block'
                        }
                    }}
                >
                    { _user.name }
                </Typography>
                <Avatar
                    className={styles.avatar}
                    onClick={(e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        _menuAnchor.set(e.currentTarget);
                    }}
                    sx={{
                        backgroundColor: _user.logoURL ? '#fff' : _theme.palette.profileColor.primary,                        
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                        width: _user.logoURL ? '60px' : '40px',
                        height: _user.logoURL ? '60px' : '40px',
                        '& img': {
                            //objectFit: 'cover',
                            height: 'auto'
                        }
                    }}
                    src={_user.logoURL + `?sid=${Math.random()}`}
                />             
            </Stack>
            <Menu
                anchorEl={_menuAnchor.value}
                open={!!_menuAnchor.value}
                onClose={_handleMenuClose}
                onClick={_handleMenuClose}
                PaperProps={{
                    elevation: 0,
                        sx: {
                            padding: '0 0 0 0',                            
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Typography                     
                    variant='subtitle2'
                    className={styles.menuEMail}
                >
                    { _user.userName }
                </Typography>
                <Divider />                
                <MenuItem
                    onClick={() => {
                        _switchLanguage(EE_CULTURE);
                    }}
                >
                    <ListItemIcon>
                        <Box className={styles.languageIconContainer}>
                            <Box className={`${styles.languageIcon} ${styles.languageIconET}`}>
                                {/* image via CSS */}
                            </Box>
                        </Box>
                    </ListItemIcon>
                    Eesti keel
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        _switchLanguage(EN_CULTURE);
                    }}
                >
                    <ListItemIcon>
                        <Box className={styles.languageIconContainer}>
                            <Box className={`${styles.languageIcon} ${styles.languageIconGB}`}>
                                {/* image via CSS */}
                            </Box>
                        </Box>
                    </ListItemIcon>
                    English
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        const userSettings:IActiveAccount = _.cloneDeep(_user);
                        _userSettings.set(userSettings);
                    }}
                >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    {_translations?.phrases.settingsMenuLabel}
                </MenuItem>              
                <MenuItem 
                    onClick={() => {
                        g_saveUserAccountDetails(null); //makes sure the user will not be logged in on logout from the session storage
                        _msal.instance.logoutRedirect();
                    }}
                >
                    <ListItemIcon>
                        <PowerSettingsNew fontSize="small" />
                    </ListItemIcon>
                    { _translations?.phrases.logOutButton }
                </MenuItem>
            </Menu>
            <Drawer
                PaperProps={{
                    sx: { 
                        width: {
                            xs: '90%',
                            sm: "55%",
                            md: '55%'
                        },
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        maxWidth: 'calc(498px + 2rem)'
                    },
                }}
                open={!!_userSettings.value}
                onClose={() => _userSettings.set(null)}
                anchor='right'
                className={styles.settingsPanel}
            >
                <Box className={styles.container}>
                    <Typography 
                        variant='h4'
                        className={styles.heading}
                    >
                        {_translations?.phrases.settingPanelHeading}
                    </Typography> 
                    <Divider />
                    <Stack spacing={1} className={styles.settings}>
                        <TextField 
                            label={_translations?.phrases.profileNameLabel || ''} 
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.name = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.name || ''}
                        />
                        <TextField 
                            label={_translations?.phrases.profileCompanyLabel || ''} 
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.company = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.company || ''}
                        />
                        <TextField 
                            label={_translations?.phrases.profilePhoneLabel || ''} 
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.phone = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.phone || ''}
                        />
                        <Typography 
                            variant='subtitle1'
                            className={styles.titleBlockHeading}
                        >
                            {_translations?.phrases.titleBlockSettingsHeading}                            
                        </Typography>                        
                        <TextField 
                            label={_translations?.phrases.settingsProjectDrafterLabel || ''} 
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.projectDrafter = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.projectDrafter || ''}
                        />
                        <TextField 
                            label={_translations?.phrases.settingsProjectManagerLabel || ''} 
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.projectManager = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.projectManager || ''}
                        />
                        <TextField 
                            label={_translations?.phrases.settingsAccountableProjectDrafterLabel || ''}
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.accountableProjectDrafter = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.accountableProjectDrafter || ''}
                        />
                        <TextField 
                            label={_translations?.phrases.settingsProjectNumberLabel || ''}
                            variant="standard" 
                            fullWidth                         
                            onChange={(e) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.projectNumber = e.target.value;
                                _userSettings.set(settings);
                            }}
                            value={_userSettings.value?.projectNumber}
                        />
                        <LogoUploader
                            url={_userSettings.value?.logoURL || null}
                            onUpload={(base64:string|null) => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.logoBase64 = base64;
                                _userSettings.set(settings);
                            }}
                            onDelete={() => {
                                const settings:IActiveAccount = _.cloneDeep(_userSettings.value) as IActiveAccount;
                                settings.logoBase64 = '';
                                settings.logoURL = null;
                                _userSettings.set(settings);
                            }}
                        />
                    </Stack>                                    
                    <Box
                        className={styles.buttons}
                    > 
                        <Button
                            onClick={() => {
                                _userSettings.set(null);
                            }}
                        >
                            {_translations?.phrases.cancelBtnLabel}
                        </Button>   
                        <LoadingButton
                            endIcon={<Save />}
                            variant='contained'
                            loadingPosition='end'
                            loading={_msalFetchUpdateSettings.isLoading.value}
                            onClick={() => {
                                _msalFetchUpdateSettings.execute(
                                    'PUT', 
                                    g_protectedResources.apiUpdateProfile.endpoint, 
                                    _userSettings.value,
                                    true
                                ).then((user:IActiveAccount) => {
                                    g_saveUserAccountDetails(user);
                                    _userSettings.set(null);                                    
                                });
                            }}
                        >
                            {_translations?.phrases.settingsSaveSettingsBtnLabel}
                        </LoadingButton>                        
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}